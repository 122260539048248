import { useIntl } from "react-intl";

function BomHeader({ labels }) {
  const { formatMessage } = useIntl();

  return (
    <div className="row">
      <div className="column">
        {labels ? formatMessage({ id: labels.table.pos }) : "pos"}
      </div>
      <div className="column">
        {labels
          ? formatMessage({ id: labels.table.description })
          : "description"}
      </div>
      <div className="column">
        {labels ? formatMessage({ id: labels.table.quality }) : "quality"}
      </div>
      <div className="column">
        {labels ? formatMessage({ id: labels.table.partNO }) : "partNO"}
      </div>
      <div className="column">
        {labels ? formatMessage({ id: labels.table.quantity }) : "quantity"}
      </div>
      <div className="column">
        {labels ? formatMessage({ id: labels.table.priceUnit }) : "priceUnit"}
      </div>
      <div className="column">
        {labels ? formatMessage({ id: labels.table.priceTotal }) : "priceTotal"}
      </div>
    </div>
  );
}

export default BomHeader;
