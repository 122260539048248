import { useIntl } from "react-intl";

function StepLabel({ labels, stepTitle, stepIdx, isCheckStatus }) {
  const { formatMessage } = useIntl();

  if (isCheckStatus) {
    return (
      <div>
        <strong>{formatMessage({ id: labels.toCheck })}</strong>
      </div>
    );
  }

  if (stepTitle == null) return null;

  return (
    <div>
      <strong>
        {formatMessage({ id: labels.titlePattern }).replace(
          "{stepId}",
          stepIdx
        )}
      </strong>{" "}
      {formatMessage({ id: stepTitle })}
    </div>
  );
}

export default StepLabel;
