import { extendObservable, action } from "mobx";
import { debounce } from "lodash";

/**
 * Wrapper functions around plan reactions to element changes
 */
/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default (state) => {
  const reactions = {};
  const saveDebounced = debounce(() => state.savePlan(), 400);

  extendObservable(reactions, {
    onAreaChange: action(() => {
      if (state.plan != null) {
        state.plan.reactions.onAreaChange();
        state.savePlan();
      }
    }),
    onSprinklerChange: action(() => {
      if (state.plan != null) {
        state.plan.reactions.onSprinklerChange();
        state.savePlan();
      }
    }),
    onSprinklerChangeDebounced: action(() => {
      if (state.plan != null) {
        state.plan.reactions.onSprinklerChange();
        saveDebounced();
      }
    }),
    onSystemElementChange: action(() => {
      if (state.plan != null) {
        state.plan.reactions.onSystemElementChange();
        state.savePlan();
      }
    }),
    onSystemElementChangeDebounced: action(() => {
      if (state.plan != null) {
        state.plan.reactions.onSystemElementChange();
        saveDebounced();
      }
    }),
    onSensorElementChange: action(() => {
      if (state.plan != null) {
        state.plan.reactions.onSensorElementChange();
        state.savePlan();
      }
    }),
    onPipesModifiedAtChange: action(() => {
      if (state.plan != null) {
        state.plan.reactions.onPipesModifiedAtChange(
          state.hiddenPipeColors,
          state.zoomState.zoomDelta
        );
      }
    }),
    onPipelineChange: action(() => {
      if (state.plan != null) {
        state.hiddenPipeColors = state.hiddenPipeColors.slice();
        state.plan.reactions.onPipelineChange(
          state.hiddenPipeColors,
          state.zoomState.zoomDelta
        );
        state.savePlan();
      }
    }),
    onBomItemChange: action(() => {
      if (state.plan != null) {
        state.plan.reactions.onBomItemChange();
        saveDebounced();
      }
    }),
    onElementChange: action((element) => {
      if (state.plan != null) {
        state.plan.onElementChange(element.type);
        state.savePlan();
      }
    }),
    defaultOnChange: () => state.savePlan(),
  });

  extendObservable(state, { reactions });
};
