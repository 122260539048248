import { BaseCsvExport, BaseProduct, Intl } from "./base-csv-export";

const VAT = 0.19;
const GENERAL_PRODUCT_MARKUP = 1.25;

type Surcharges = {
  productMarkup?: number;
  earthworks?: number;
  equipment?: number;
  briefing?: number;
  documentation?: number;
};

class PartnersCsvExport extends BaseCsvExport<
  BaseProduct & {
    affiliatePricePerUnit: number;
    affiliatePrice: number;
  }
> {
  _trenchLength: number;

  _totalPriceOfProducts: number = 0;
  _affiliateTotalPriceOfProducts: number = 0;

  _priceForEarthworks?: number;
  _priceForEquipment?: number;
  _priceForBriefing?: number;
  _priceForDocumentation?: number;
  _minInstallationTime: number = 0;
  _maxInstallationTime: number = 0;

  constructor(
    intl: Intl,
    products: BaseProduct[],
    trenchLength: number,
    surcharges: Surcharges
  ) {
    super(
      intl,
      products.map((product) => {
        let affiliatePricePerUnit =
          product.pricePerUnit * GENERAL_PRODUCT_MARKUP;

        if (surcharges.productMarkup != null) {
          affiliatePricePerUnit *= 1 + surcharges.productMarkup / 100;
        }

        const affiliatePrice = product.quantity * affiliatePricePerUnit;

        return {
          name: product.name,
          articleNO: product.articleNO,
          quantity: product.quantity,
          minInstallationTime: product.minInstallationTime,
          maxInstallationTime: product.maxInstallationTime,
          pricePerUnit: PartnersCsvExport._priceRounding(product.pricePerUnit),
          price: PartnersCsvExport._priceRounding(product.price),
          affiliatePricePerUnit: PartnersCsvExport._priceRounding(
            affiliatePricePerUnit
          ),
          affiliatePrice: PartnersCsvExport._priceRounding(affiliatePrice),
        };
      })
    );

    this._trenchLength = Math.ceil(trenchLength);

    for (const product of this._products) {
      this._totalPriceOfProducts += product.price;
      this._affiliateTotalPriceOfProducts += product.affiliatePrice;
      this._minInstallationTime +=
        product.quantity * (product.minInstallationTime ?? 0);
      this._maxInstallationTime +=
        product.quantity * (product.maxInstallationTime ?? 0);
    }

    this._priceForEarthworks =
      surcharges.earthworks != null
        ? surcharges.earthworks * this._trenchLength
        : undefined;
    this._priceForEquipment = surcharges.equipment;
    this._priceForBriefing = surcharges.briefing;
    this._priceForDocumentation = surcharges.documentation;
  }

  _addHeaders() {
    const { formatMessage } = this._intl;

    this._lines.push([
      this._formatField(
        formatMessage({ id: "texts.steps.bom.popup.csvFile.b2b.articleNO" })
      ),
      this._formatField(
        formatMessage({ id: "texts.steps.bom.popup.csvFile.b2b.quantity" })
      ),
      this._formatField(
        formatMessage({ id: "texts.steps.bom.popup.csvFile.b2b.name" })
      ),
      this._formatField(
        formatMessage({ id: "texts.steps.bom.popup.csvFile.b2b.pricePerUnit" })
      ),
      this._formatField(
        formatMessage({ id: "texts.steps.bom.popup.csvFile.b2b.priceTotal" })
      ),
      this._formatField(""),
      this._formatField(
        formatMessage({
          id: "texts.steps.bom.popup.csvFile.b2b.affiliatePricePerUnit",
        })
      ),
      this._formatField(
        formatMessage({
          id: "texts.steps.bom.popup.csvFile.b2b.affiliatePrice",
        })
      ),
    ]);
  }

  _addProducts() {
    const { formatMessage } = this._intl;

    for (const product of this._products) {
      this._lines.push([
        this._formatField(product.articleNO),
        this._formatField(product.quantity),
        this._formatField(formatMessage({ id: product.name })),
        this._formatField(this._formatPrice(product.pricePerUnit)),
        this._formatField(this._formatPrice(product.price)),
        this._formatField(""),
        this._formatField(this._formatPrice(product.affiliatePricePerUnit)),
        this._formatField(this._formatPrice(product.affiliatePrice)),
      ]);
    }
  }

  _addTotalPriceOfProducts() {
    const { formatMessage } = this._intl;

    const emptyRow = this._lines[0].map(() => this._formatField(""));
    this._lines.push(emptyRow);

    this._lines.push([
      this._formatField(""),
      this._formatField(""),
      this._formatField(""),
      this._formatField(
        formatMessage({
          id: "texts.steps.bom.popup.csvFile.b2b.totalOfProducts",
        })
      ),
      this._formatField(this._formatPrice(this._totalPriceOfProducts)),
      this._formatField(""),
      this._formatField(
        formatMessage({
          id: "texts.steps.bom.popup.csvFile.b2b.totalOfProducts",
        })
      ),
      this._formatField(this._formatPrice(this._affiliateTotalPriceOfProducts)),
    ]);
  }

  _addServicesAndProfit() {
    const { formatMessage, formatNumber } = this._intl;

    const emptyRow = this._lines[0].map(() => this._formatField(""));
    this._lines.push(emptyRow);

    this._lines.push([
      this._formatField(""),
      this._formatField(""),
      this._formatField(""),
      this._formatField(""),
      this._formatField(""),
      this._formatField(""),
      this._formatField(
        formatMessage({ id: "texts.steps.bom.popup.csvFile.b2b.earthworks" })
      ),
      this._formatField(
        this._priceForEarthworks != null
          ? this._formatPrice(this._priceForEarthworks)
          : ""
      ),
    ]);

    this._lines.push([
      this._formatField(
        formatMessage({ id: "texts.steps.bom.popup.csvFile.b2b.profit" })
      ),
      this._formatField(""),
      this._formatField(""),
      this._formatField(""),
      this._formatField(""),
      this._formatField(""),
      this._formatField(""),
      this._formatField(""),
    ]);

    this._lines.push([
      this._formatField(""),
      this._formatField(""),
      this._formatField(""),
      this._formatField(""),
      this._formatField(""),
      this._formatField(""),
      this._formatField(
        formatMessage({ id: "texts.steps.bom.popup.csvFile.b2b.equipment" })
      ),
      this._formatField(
        this._priceForEquipment != null
          ? this._formatPrice(this._priceForEquipment)
          : ""
      ),
    ]);

    this._lines.push([
      this._formatField(
        formatMessage({
          id: "texts.steps.bom.popup.csvFile.b2b.netPurchasePrice",
        })
      ),
      this._formatField(this._formatPrice(this._totalPriceOfProducts)),
      this._formatField(""),
      this._formatField(""),
      this._formatField(""),
      this._formatField(""),
      this._formatField(""),
      this._formatField(""),
    ]);

    this._lines.push([
      this._formatField(
        formatMessage({
          id: "texts.steps.bom.popup.csvFile.b2b.netSellingPrice",
        })
      ),
      this._formatField(this._formatPrice(this._affiliateTotalPriceOfProducts)),
      this._formatField(""),
      this._formatField(""),
      this._formatField(""),
      this._formatField(""),
      this._formatField(
        formatMessage({ id: "texts.steps.bom.popup.csvFile.b2b.briefing" })
      ),
      this._formatField(
        this._priceForBriefing != null
          ? this._formatPrice(this._priceForBriefing)
          : ""
      ),
    ]);

    this._lines.push([
      this._formatField(
        formatMessage({ id: "texts.steps.bom.popup.csvFile.b2b.projectYield" })
      ),
      this._formatField(
        this._formatPrice(
          this._affiliateTotalPriceOfProducts - this._totalPriceOfProducts
        )
      ),
      this._formatField(""),
      this._formatField(""),
      this._formatField(""),
      this._formatField(""),
      this._formatField(""),
      this._formatField(""),
    ]);

    this._lines.push([
      this._formatField(""),
      this._formatField(""),
      this._formatField(""),
      this._formatField(""),
      this._formatField(""),
      this._formatField(""),
      this._formatField(
        formatMessage({ id: "texts.steps.bom.popup.csvFile.b2b.documentation" })
      ),
      this._formatField(
        this._priceForDocumentation != null
          ? this._formatPrice(this._priceForDocumentation)
          : ""
      ),
    ]);

    this._lines.push([
      this._formatField(
        formatMessage({
          id: "texts.steps.bom.popup.csvFile.b2b.installationTime",
        })
      ),
      this._formatField(""),
      this._formatField(
        [
          `${formatNumber(Math.ceil(this._minInstallationTime / 60))}h`,
          `${formatNumber(Math.ceil(this._maxInstallationTime / 60))}h`,
        ].join(" - ")
      ),
      this._formatField(""),
      this._formatField(""),
      this._formatField(""),
      this._formatField(""),
      this._formatField(""),
    ]);
    this._lines.push([
      this._formatField(
        formatMessage({
          id: "texts.steps.bom.popup.csvFile.b2b.trenchLength",
        })
      ),
      this._formatField(""),
      this._formatField(`${formatNumber(this._trenchLength)}m`),
      this._formatField(""),
      this._formatField(""),
      this._formatField(""),
      this._formatField(""),
      this._formatField(""),
    ]);

    this._lines.push([
      this._formatField(
        formatMessage({
          id: "texts.steps.bom.popup.csvFile.b2b.additionalTime",
        })
      ),
      this._formatField(""),
      this._formatField(""),
      this._formatField(""),
      this._formatField(""),
      this._formatField(""),
      this._formatField(""),
      this._formatField(""),
    ]);

    const affiliateNetto =
      this._affiliateTotalPriceOfProducts +
      (this._priceForEarthworks ?? 0) +
      (this._priceForEquipment ?? 0) +
      (this._priceForBriefing ?? 0) +
      (this._priceForDocumentation ?? 0);

    this._lines.push([
      this._formatField(""),
      this._formatField(""),
      this._formatField(""),
      this._formatField(""),
      this._formatField(""),
      this._formatField(""),
      this._formatField(
        formatMessage({ id: "texts.steps.bom.popup.csvFile.b2b.totalNet" })
      ),
      this._formatField(this._formatPrice(affiliateNetto)),
    ]);

    this._lines.push(emptyRow);

    this._lines.push([
      this._formatField(""),
      this._formatField(""),
      this._formatField(""),
      this._formatField(""),
      this._formatField(""),
      this._formatField(""),
      this._formatField(
        formatMessage({ id: "texts.steps.bom.popup.csvFile.b2b.totalGross" })
      ),
      this._formatField(
        this._formatPrice(
          PartnersCsvExport._priceRounding(
            affiliateNetto + affiliateNetto * VAT
          )
        )
      ),
    ]);
  }

  generate() {
    this._addHeaders();
    this._addProducts();
    this._addTotalPriceOfProducts();
    this._addServicesAndProfit();
  }
}

export { PartnersCsvExport };
