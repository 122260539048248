import { observer } from "mobx-react";
import React from "react";
import { useIntl } from "react-intl";

import { useAutoFocus } from "../../hooks/useAutoFocus";
import useStores from "../../hooks/useStores";
import ButtonWithRef from "../elements/Button";
import { FinishPlanningDialogHeader } from "./FinishPlanningDialogHeader";
import WindowPopup from "./WindowPopup";

const SprinklerRecalculationPopup = observer(() => {
  const { formatMessage } = useIntl();
  const { uiState } = useStores();
  const { confirmCallback } = uiState;

  const ref = React.useRef();
  useAutoFocus(ref);

  return (
    <WindowPopup
      className="window-dialog"
      title={formatMessage({
        id: "texts.steps.sprinklers.calculationTitle",
      })}
    >
      <form>
        <div className="dialog-text">
          <div className="dialog-message">
            {formatMessage({
              id: "texts.steps.sprinklers.calculationNeededConfirm.question",
            })}
          </div>
        </div>

        <div className="dialog-buttons">
          <ButtonWithRef
            className="cancel"
            title={formatMessage({
              id: "texts.steps.sprinklers.calculationNeededConfirm.no",
            })}
            onClick={() => confirmCallback(false)}
          />

          <ButtonWithRef
            className="ok"
            title={formatMessage({
              id: "texts.steps.sprinklers.calculationNeededConfirm.yes",
            })}
            onClick={() => confirmCallback(true)}
            ref={ref}
          />
        </div>
      </form>

      <FinishPlanningDialogHeader
        formatMessage={formatMessage}
        confirmCallback={confirmCallback}
        stepName={uiState.step.name}
        showPopup={uiState.showPopup}
        planToCheck={uiState.planToCheck}
        sprinklerStep={true}
      />
    </WindowPopup>
  );
});

export default SprinklerRecalculationPopup;
