import React from "react";

const ButtonWithRef = React.forwardRef(function Button(
  {
    title,
    type = "button",
    disabledClass = false,
    disabled = false,
    onClick,
    options = null,
    className,
    fileOptions = {},
    hint = null,
  },
  ref
) {
  const [error, setError] = React.useState();
  const inputFileRef = React.useRef();

  const handleImageUpload = (e) => {
    e.preventDefault();

    try {
      let reader = new FileReader();

      reader.onloadend = (theFile) => {
        const {
          maxSize,
          maxSizeError,
          uploadError = "Error loading file. The file must be an image",
        } = fileOptions;

        const file = e.target.files[0];
        if (
          ["image/jpeg", "image/pjpeg", "image/png"].indexOf(file.type) !== -1
        ) {
          if (maxSize != null && file.size > maxSize) {
            setError(maxSizeError);
          } else {
            const image = new Image();
            image.src = theFile.target.result;

            image.onload = function () {
              onClick({
                src: this.src,
                width: this.width,
                height: this.height,
                file,
              });
            };

            image.onerror = function () {
              setError(uploadError);
            };
          }
        } else {
          setError(uploadError);
        }
      };

      if (e.target.files) {
        reader.readAsDataURL(e.target.files[0]);
      }
    } catch (e) {
      setError(e);
    }
  };

  const handleFileUpload = (e) => {
    e.preventDefault();

    let reader = new FileReader();

    reader.onloadend = (theFile) => {
      onClick(theFile.target.result);
    };

    if (e.target.files) {
      reader.readAsText(e.target.files[0]);
    }
  };

  return (
    <React.Fragment>
      {type === "image" || type === "file" ? (
        <span className="button-file">
          <input
            ref={inputFileRef}
            className="hidden"
            type="file"
            disabled={disabled}
            onChange={type === "image" ? handleImageUpload : handleFileUpload}
            // accept=".jpg, .jpeg,.png"
          />
          <button
            {...options}
            className={`button ${className}`}
            ref={ref}
            type={type}
            disabled={disabled}
            onClick={() => {
              inputFileRef.current.click();
            }}
          >
            {title}
          </button>
          {hint && <span className="hint">{hint}</span>}
          {error && <span className="error">{error}</span>}
        </span>
      ) : (
        <button
          {...options}
          ref={ref}
          type={type}
          disabled={disabled}
          className={`button ${disabledClass ? "disabled" : ""} ${
            className ? className : ""
          }`}
          onClick={onClick}
        >
          {title}
        </button>
      )}
    </React.Fragment>
  );
});

export default ButtonWithRef;
