import React from "react";
import { observer } from "mobx-react";
import {
  getSvgPoint,
  pixelSizeByZoom,
  sizeInMetersByPixel,
} from "@dvsproj/ipat-core/planUtils";
import { remToPx } from "../../utils/uiUtils";

const ScaleInputToolPoint = ({ x, y, size, stroke }) => {
  return (
    <g transform={`translate(${x - size / 2} ${y - size / 2})`}>
      <path
        d={`M0 0h${size / 2 + stroke}v${size / 2 + stroke}H0z`}
        transform={`rotate(45 ${stroke * 1.6} ${size / 2 + stroke * 1.5})`}
        strokeWidth={stroke * 1.5}
        stroke="#ff7600"
        fill="none"
      />
      <path
        d={`M0 0v${size - 4 * stroke}`}
        transform={`translate(${size / 2} ${stroke * 2})`}
        strokeWidth={stroke}
        stroke="#ff7600"
        fill="none"
      />
      <path
        d={`M${size - 4 * stroke} 0H0`}
        transform={`translate(${stroke * 2} ${size / 2})`}
        strokeWidth={stroke}
        stroke="#ff7600"
        fill="none"
      />
    </g>
  );
};

const LenghtText = ({ l, x, y, angle, zoomDelta }) => {
  const textWidth =
    l.length * pixelSizeByZoom(7, zoomDelta) + pixelSizeByZoom(16, zoomDelta);
  const textHeight =
    pixelSizeByZoom(remToPx(0.75), zoomDelta) + pixelSizeByZoom(8, zoomDelta);

  const textX = x - textWidth / 2;
  const textY = y + textHeight / 2;

  const dx = !isNaN(angle)
    ? (textWidth / 2 + pixelSizeByZoom(5)) * Math.sin(angle)
    : null;
  const dy = !isNaN(angle)
    ? -(textHeight / 2 + pixelSizeByZoom(5)) * Math.cos(angle)
    : null;

  return (
    <g transform={`translate(${textX + dx} ${textY + dy})`}>
      <path
        id="scale-text"
        d={`M 0 0 L ${textWidth} 0 L ${textWidth} ${-textHeight} L 0 ${-textHeight} Z`}
        fill="#ff7600"
      />
      <text
        style={{
          fontSize: pixelSizeByZoom(remToPx(0.75), zoomDelta),
          lineHeight: pixelSizeByZoom(remToPx(0.75), zoomDelta),
          fontWeight: "bold",
          userSelect: "none",
        }}
        fill="#fff"
        dy={pixelSizeByZoom(-6, zoomDelta)}
        dx={pixelSizeByZoom(5, zoomDelta)}
      >
        <textPath xlinkHref="#scale-text">
          <tspan>{l} m</tspan>
        </textPath>
      </text>
    </g>
  );
};

const ScaleInputTool = observer(
  class extends React.Component {
    state = {
      startPoint: null,
      stopPoint: null,
      mouseCoord: null,
      mousedown: false,
    };

    onMouseMove(e) {
      // const { startPoint, stopPoint, mousedown } = this.state;

      // if (!startPoint || stopPoint || mousedown) {
      //   return;
      // }

      const svgP = getSvgPoint(e, this.svg);
      this.setState({
        mouseCoord: { x: svgP.x, y: svgP.y },
      });
      e.preventDefault();
    }

    onClick(e) {
      const { startPoint, stopPoint } = this.state;
      //const svgBB = this.svg ? this.svg.getBoundingClientRect() : null;
      //const svgVB = this.svg ? this.svg.viewBox.baseVal : null;

      const svgP = getSvgPoint(e, this.svg);
      this.setState({
        startPoint:
          startPoint && !stopPoint ? startPoint : { x: svgP.x, y: svgP.y },
        stopPoint: startPoint && !stopPoint ? { x: svgP.x, y: svgP.y } : null,
        mouseCoord: null,
        mousedown: false,
      });
      e.preventDefault();
    }

    onMouseDown(e) {
      this.setState({
        mouseCoord: this.state.mouseCoord,
        mousedown: true,
        startPoint:
          this.state.startPoint && this.state.stopPoint
            ? null
            : this.state.startPoint,
        stopPoint:
          this.state.startPoint && this.state.stopPoint
            ? null
            : this.state.stopPoint,
      });
    }

    componentDidUpdate() {
      const { startPoint, stopPoint } = this.state;
      const { setScaleLength } = this.props;

      if (startPoint && stopPoint && typeof setScaleLength === "function") {
        const l = Math.sqrt(
          (stopPoint.x - startPoint.x) * (stopPoint.x - startPoint.x) +
            (stopPoint.y - startPoint.y) * (stopPoint.y - startPoint.y)
        );
        setScaleLength(l);
      }
    }

    render() {
      const {
        width,
        height,
        viewbox,
        draggable,
        zoomDelta,
        scale,
        hasLengthText = false,
      } = this.props;
      const { startPoint, stopPoint, mouseCoord } = this.state;

      var endX = stopPoint ? stopPoint.x : mouseCoord ? mouseCoord.x : 0;
      var endY = stopPoint ? stopPoint.y : mouseCoord ? mouseCoord.y : 0;

      return (
        <svg
          style={{ zIndex: 200 }}
          ref={(c) => (this.svg = c)}
          viewBox={`${viewbox.x} ${viewbox.y} ${viewbox.w} ${viewbox.h}`}
          width={width}
          height={height}
          onMouseDown={!draggable ? this.onMouseDown.bind(this) : null}
          onMouseUp={!draggable ? this.onClick.bind(this) : null}
          onMouseMove={!draggable ? this.onMouseMove.bind(this) : null}
          onTouchMove={!draggable ? this.onMouseMove.bind(this) : null}
          onTouchStart={!draggable ? this.onMouseDown.bind(this) : null}
          onTouchEnd={!draggable ? this.onClick.bind(this) : null}
        >
          {this.state.mousedown && this.state.mouseCoord ? (
            <ScaleInputToolPoint
              x={this.state.mouseCoord.x}
              y={this.state.mouseCoord.y}
              size={pixelSizeByZoom(22, zoomDelta)}
              stroke={pixelSizeByZoom(2, zoomDelta)}
            />
          ) : null}
          {startPoint ? (
            <ScaleInputToolPoint
              x={startPoint.x}
              y={startPoint.y}
              size={pixelSizeByZoom(22, zoomDelta)}
              stroke={pixelSizeByZoom(2, zoomDelta)}
            />
          ) : null}
          {stopPoint ? (
            <ScaleInputToolPoint
              x={stopPoint.x}
              y={stopPoint.y}
              size={pixelSizeByZoom(22, zoomDelta)}
              stroke={pixelSizeByZoom(2, zoomDelta)}
            />
          ) : null}
          {startPoint && (mouseCoord || stopPoint) ? (
            <React.Fragment>
              <line
                x1={startPoint.x}
                y1={startPoint.y}
                x2={endX}
                y2={endY}
                stroke="#ff7600"
                strokeWidth={pixelSizeByZoom(2, zoomDelta)}
              />
              {hasLengthText ? (
                <LenghtText
                  x={startPoint.x + (endX - startPoint.x) / 2}
                  y={startPoint.y + (endY - startPoint.y) / 2}
                  angle={Math.atan(
                    (endY - startPoint.y) / (endX - startPoint.x)
                  )}
                  l={sizeInMetersByPixel(
                    Math.sqrt(
                      Math.pow(endX - startPoint.x, 2) +
                        Math.pow(endY - startPoint.y, 2)
                    ),
                    scale
                  ).toFixed(1)}
                  zoomDelta={zoomDelta}
                  scale={scale}
                />
              ) : null}
            </React.Fragment>
          ) : null}
        </svg>
      );
    }
  }
);

export default ScaleInputTool;
