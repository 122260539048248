import React from "react";
import { useDraggable } from "react-draggable-hoc";

function Slider({
  value,
  changeValue,
  onBefore = () => {},
  onAfter = () => {},
}) {
  const tValue = isNaN(value) ? 0 : value;

  const sliderRef = React.useRef();

  const { deltaX, deltaY, isDragged } = useDraggable(sliderRef, {
    dragProps: "slider",
  });

  const before = React.useRef({ value: tValue });
  const prevDeltas = React.useRef({ deltaX: 0, deltaY: 0, isDragged: false });
  React.useEffect(() => {
    if (isDragged && !prevDeltas.current.isDragged) {
      onBefore();
      before.current = { value: tValue };
    }

    if (isDragged) {
      const node = sliderRef.current;
      if (node) {
        let newValue =
          before.current.value +
          (-prevDeltas.current.deltaY / node.parentNode.offsetHeight) * 100;
        if (newValue >= 0 && newValue <= 100) {
          changeValue(newValue);
        }
      }
    }

    if (
      !isDragged &&
      (prevDeltas.current.deltaX !== 0 || prevDeltas.current.deltaY !== 0)
    ) {
      onAfter();
    }

    prevDeltas.current = { deltaX, deltaY, isDragged };
  }, [
    isDragged,
    tValue,
    changeValue,
    onAfter,
    onBefore,
    prevDeltas,
    before,
    deltaX,
    deltaY,
  ]);

  return (
    <div className="slider">
      <div className="slider-wrap">
        <svg
          id="slider-gradient"
          width="24px"
          height="192px"
          viewBox="0 0 24 192"
        >
          <defs>
            <linearGradient id="gradient" gradientTransform="rotate(90)">
              <stop offset="0%" stopColor="#c5d75d" />
              <stop offset="100%" stopColor="rgba(197, 215, 93, 0)" />
            </linearGradient>
          </defs>
          <polygon
            fill="url(#gradient)"
            points="0 0 24 0 24 192 20 192"
          ></polygon>
        </svg>
        <div className="slider-button-wrap" ref={sliderRef}>
          <div
            className="slider-button"
            style={{ top: `calc(${100 - tValue}% - 8px)` }}
          >
            <div className="slider-value">{tValue}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Slider;
