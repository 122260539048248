import * as React from "react";

function useDelayedAction(timeout = 200) {
  const [delayedAction, changeDelayedAction] = React.useState();
  const timeoutRef = React.useRef();

  const cleanTimeout = () => {
    if (timeoutRef.current != null) {
      window.clearTimeout(timeoutRef.current);
      timeoutRef.current = undefined;
    }
  };

  const action = React.useCallback(() => {
    cleanTimeout();
    changeDelayedAction(undefined);
    if (typeof delayedAction === "function") {
      delayedAction();
    }
  }, [delayedAction]);

  React.useEffect(() => {
    timeoutRef.current =
      typeof delayedAction === "function"
        ? window.setTimeout(action, timeout)
        : undefined;

    return cleanTimeout;
  });

  return [
    action => {
      if (action !== delayedAction) {
        if (action == null) {
          changeDelayedAction(action);
        } else if (typeof action === "function") {
          if (timeout < 0) {
            changeDelayedAction(null);
            action();
          } else {
            changeDelayedAction(() => action);
          }
        }
      }
    },
    typeof delayedAction === "function" ? action : undefined,
    cleanTimeout,
  ];
}

export default useDelayedAction;
