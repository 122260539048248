export const sprinklerLineErrors = ({
  hoveredElement,
  selectedElement,
  selectedPipeline,
  hoveredPipeline,
  labels,
  plan,
}) => {
  const totalWaterQuantity =
    selectedPipeline && hoveredPipeline
      ? selectedPipeline.waterQuantity + hoveredPipeline.waterQuantity
      : undefined;

  const hoveredSprinklerPoint =
    hoveredElement &&
    hoveredElement.type === "pipeline-point" &&
    hoveredElement.isSprinklerPoint
      ? hoveredElement
      : undefined;

  const hasStartPoint =
    (hoveredElement &&
      hoveredElement.type === "pipeline-point" &&
      hoveredElement.isStartPoint) ||
    (selectedElement.type === "pipeline-point" && selectedElement.isStartPoint);

  const hasDuplicateLine = hoveredElement
    ? plan.pipes.filter((pipe) => {
        return (
          (pipe.start === selectedElement.id &&
            pipe.end === hoveredElement.id) ||
          (pipe.end === selectedElement.id && pipe.start === hoveredElement.id)
        );
      }).length > 0
    : false;

  return [
    // this circuit carries not enough water
    {
      status:
        !hasStartPoint &&
        selectedPipeline &&
        hoveredPipeline &&
        selectedPipeline.id !== hoveredPipeline.id &&
        totalWaterQuantity > selectedPipeline.shapeLimit,
      text: labels ? labels.error.notEnoughWater : "",
      type: "water",
    },

    // this sprinkler has already 2 connections
    {
      status:
        hoveredElement &&
        selectedElement.id !== hoveredElement.id &&
        ((hoveredSprinklerPoint && hoveredSprinklerPoint.linesCount >= 2) ||
          hasDuplicateLine),
      text: labels ? labels.error.sprinklerHas2Connection : "",
    },
  ].filter(({ status }) => status);
};

export const driplineLineErrors = ({
  hoveredElement,
  selectedElement,
  selectedPipeline,
  hoveredPipeline,
  labels,
}) => {
  const hasStartPoint =
    (hoveredElement &&
      hoveredElement.type === "pipeline-point" &&
      hoveredElement.isStartPoint) ||
    (selectedElement.type === "pipeline-point" && selectedElement.isStartPoint);

  //selected
  const sHasDripline = selectedPipeline?.hasDriplinePoint ?? false;
  const sHasSprinkler = selectedPipeline?.hasSprinklerPoint ?? false;
  const sHasRzws = selectedPipeline?.hasRzwsPoint ?? false;
  const sHasRaisedBed = selectedPipeline?.hasRaisedBedPoint ?? false;

  //current
  const cHasDripline = hoveredPipeline?.hasDriplinePoint ?? false;
  const cHasSprinkler = hoveredPipeline?.hasSprinklerPoint ?? false;
  const cHasRzws = hoveredPipeline?.hasRzwsPoint ?? false;
  const cHasRaisedBed = hoveredPipeline?.hasRaisedBedPoint ?? false;

  const hasTypesIntersect =
    (sHasDripline && (cHasSprinkler || cHasRzws || cHasRaisedBed)) ||
    (sHasSprinkler && (cHasDripline || cHasRzws || cHasRaisedBed)) ||
    (sHasRzws && (cHasDripline || cHasSprinkler || cHasRaisedBed)) ||
    (sHasRaisedBed && (cHasDripline || cHasSprinkler || cHasRzws));

  return [
    // 	sprinklers cannot be added to dripline circuits
    {
      status:
        !hasStartPoint &&
        selectedPipeline &&
        hoveredPipeline &&
        selectedPipeline.id !== hoveredPipeline.id &&
        hasTypesIntersect,
      text: labels ? labels.error.hasOtherType : "",
    },
  ].filter(({ status }) => status);
};

export const pressureTubingLineErrors = ({
  selectedPipeline,
  hoveredPipeline,
  selectedElement,
  hoveredElement,
  labels,
}) => {
  const connectionToSystemPoint =
    (selectedElement &&
      hoveredPipeline &&
      selectedElement.type === "pipeline-point" &&
      selectedElement.isSystemElementPoint &&
      hoveredPipeline.lineType === "pressure-tubing") ||
    (hoveredElement &&
      selectedPipeline &&
      hoveredElement.type === "pipeline-point" &&
      hoveredElement.isSystemElementPoint &&
      selectedPipeline.lineType === "pressure-tubing");

  const connectionLineToPressureTubing =
    selectedPipeline &&
    hoveredPipeline &&
    ((selectedPipeline.lineType !== "pressure-tubing" &&
      hoveredPipeline.lineType === "pressure-tubing" &&
      !selectedPipeline.hasSprinklerPoint &&
      !selectedPipeline.hasDriplinePoint &&
      !selectedPipeline.hasRzwsPoint &&
      !selectedPipeline.hasRaisedBedPoint) ||
      (hoveredPipeline.lineType !== "pressure-tubing" &&
        selectedPipeline.lineType === "pressure-tubing" &&
        !hoveredPipeline.hasSprinklerPoint &&
        !hoveredPipeline.hasDriplinePoint &&
        !hoveredPipeline.hasRzwsPoint &&
        !hoveredPipeline.hasRaisedBedPoint));

  const hasPressureTubing =
    selectedPipeline?.lineType === "pressure-tubing" ||
    hoveredPipeline?.lineType === "pressure-tubing";

  const connectionToWaterSupply = hoveredElement
    ? (selectedElement.type === "pipeline-point" &&
        selectedElement.isWaterSupplyPoint &&
        !hoveredElement.isSystemElementPoint &&
        hoveredPipeline &&
        hoveredPipeline.lineType !== "pressure-tubing" &&
        (hoveredPipeline.hasSprinklerPoint ||
          hoveredPipeline.hasDriplinePoint ||
          hoveredPipeline.hasRzwsPoint ||
          hoveredPipeline.hasRaisedBedPoint)) ||
      (hoveredElement.type === "pipeline-point" &&
        hoveredElement.isWaterSupplyPoint &&
        !selectedElement.isSystemElementPoint &&
        selectedPipeline &&
        selectedPipeline.lineType !== "pressure-tubing" &&
        (selectedPipeline.hasSprinklerPoint ||
          selectedPipeline.hasDriplinePoint ||
          selectedPipeline.hasRzwsPoint ||
          selectedPipeline.hasRaisedBedPoint))
    : false;

  const hoverOnOneself = hoveredElement
    ? selectedElement.id === hoveredElement.id
    : false;

  const connectionForYourself =
    selectedPipeline &&
    hoveredPipeline &&
    selectedPipeline.id === hoveredPipeline.id;

  const hasStartPoint =
    (hoveredElement &&
      hoveredElement.type === "pipeline-point" &&
      hoveredElement.isStartPoint) ||
    (selectedElement.type === "pipeline-point" && selectedElement.isStartPoint);
  return [
    // sprinklers cannot be connected to water-supply
    {
      status: connectionToWaterSupply,
      text: labels ? labels.error.cannotBeConnectedToWaterSupply : "",
    },
    // sprinklers cannot be connected to pressureTubing
    {
      status:
        hasPressureTubing &&
        !hoverOnOneself &&
        !connectionToSystemPoint &&
        !connectionLineToPressureTubing &&
        !connectionToWaterSupply &&
        !hasStartPoint &&
        ((hoveredPipeline && hoveredPipeline.lineType !== "pressure-tubing") ||
          (selectedPipeline &&
            selectedPipeline.lineType !== "pressure-tubing")),

      text: labels ? labels.error.cannotBeConnectedToPressureTubing : "",
    },
    // you can not connect the pipe by itself
    {
      status: hasPressureTubing && !hoverOnOneself && connectionForYourself,
      text: labels ? labels.error.cannotConnectThePressureTubingByYourself : "",
    },
  ].filter(({ status }) => status);
};
