import { findFittingByTubeType, maxOfArray } from "./common-utils";
import { sizeInMetersByPixel } from "@dvsproj/ipat-core/planUtils";
import { round } from "@dvsproj/ipat-core/formatter";
import { Settings } from "../../config/settings-types";
import { BomItem, Plan } from "../../config/plan-types";

function waterFilter(settings: Settings, plan: Plan) {
  const waterFilterElement = plan.systemElements.find(
    (e) => e.systemType === "water-filter"
  );
  const { bomList, elements } = settings;
  const { filterMastervalve, pressureSensor } = elements;

  if (waterFilterElement == null) return [];

  const filterTypes = filterMastervalve.find(
    (e) => e.value === waterFilterElement.waterFilter
  )?.types;

  if (filterTypes == null) {
    throw new Error(`Filter ${filterTypes} not found`);
  }

  let bomItems: BomItem[] = [];

  const filterTypeByTube = findFittingByTubeType(
    plan.pressureTubeType,
    filterTypes
  );

  bomItems.push({
    bomId: filterTypeByTube.bomId,
    qualityList: bomList[filterTypeByTube.bomId],
    quantity: 1,
  });

  if (waterFilterElement.waterPressureSensor === "sensor") {
    bomItems.push({
      bomId: pressureSensor.bomId,
      qualityList: bomList[pressureSensor.bomId],
      quantity: 1,
    });
  }

  return bomItems;
}

function waterMeter(settings: Settings, plan: Plan) {
  const waterMeterElement = plan.systemElements.find(
    (e) => e.systemType === "water-meter"
  );
  const { bomList, elements } = settings;
  const { waterMeter: waterMeterTypes } = elements;

  if (waterMeterElement == null) return [];

  const waterMeterList = waterMeterTypes.find(
    (e) => e.value === waterMeterElement.waterMeterType
  )?.types;
  if (waterMeterList == null) {
    throw new Error(
      `Water meter ${waterMeterElement.waterMeterType} not found`
    );
  }

  const waterMeterByTube = findFittingByTubeType(
    plan.pressureTubeType,
    waterMeterList
  );

  const bomItems: BomItem[] = [
    {
      bomId: waterMeterByTube.bomId,
      qualityList: bomList[waterMeterByTube.bomId],
      quantity: 1,
    },
  ];

  return bomItems;
}

function waterSupply(settings: Settings, plan: Plan) {
  const waterSupplyElement = plan.systemElements.find(
    (e) => e.systemType === "water-supply"
  );
  const { bomList, elements } = settings;
  const { connectionKit, waterSupplyQuality } = elements;

  if (waterSupplyElement == null) return [];

  const waterSupply = waterSupplyQuality.find(
    (e) => e.value === waterSupplyElement.waterQuality
  );

  if (waterSupply == null) {
    throw new Error(
      `Water supply ${waterSupplyElement.waterQuality} not found`
    );
  }

  let bomItems: BomItem[] = [];

  const recoms = waterSupplyElement.recom
    ? Object.values(waterSupplyElement.recom)
    : [];
  recoms.forEach((recom) => {
    if (recom.value === true && bomList[recom.bomId] != null) {
      bomItems.push({
        bomId: recom.bomId,
        qualityList: bomList[recom.bomId],
        quantity: 1,
      });
    }
  });

  const connectionKitForTube = findFittingByTubeType(
    plan.pressureTubeType,
    connectionKit
  );

  bomItems.push({
    bomId: connectionKitForTube.bomId,
    qualityList: bomList[connectionKitForTube.bomId],
    quantity: 1,
  });

  return bomItems;
}

function combiBox(settings: Settings, plan: Plan) {
  const combiBoxElement = plan.systemElements.find(
    (e) => e.systemType === "combi-box"
  );
  const { bomList, elements } = settings;
  const { combiBox: combiBoxTypes, pressureSensor } = elements;

  if (combiBoxElement == null) {
    return [];
  }

  const combiBoxList = combiBoxTypes.find(
    (e) => e.value === combiBoxElement.combiBoxType
  )?.types;
  if (combiBoxList == null) {
    throw new Error(
      `CombiBox of ${combiBoxElement.combiBoxType} type not found`
    );
  }

  const combiBoxByTube = findFittingByTubeType(
    plan.pressureTubeType,
    combiBoxList
  );

  const bomItems: BomItem[] = [
    {
      bomId: combiBoxByTube.bomId,
      qualityList: bomList[combiBoxByTube.bomId],
      quantity: 1,
    },
  ];

  if (combiBoxElement.waterPressureSensor === "sensor") {
    bomItems.push({
      bomId: pressureSensor.bomId,
      qualityList: bomList[pressureSensor.bomId],
      quantity: 1,
    });
  }

  return bomItems;
}

function cableForWaterSupplySensors(settings: any, plan: Plan) {
  const { bomList } = settings;
  const { waterMeterCable } = settings.elements;

  const waterFilter = plan.systemElements.find(
    (e) => e.systemType === "water-filter"
  );

  const waterMeter = plan.systemElements.find(
    (e) => e.systemType === "water-meter"
  );

  const combiBox = plan.systemElements.find(
    (e) => e.systemType === "combi-box"
  );

  if (waterFilter == null && waterMeter == null && combiBox == null) {
    return [];
  }

  // length calculation
  const distance = sizeInMetersByPixel(
    (plan.waterMeterCable ?? []).reduce((acc, c) => acc + c.distance, 0),
    plan.scale
  );

  let length = 0;
  if (waterFilter != null || combiBox != null) {
    length += distance;
  }

  if (waterMeter != null || combiBox != null) {
    if (length > 2.5) {
      length = length - 2.5;
    }
    length += distance;
  }

  length = Math.max(round(length, 1, "ceil"), 0.1);

  // cable search
  let quantity = 1;
  let cableBOM = waterMeterCable.find(
    ({ minLength, maxLength }) => length >= minLength && length <= maxLength
  );
  if (cableBOM == null) {
    cableBOM = maxOfArray(waterMeterCable, "maxLength");
    quantity = Math.ceil(length / cableBOM.maxLength);
  }

  if (cableBOM == null) {
    throw new Error("Cable for waterSupply sensors not found");
  }

  const bomItems: BomItem[] = [
    {
      bomId: cableBOM.bomId,
      qualityList: bomList[cableBOM.bomId],
      quantity,
    },
  ];

  return bomItems;
}

export {
  waterFilter,
  waterMeter,
  waterSupply,
  combiBox,
  cableForWaterSupplySensors,
};
