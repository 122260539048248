import React from "react";
import ReactDOM from "react-dom";
import { observer } from "mobx-react";

import { removeScripts } from "../../utils/uiUtils";

import Scroll from "../elements/Scroll";
import ToolButton from "../elements/ToolButton";
import NumberInput from "../inputs/NumberInput";

import { ReactComponent as MasstabPopupSVG } from "../../assets/masstab-popup.svg";
import { ReactComponent as SaveSVG } from "../../assets/save.svg";
import { RawIntlProvider, useIntl } from "react-intl";
import useStores from "../../hooks/useStores";

const ScaleTool = observer(({ scaleLength, onChange, labels }) => {
  const intl = useIntl();
  const { formatMessage } = intl;

  const [value, changeValue] = React.useState(0);

  const img = React.useMemo(() => {
    return (
      <div className="scale-img">
        <MasstabPopupSVG />
      </div>
    );
  }, []);

  const scaleRef = React.useRef();

  const input = React.useMemo(() => {
    return (
      <NumberInput
        ref={scaleRef}
        type="text"
        name="masstab"
        value={value}
        onChange={changeValue}
        precision={2}
      />
    );
  }, [value]);

  const setText = () => {
    return {
      __html: removeScripts(formatMessage({ id: labels.text }))
        .replace("{img}", "<div id='scale-img'></div>")
        .replace("{input}", "<span id='scale-input'></span>"),
    };
  };

  React.useEffect(() => {
    ReactDOM.render(img, document.getElementById("scale-img"));
    ReactDOM.render(
      <RawIntlProvider value={intl}>{input}</RawIntlProvider>,
      document.getElementById("scale-input")
    );
    if (scaleLength) {
      const node = scaleRef.current;
      if (node) {
        node.focus();
      }
    }
  }, [img, input, scaleLength, scaleRef, intl]);

  return (
    <form
      onKeyUp={(e) => {
        if (e.keyCode === 13) {
          e.preventDefault();
          onChange(value);
        }
      }}
    >
      <Scroll className="right-side-content scale-tool">
        <div className="scale-header">
          {formatMessage({ id: labels.title })}
        </div>
        <div className="scale-container" dangerouslySetInnerHTML={setText()} />
      </Scroll>
      <div className="right-side-footer">
        <div className="right-side-icon">
          <ToolButton
            type="submit"
            onClick={() => {
              onChange(value);
            }}
          >
            <SaveSVG />
          </ToolButton>
        </div>
      </div>
    </form>
  );
});

let ScaleToolWithState = ({ onClick }) => {
  const { uiState } = useStores();
  const onChange = (value) => {
    let result = false;
    if (
      value &&
      uiState.plan.scaleLength &&
      uiState.plan.scaleLength / value < 1000
    ) {
      uiState.plan.setScale(uiState.plan.scaleLength / value);
      if (uiState.reactions) {
        uiState.reactions.onAreaChange();
      }
      result = true;
      if (!uiState.showGrid) {
        uiState.changeShowGrid();
      }
    }

    if (result && onClick && typeof onClick === "function") {
      onClick();
    }
    return result;
  };

  return (
    <ScaleTool
      scaleLength={uiState.plan ? uiState.plan.scaleLength : null}
      onChange={onChange}
      labels={uiState.settingsState.texts.tools.scale.popup}
    />
  );
};

ScaleToolWithState = observer(ScaleToolWithState);
export default ScaleToolWithState;
