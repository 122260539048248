import React from "react";
import { observer } from "mobx-react";

import Slider from "../elements/Slider";
import Popup from "./Popup";
import { useIntl } from "react-intl";
import useStores from "../../hooks/useStores";

function OpacityPopup({
  hasActive = false,
  onClose,
  position,
  opacity,
  changeOpacity,
  onChange,
  labels,
}) {
  const { formatMessage } = useIntl();

  return (
    <Popup
      className="opacity-popup"
      hasActive={hasActive}
      position={position}
      onClose={onClose}
    >
      <div>
        <div className="popup-title">
          {formatMessage({ id: labels.opacity.popupTitle })}
        </div>
        <div>
          <Slider
            value={opacity == null ? 100 : Math.round((1 - opacity) * 100)}
            changeValue={(val) => changeOpacity(1 - val / 100)}
            onAfter={() => {
              onChange();
              onClose();
            }}
          />
        </div>
      </div>
    </Popup>
  );
}

let OpacityPopupWithState = ({ ...props }) => {
  const { uiState } = useStores();
  return (
    <OpacityPopup
      changeOpacity={
        uiState.plan != null ? uiState.plan.changeOpacity : () => {}
      }
      opacity={uiState.plan != null ? uiState.plan.opacity : undefined}
      onChange={
        uiState.reactions ? uiState.reactions.defaultOnChange : () => {}
      }
      labels={uiState.settingsState.texts.tools}
      {...props}
    />
  );
};

OpacityPopupWithState = observer(OpacityPopupWithState);
export default OpacityPopupWithState;
