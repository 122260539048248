import { useIntl } from "react-intl";
import { observer } from "mobx-react-lite";

import WindowPopup from "../popups/WindowPopup";
import Button from "../elements/Button";

import { ReactComponent as SensorSetSVG } from "../../assets/sensor-set.svg";
import { ReactComponent as SensorSchemaSVG } from "../../assets/sensor-schema.svg";

import "../../styles/scss/smart-garden.scss";
import useStores from "../../hooks/useStores";

function Image({ children }) {
  return (
    <div className="smart-garden-img">
      <div className="img-offset" />
      <div className="img-offset" />
      <div className="img-offset" />
      <div className="img-offset" />
      {children}
    </div>
  );
}

let SmartGarden = () => {
  const { formatMessage } = useIntl();
  const { uiState } = useStores();

  const {
    showSensorPlan,
    setShowSensorPlan = () => {},
    sensors,
  } = uiState?.plan ?? {};

  if (
    uiState.step.name !== "sensor" ||
    showSensorPlan != null ||
    sensors.length > 0
  )
    return null;

  return (
    <WindowPopup
      wrapperClassName="smart-garden"
      title={formatMessage({ id: "texts.steps.sensor.popup.title" })}
    >
      <div className="smart-garden">
        <div className="icon">
          <SensorSetSVG />
          <SensorSchemaSVG />
        </div>
        <Image>
          <div
            dangerouslySetInnerHTML={{
              __html: formatMessage({ id: "texts.steps.sensor.popup.text" }),
            }}
          />

          <div className="dialog-buttons">
            <Button
              title={formatMessage({ id: "texts.steps.sensor.popup.cancel" })}
              onClick={() => {
                uiState.nextStep();
              }}
              className="cancel"
            />
            <Button
              title={formatMessage({ id: "texts.steps.sensor.popup.confirm" })}
              onClick={() => setShowSensorPlan(true)}
              className="ok"
            />
          </div>
        </Image>
      </div>
    </WindowPopup>
  );
};

SmartGarden = observer(SmartGarden);

export default SmartGarden;
