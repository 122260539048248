import { observer } from "mobx-react";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { ReactComponent as CanBeIrregatedSVG } from "./../../assets/can-be-irrigated-tour.svg";
import { ReactComponent as CrossAbilitySVG } from "./../../assets/cross-ability-tour.svg";
import { ReactComponent as DripLineAreaSVG } from "./../../assets/drip-line-tour.svg";
import { ReactComponent as NoIrregationSVG } from "./../../assets/no-irrigation-tour.svg";
import { ReactComponent as PipeAddSVG } from "./../../assets/pipeline-add-tour.svg";
import { ReactComponent as RaisedBedSVG } from "./../../assets/raised-bed-tour.svg";
import { ReactComponent as RzwsSVG } from "./../../assets/rzws-tour.svg";
import { ReactComponent as SelectToolSVG } from "./../../assets/select-tour.svg";
import { ReactComponent as ShouldBeIrregatedSVG } from "./../../assets/should-be-irrigated-tour.svg";
import { ReactComponent as SprinklerSVG } from "./../../assets/sprinkler-tour.svg";
import { ReactComponent as DriplineSVG } from "./../../assets/stripline-tour.svg";
import { ReactComponent as ValveBoxSVG } from "./../../assets/valve-box-tour.svg";
import { ReactComponent as WaterSourceSVG } from "./../../assets/water-source-tour.svg";

import { useTourContext } from "./TourContext";

const elementsContainClass = (elements, className) =>
  elements.find((el) => el.classList.contains(className));

function useButtonHandlers(ref, ctx) {
  React.useEffect(() => {
    if (ref == null) return;

    const buttonListener = async (e) => {
      const containerAllowsButtons =
        typeof ctx.tourStep?.containerSelectorFn === "function" &&
        ctx.tourStep.containerSelectorFn().id === "main";

      if (e.code === "Escape" && !containerAllowsButtons) {
        e.preventDefault();
        e.stopPropagation();
        return false;
      }

      if (
        (e.code === "Backspace" || e.code === "Delete") &&
        !containerAllowsButtons &&
        e.target.nodeName.toLowerCase() !== "input"
      ) {
        e.preventDefault();
        e.stopPropagation();
        return false;
      }

      if (
        e.target.nodeName.toLowerCase() === "input" &&
        e.type === "keyup" &&
        e.code === "Enter"
      ) {
        if (
          typeof ctx.tourStep.validate === "function" &&
          !ctx.tourStep.validate()
        ) {
          e.preventDefault();
          e.stopPropagation();
          return false;
        }

        await ctx.takeNextStep();
      }
    };

    document.addEventListener("keydown", buttonListener, true);
    document.addEventListener("keyup", buttonListener, true);

    return () => {
      document.removeEventListener("keyup", buttonListener, true);
      document.removeEventListener("keydown", buttonListener, true);
    };
  }, [ctx, ctx.tourStep, ref]);
}

function useClickHandlers(ref, ctx) {
  const stateRef = React.useRef();

  React.useEffect(() => {
    if (ref == null) return;

    const listener = async (e) => {
      const elements = document.elementsFromPoint(e.clientX, e.clientY);
      if (
        ctx.allowedElements.some((ex) => elementsContainClass(elements, ex))
      ) {
        stateRef.current = "eventValidated";
        return true;
      }

      if (
        ctx.tourStep.excludedElements?.some((ex) =>
          elementsContainClass(elements, ex)
        )
      ) {
        e.preventDefault();
        e.stopPropagation();
        return false;
      }

      const controllerElement = ctx.tourStep.controllerSelectorFn?.();
      const containerElement = ctx.tourStep.containerSelectorFn?.();

      if (NodeList.prototype.isPrototypeOf(controllerElement)) {
        for (const el of controllerElement) {
          if (el.contains(e.target)) {
            await ctx.takeNextStep();
            break;
          }
        }
        return true;
      }

      if (
        typeof ctx.tourStep.containerSelectorFn === "function" &&
        containerElement?.contains(e.target) &&
        !controllerElement?.contains(e.target)
      ) {
        stateRef.current = "eventValidated";
        return true;
      }

      if (!controllerElement?.contains(e.target)) {
        e.preventDefault();
        e.stopPropagation();
        return false;
      }

      if (
        typeof ctx.tourStep.validate === "function" &&
        !ctx.tourStep.validate()
      ) {
        e.preventDefault();
        e.stopPropagation();
        return false;
      }

      stateRef.current = "eventValidated";
      await ctx.takeNextStep();
    };

    const mouseLeftButtonHandler = async (e) => {
      if (e.type === "mousedown") stateRef.current = null;

      const eventType = e.type;
      const controllerEventType = ctx.tourStep.controllerEventType
        ? ctx.tourStep.controllerEventType
        : "click";

      if (controllerEventType === "mousedown" && eventType !== "mousedown") {
        if (stateRef.current !== "eventValidated") {
          if (eventType === "mouseup" || eventType === "click") {
            e.preventDefault();
            e.stopPropagation();
            return false;
          }
        }
        // all eventValidated,  pass through
        return true;
      }

      if (controllerEventType === "mouseup" && eventType !== "mouseup") {
        if (eventType === "mousedown") {
          // pass through
          return true;
        }

        if (eventType === "click") {
          if (stateRef.current !== "eventValidated") {
            e.preventDefault();
            e.stopPropagation();
            return false;
          }
        }
      }

      if (controllerEventType === "click") {
        if (eventType === "mousedown" || eventType === "mouseup") {
          // pass through
          return true;
        }
      }

      return listener(e);
    };

    document.addEventListener("mousedown", mouseLeftButtonHandler, true);
    document.addEventListener("mouseup", mouseLeftButtonHandler, true);
    document.addEventListener("click", mouseLeftButtonHandler, true);

    return () => {
      document.removeEventListener("click", mouseLeftButtonHandler, true);
      document.removeEventListener("mouseup", mouseLeftButtonHandler, true);
      document.removeEventListener("mousedown", mouseLeftButtonHandler, true);
    };
  }, [ctx, ref]);
}

function Tour() {
  const ctx = useTourContext();

  useButtonHandlers(ctx.tourNode, ctx);
  useClickHandlers(ctx.tourNode, ctx);

  if (!ctx.tourEnabled) return null;
  if (!ctx.tourCanBeShown) return null;

  return (
    <div
      className="tour"
      style={{ clipPath: `path('${ctx.clipPath}')` }}
      ref={ctx.onTourRefChange}
    >
      {ctx.highlightedElements.map((el) => {
        const elementType = el.type;
        const borderWidth = 2;
        const style = {
          width: `${el.width + borderWidth * 2}px`,
          height: `${el.height + borderWidth * 2}px`,
          left: `${el.x - borderWidth}px`,
          top: `${el.y - borderWidth}px`,
          borderWidth: `${borderWidth}px`,
        };

        return (
          <div key={el.id}>
            <div className={`element-highlight ${elementType}`} style={style} />
            <div className={`animated-shadow  ${elementType}`} style={style} />
          </div>
        );
      })}
    </div>
  );
}
export default observer(Tour);

export const TourInfo = observer(function () {
  const ctx = useTourContext();
  const { formatMessage } = useIntl();

  if (!ctx.tourEnabled) return null;
  if (!ctx.tourCanBeShown) return null;

  const templateReplacements = {
    waterVolumeInfoURL: (
      <a
        className="link"
        target="_blank"
        rel="noreferrer noopener"
        href={formatMessage({
          id: "texts.properties.systemElements.waterSupply.waterVolumeInfoURL",
        })}
      >
        {formatMessage({
          id: "tour.systemElements.waterSupply.propertiesStep.description.infoLinkTitle",
        })}
      </a>
    ),
    videoLink: (
      <a
        className="link"
        target="_blank"
        rel="noreferrer noopener"
        href={formatMessage({
          id: "texts.steps.systemElements.waterSupplyDialog.videoLink",
        })}
      >
        {formatMessage({
          id: "tour.systemElements.waterSupply.propertiesStep.description.videoLinkTitle",
        })}
      </a>
    ),
    shouldBeIrregatedIcon: <ShouldBeIrregatedSVG />,
    driplineIcon: <DriplineSVG />,
    noIrregationIcon: <NoIrregationSVG />,
    canBeIrregatedIcon: <CanBeIrregatedSVG />,
    crossAbilityIcon: <CrossAbilitySVG />,
    ul: (chunks) => <div className="tour-info-steps-icon-list">{chunks}</div>,
    b: (chunks) => <span style={{ fontWeight: "bold" }}>{chunks}</span>,
    waterSource: <WaterSourceSVG />,
    valveBox: <ValveBoxSVG />,
    sprinkler: <SprinklerSVG />,
    rzws: <RzwsSVG />,
    raisedBed: <RaisedBedSVG />,
    selectTool: <SelectToolSVG />,
    pipelineAddTour: <PipeAddSVG />,
    dripLineArea: <DripLineAreaSVG />,
  };

  const classNames = ["tour-info"];
  if (ctx.tourStep.visualOptions?.infoPositionClass) {
    classNames.push(ctx.tourStep.visualOptions.infoPositionClass);
  }

  return (
    <div className={classNames.join(" ")}>
      <div className="tour-info-title">
        {formatMessage({ id: ctx.tourStep.title })}
      </div>

      <div className="tour-info-description">
        <FormattedMessage
          id={ctx.tourStep.description}
          values={templateReplacements}
        />
      </div>

      <div className="tour-info-controls">
        <button onClick={async () => ctx.resetTour()}>
          {formatMessage({ id: "tour.tourInfo.leave.button" })}
        </button>

        <div className="step-controls">
          <button
            className="with-margin"
            disabled={!ctx.previousStepAvailable}
            onClick={async () => {
              if (
                typeof ctx.tourStep.validate === "function" &&
                !ctx.tourStep.validate()
              ) {
                return false;
              }

              const previousIndex = ctx.computePreviousRelevantStepIndex();
              if (previousIndex >= 0) {
                await ctx.goToStep(previousIndex);
              }
            }}
          >
            {"<"}
          </button>

          <button
            id="tour-button-next"
            disabled={!ctx.nextStepAvailable}
            onClick={async () => {
              if (
                typeof ctx.tourStep.validate === "function" &&
                !ctx.tourStep.validate()
              ) {
                return false;
              }

              const nextIndex = ctx.computeNextRelevantStepIndex();
              await ctx.goToStep(nextIndex);
            }}
          >
            {">"}
          </button>
        </div>
      </div>
    </div>
  );
});
