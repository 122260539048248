import React from "react";
import { observer } from "mobx-react";

import Properties from "./Properties";

import { ReactComponent as RaisedBedSVG } from "../../assets/Hochbeet_Icon_Eigen.svg";
import { useIntl } from "react-intl";
import useStores from "../../hooks/useStores";

function RaisedBedProperties({
  onDisable,
  disabled,
  onRemove,
  onChange,
  labels,
}) {
  const { formatMessage } = useIntl();

  return (
    <Properties
      onDisable={onDisable}
      disabled={disabled}
      onRemove={onRemove}
      onChange={onChange}
      header={{
        icon: <RaisedBedSVG />,
        title: formatMessage({ id: labels }),
      }}
    ></Properties>
  );
}

let RaisedBedPropertiesWithState = () => {
  const { uiState } = useStores();
  const { selectedElement } = uiState;
  return (
    <RaisedBedProperties
      onRemove={uiState.onRemoveElement}
      onDisable={selectedElement.onDisable}
      disabled={selectedElement.disabled || !uiState.planIsEditable}
      onChange={
        uiState.reactions ? uiState.reactions.onSprinklerChange : () => {}
      }
      labels={
        uiState.settingsState
          ? uiState.settingsState.texts.properties.raisedBed.title
          : null
      }
    />
  );
};

RaisedBedPropertiesWithState = observer(RaisedBedPropertiesWithState);
export default RaisedBedPropertiesWithState;
