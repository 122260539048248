import { extendObservable, action, observable } from "mobx";
import {
  getAllBomList,
  groupBomByBomId,
  normalizeBomItems,
} from "../../utils/bomUtils";

import bomItemFactory from "../types/bomItemFactory";

/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default (plan, settingsState) => (bomItems) => {
  const pricesDictionary = observable.map({});

  extendObservable(plan, {
    get pricesDictionary() {
      return pricesDictionary;
    },
    bomItems:
      Array.isArray(bomItems) && bomItems.length > 0
        ? groupBomByBomId([
            ...normalizeBomItems(bomItems, settingsState),
            ...getAllBomList(settingsState),
          ]).map((data) => bomItemFactory(data, pricesDictionary))
        : [],
    addBomItemsToStorage: action((bomItems) => {
      plan.clearBomItems();
      for (const data of bomItems) {
        const item = data ? bomItemFactory(data, pricesDictionary) : undefined;
        if (item) plan.bomItems.push(item);
      }

      plan.applyBomType(plan.bomType, plan.bomItems);
    }),
    clearBomItems: () => plan.bomItems.replace([]),
  });
};
