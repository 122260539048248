import { extendObservable, observable, action } from "mobx";
import { v4 as uuid } from "uuid";

import {
  lineLength,
  calculateAngleByPoints,
} from "@dvsproj/ipat-core/geometryUtils";
import { calculateSvgPointsDirection } from "../../utils/uiUtils";

/**
 * Represents an area point object
 * @param {*} param0 - area point json
 * @returns area point object
 * contains
 * id - point identifier
 * point type - "point"(default) or "control-point" (control point of bezier curve)
 * x,y - point coordinates
 * isDefault - used only for bezier control point. If value is true, then curve is line
 */
const areaPointFactory = ({ id, type, x, y, isDefault = true } = {}) => {
  const state = observable({
    x: Math.round(x),
    y: Math.round(y),
  });

  //inject variables
  const point = observable({
    id: id || "area-point-" + uuid(),
    isDefault,
    get x() {
      return point.isDefault &&
        point.isControlPoint &&
        this.nextPoint &&
        this.prevPoint
        ? (this.nextPoint.x + this.prevPoint.x) / 2
        : state.x;
    },
    set x(nx) {
      state.x = nx;
    },
    get y() {
      return point.isDefault &&
        point.isControlPoint &&
        this.nextPoint &&
        this.prevPoint
        ? (this.nextPoint.y + this.prevPoint.y) / 2
        : state.y;
    },
    set y(ny) {
      state.y = ny;
    },
  });

  // getters
  extendObservable(point, {
    get type() {
      return type;
    },
    /**
     * Angle value in point
     * Calculated using the previous and next points
     * as well as using the area points direction
     */
    get angle() {
      if (
        point.isControlPoint ||
        this.prevPoint == null ||
        this.nextPoint == null
      ) {
        return undefined;
      }
      const prev = this.prevPoint;
      const next = this.nextPoint;
      const points = [prev, this, next];
      const angle = calculateAngleByPoints(...points);
      return this.pointsDirection === this.direction ? angle : 360 - angle;
    },
    // line length by bezier curve control points
    get length() {
      if (!point.isControlPoint) {
        return undefined;
      }
      return lineLength(this.prevPoint, this.nextPoint, this);
    },
    // current point direction
    // calculated using the previous and next points
    get direction() {
      if (this.prevPoint == null || this.nextPoint == null) {
        return undefined;
      }
      const points = [this.prevPoint, this, this.nextPoint];
      return calculateSvgPointsDirection(points);
    },
    get isControlPoint() {
      return type === "control-point";
    },
    get bezierCurveCenterPoint() {
      if (this.isControlPoint && this.prevPoint && this.nextPoint) {
        //iT * iT * p1 + 2 * iT * t * p2 + t * t * p3
        return {
          x: 0.25 * this.prevPoint.x + 0.5 * this.x + 0.25 * this.nextPoint.x,
          y: 0.25 * this.prevPoint.y + 0.5 * this.y + 0.25 * this.nextPoint.y,
        };
      }
      return null;
    },
    get toJSON() {
      return {
        id: this.id,
        type: this.type,
        x: this.x,
        y: this.y,
        isDefault: this.isDefault,
      };
    },
  });

  // actions
  extendObservable(point, {
    move: action((nx, ny) => {
      state.x = nx;
      state.y = ny;
      point.isDefault = false;
    }),
  });

  return point;
};

export default areaPointFactory;
