import Button from "../../elements/Button";

function StepControl({ title, disabled = false, onClick }) {
  return (
    <Button
      className="steps-control"
      title={title}
      disabledClass={disabled}
      onClick={onClick}
    />
  );
}

export default StepControl;
