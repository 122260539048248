import React from "react";
import { observer } from "mobx-react";

import { toJS } from "mobx";

import CircleArea from "./CircleArea";
import RectangleArea from "./RectangleArea";
import PolygonArea from "./PolygonArea";
import { getSvgPoint } from "@dvsproj/ipat-core/planUtils";
import DefaultSVGStyles from "./../DefaultSVGStyles";
import DefaultSVGFilters from "./../DefaultSVGFilters";
import AreaSize from "./AreaSize";
import usePlanSizeValidation from "../../hooks/usePlanSizeValidation";
import useStores from "../../hooks/useStores";

const Areas = ({
  width,
  height,
  viewbox,
  areas,
  zoomDelta,
  scale,
  precision,
  onChange,
  remove,
  planIsEditable,
  selectedElementId,
  textsVisibility,
  labels,
  useDefaultStyles = true,
  setSelectedElement = () => {},
  grayscale = false,
}) => {
  const ref = React.useRef();
  const [areasContainer, areasSizeContainer] = React.useMemo(() => {
    let container = [];
    let sizeContainer = [];
    areas.forEach((element) => {
      let Component;
      let areaSizeOptions;
      switch (element.areaType) {
        case "circle":
          Component = CircleArea;
          areaSizeOptions = {
            transform: `translate(${element.x}, ${element.y})`,
          };
          break;
        case "rectangle":
          Component = RectangleArea;
          areaSizeOptions = {
            transform: `translate(${element.x}, ${element.y})`,
          };
          break;
        default:
          Component = PolygonArea;
          areaSizeOptions = {
            transform: `translate(${element.pointsCenter.x}, ${element.pointsCenter.y})`,
          };
          break;
      }
      container.push(
        <Component
          key={element.id}
          element={element}
          getSvgPoint={(e) => getSvgPoint(e, ref.current)}
          zoomDelta={zoomDelta}
          scale={scale}
          precision={precision}
          onChange={onChange}
          planIsEditable={planIsEditable}
          selectedElementId={selectedElementId}
          setSelectedElement={setSelectedElement}
          useDefaultStyles={useDefaultStyles}
          textsVisibility={textsVisibility}
          remove={remove}
        />
      );
      if (["should", "dripline"].indexOf(element.quantity) >= 0) {
        sizeContainer.push(
          <AreaSize
            {...areaSizeOptions}
            key={element.id}
            size={element.size}
            zoomDelta={zoomDelta}
            label={labels.areaSizeLabels[element.quantity]}
          />
        );
      }
    });
    return [container, sizeContainer];
  }, [
    areas,
    onChange,
    planIsEditable,
    precision,
    scale,
    selectedElementId,
    setSelectedElement,
    textsVisibility,
    useDefaultStyles,
    zoomDelta,
    labels,
    remove,
  ]);
  return (
    <svg
      className="areas-layer"
      viewBox={`${viewbox.x} ${viewbox.y} ${viewbox.w} ${viewbox.h}`}
      width={textsVisibility.areaSize ? viewbox.w : width}
      height={textsVisibility.areaSize ? viewbox.h : height}
      ref={ref}
    >
      {useDefaultStyles && <DefaultSVGStyles />}
      <DefaultSVGFilters />
      <g filter={grayscale ? "url(#grayscale)" : ""}>
        {areasContainer}
        {textsVisibility.areaSize && <g>{areasSizeContainer}</g>}
      </g>
    </svg>
  );
};

let AreasWithState = ({ viewbox, areas, ...props }) => {
  const { uiState } = useStores();
  const planSizeValidation = usePlanSizeValidation();

  return (
    <Areas
      {...props}
      viewbox={toJS(viewbox)}
      areas={areas.slice()}
      selectedTool={uiState.selectedTool}
      zoomDelta={uiState.zoomState.zoomDelta}
      scale={uiState.plan ? uiState.plan.scale : null}
      precision={uiState.precision}
      onChange={
        uiState.reactions
          ? (params) => {
              uiState.reactions.onAreaChange(params);
              planSizeValidation();
            }
          : () => {}
      }
      remove={uiState.onRemoveElement}
      planIsEditable={uiState.planIsEditable}
      selectedElementId={uiState.selectedElementId}
      setSelectedElement={
        uiState.selectedTool === "select"
          ? uiState.setSelectedElement
          : () => {}
      }
      textsVisibility={uiState.textsVisibility}
      useDefaultStyles={uiState.useDefaultStyles}
      grayscale={uiState.grayscale.area}
      labels={
        uiState.settingsState
          ? uiState.settingsState.texts.properties.areas
          : null
      }
    />
  );
};

AreasWithState = observer(AreasWithState);

export { AreasWithState as Areas };

export default AreasWithState;
