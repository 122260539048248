import React from "react";
import { observer } from "mobx-react";

import Properties from "./Properties";

import { ReactComponent as RzwsSVG } from "../../assets/Baum_Icon_Eigen.svg";
import { useIntl } from "react-intl";
import useStores from "../../hooks/useStores";

function RzwsProperties({ onDisable, disabled, onRemove, onChange, labels }) {
  const { formatMessage } = useIntl();

  return (
    <Properties
      onDisable={onDisable}
      disabled={disabled}
      onRemove={onRemove}
      onChange={onChange}
      header={{
        icon: <RzwsSVG />,
        title: formatMessage({ id: labels }),
      }}
    ></Properties>
  );
}

let RzwsPropertiesWithState = () => {
  const { uiState } = useStores();
  const { selectedElement } = uiState;
  return (
    <RzwsProperties
      onRemove={uiState.onRemoveElement}
      onDisable={selectedElement.onDisable}
      disabled={selectedElement.disabled || !uiState.planIsEditable}
      onChange={
        uiState.reactions ? uiState.reactions.onSprinklerChange : () => {}
      }
      labels={
        uiState.settingsState
          ? uiState.settingsState.texts.properties.rzws.title
          : null
      }
    />
  );
};

RzwsPropertiesWithState = observer(RzwsPropertiesWithState);
export default RzwsPropertiesWithState;
