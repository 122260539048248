import React from "react";
import { observer } from "mobx-react";

import { useIntl } from "react-intl";

import { ReactComponent as CenterSVG } from "../assets/to-center.svg";
import { ReactComponent as ViewSVG } from "../assets/view.svg";
import { ReactComponent as MasstabSVG } from "../assets/masstab.svg";
import { ReactComponent as GridSVG } from "../assets/grid.svg";
import { ReactComponent as OpacitySVG } from "../assets/opacity.svg";
import { ReactComponent as HandSVG } from "../assets/hand.svg";
import { ReactComponent as ScalePlusSVG } from "../assets/scale-plus.svg";
import { ReactComponent as ScaleMinusSVG } from "../assets/scale-minus.svg";
import OpacityPopup from "./popups/OpacityPopup";
import PipeViewPopup from "./popups/PipeViewPopup";
import Tooltip from "./tooltip/Tooltip";
import ToolButton from "./elements/ToolButton";
import useStores from "../hooks/useStores";

const ScaleControl = ({ onClick, disabled = false, children }) => (
  <ToolButton
    className={`scale-item ${disabled ? "disabled" : ""}`}
    onMouseOver={(e) => {
      if (!disabled) {
        e.target.closest(".scale-item").classList.add("active");
      }
    }}
    onMouseOut={(e) => {
      e.target.closest(".scale-item").classList.remove("active");
    }}
    onClick={!disabled ? onClick : null}
  >
    {children}
  </ToolButton>
);

function ScaleItem({
  id,
  hasActive,
  onClick,
  icon,
  popup = null,
  hasLine = false,
  disabled = false,
  tooltip,
}) {
  return (
    <React.Fragment>
      <ToolButton
        id={id}
        className={`scale-item ${hasActive ? "active" : ""} ${
          disabled ? "disabled" : ""
        }`}
      >
        {popup ? popup : null}
        <Tooltip
          tooltip={!(popup && hasActive) ? tooltip : null}
          position="top"
        >
          <i
            onClick={!disabled ? onClick : null}
            onMouseOver={(e) => {
              e.target.closest(".scale-item").classList.add("active");
            }}
            onMouseOut={(e) => {
              if (!hasActive) {
                e.target.closest(".scale-item").classList.remove("active");
              }
            }}
          >
            {icon}
          </i>
        </Tooltip>
      </ToolButton>
      {hasLine ? <div className="scale-line"></div> : ""}
    </React.Fragment>
  );
}

const Scale = ({
  zoomIn,
  zoomOut,
  toCenter,
  grid,
  selectedTool,
  setSelectedTool,
  selectedOtherTool,
  setSelectedOtherTool,
  showGrid,
  changeShowGrid,
  step,
  planBackground,
  hasPipeline,
  labels,
  planIsEditable,
  hiddenPipeCount,
}) => {
  const { formatMessage } = useIntl();

  const hasToolsEnabled = step.name !== "upload";

  return (
    <div className="scale">
      {hasPipeline && (
        <ScaleItem
          id="pipe-view-tool"
          icon={
            <>
              <ViewSVG />
              {hiddenPipeCount > 0 ? (
                <div className="hidden-pipe-count">{hiddenPipeCount}</div>
              ) : null}
            </>
          }
          hasLine={true}
          hasActive={selectedOtherTool === "pipe-view"}
          disabled={!hasToolsEnabled}
          onClick={() => {
            setSelectedOtherTool("pipe-view");
          }}
          tooltip={
            labels ? formatMessage({ id: labels.pipeView.tooltip }) : null
          }
          popup={
            <PipeViewPopup
              hasActive={selectedOtherTool === "pipe-view"}
              onClose={() => {
                setSelectedOtherTool();
              }}
            />
          }
        />
      )}
      {planBackground?.src && step.name === "areas" && planIsEditable ? (
        <ScaleItem
          icon={<MasstabSVG />}
          hasLine={true}
          hasActive={selectedTool === "scale"}
          disabled={!hasToolsEnabled}
          onClick={() => {
            setSelectedTool("scale");
          }}
          tooltip={labels ? formatMessage({ id: labels.scale.tooltip }) : null}
        />
      ) : null}
      <ScaleItem
        icon={<GridSVG />}
        hasLine={true}
        hasActive={grid && showGrid}
        disabled={!hasToolsEnabled}
        onClick={() => {
          changeShowGrid();
        }}
        tooltip={labels ? formatMessage({ id: labels.grid.tooltip }) : null}
      />

      {planBackground?.src && (
        <ScaleItem
          icon={<OpacitySVG />}
          hasLine={true}
          hasActive={selectedTool === "opacity"}
          disabled={!hasToolsEnabled}
          onClick={() => {
            setSelectedTool("opacity");
          }}
          tooltip={
            labels ? formatMessage({ id: labels.opacity.tooltip }) : null
          }
          popup={
            <OpacityPopup
              hasActive={selectedTool === "opacity"}
              position="top"
              onClose={() => {
                setSelectedTool();
              }}
            />
          }
        />
      )}

      <ScaleItem
        hasLine={true}
        icon={<CenterSVG />}
        onClick={toCenter}
        tooltip={labels ? formatMessage({ id: labels.center.tooltip }) : null}
      />

      <ScaleItem
        icon={<HandSVG />}
        hasActive={selectedOtherTool === "draggable"}
        onClick={() => {
          setSelectedOtherTool("draggable");
        }}
        tooltip={labels ? formatMessage({ id: labels.hand.tooltip }) : null}
      />

      <div className="scale-info">
        <div className="scale-text-wrapper">
          {grid ? (
            <div
              className="scale-text"
              style={
                grid && grid.originalSize
                  ? { width: grid.originalSize + 2 }
                  : null
              }
            >{`${grid && grid.value ? grid.value : 1} m`}</div>
          ) : null}
        </div>

        <ScaleControl onClick={zoomIn}>
          <ScalePlusSVG />
        </ScaleControl>
        <ScaleControl onClick={zoomOut}>
          <ScaleMinusSVG />
        </ScaleControl>
      </div>
    </div>
  );
};

let ScaleWithState = () => {
  const { uiState } = useStores();

  const documentCenter = () => {
    const { offsetWidth, offsetHeight } = document.body;

    return {
      x: offsetWidth / 2,
      y: offsetHeight / 2,
    };
  };

  return (
    <Scale
      zoomIn={() => {
        const { zoom, stepDelta, centerZoom } = uiState.zoomState;
        centerZoom(zoom - stepDelta, documentCenter());
        uiState.savePlanPosition();
      }}
      zoomOut={() => {
        const { zoom, stepDelta, centerZoom } = uiState.zoomState;
        centerZoom(zoom + stepDelta, documentCenter());
        uiState.savePlanPosition();
      }}
      toCenter={uiState.toCenter}
      grid={uiState.grid}
      selectedOtherTool={uiState.selectedOtherTool}
      setSelectedOtherTool={(tool) => {
        if (uiState.selectedOtherTool === tool) {
          uiState.setSelectedOtherTool();
        } else {
          uiState.setSelectedOtherTool(tool);
        }
      }}
      selectedTool={uiState.selectedTool}
      setSelectedTool={(tool) => {
        if (uiState.selectedTool === tool) {
          uiState.setSelectedTool();
        } else {
          uiState.setSelectedTool(tool);
        }
      }}
      showGrid={uiState.showGrid}
      changeShowGrid={uiState.changeShowGrid}
      step={uiState.step}
      planBackground={
        uiState.plan != null ? uiState.plan.background : undefined
      }
      planScale={uiState.plan != null ? uiState.plan.scale : undefined}
      planIsEditable={uiState.planIsEditable}
      hasPipeline={uiState.step.name === "pipeline"}
      hiddenPipeCount={uiState.hiddenPipeColors?.length}
      labels={uiState.settingsState ? uiState.settingsState.texts.tools : null}
    />
  );
};

ScaleWithState = observer(ScaleWithState);

export default ScaleWithState;
