import React from "react";
import { observer } from "mobx-react";

import { useIntl } from "react-intl";
import useStores from "../../hooks/useStores";

const SensorCoverageLegend = ({ labels }) => {
  const { formatMessage } = useIntl();
  const { uiState } = useStores();
  const { sensorPrecipitationCoverageColors = [] } = uiState.plan ?? {};
  return (
    <div className="sensor-coverage-legend">
      {sensorPrecipitationCoverageColors.map((color, i) => {
        return <i style={{ background: color }} key={i}></i>;
      })}

      <div className="">
        {formatMessage({ id: labels.sensor.legendl1 })}
        <br></br>
        <b className="legend-line-two">
          {formatMessage({ id: labels.sensor.legendl2 })}
        </b>
      </div>
    </div>
  );
};

let SensorCoverageLegendWithState = () => {
  const { uiState } = useStores();
  return (
    <>
      {uiState.plan &&
        uiState.plan.sensorPrecipitationCoverage &&
        uiState.calculatingTitle == null &&
        uiState.stepIdx === uiState.stepIdxByName("sensor") && (
          <SensorCoverageLegend
            legend={uiState.settingsState.precipitationBins}
            labels={
              uiState.settingsState ? uiState.settingsState.texts.steps : null
            }
          />
        )}
    </>
  );
};

SensorCoverageLegendWithState = observer(SensorCoverageLegendWithState);
export default SensorCoverageLegendWithState;
