import React from "react";
import { observer } from "mobx-react";
import useStores from "../../hooks/useStores";

const TooltipContent = ({ tooltipText, tooltipCursorPosition }) => {
  const tooltipRef = React.useRef();
  const { position, coords } = tooltipCursorPosition;

  React.useEffect(() => {
    const node = tooltipRef.current;

    if (node) {
      switch (position) {
        case "bottom":
          node.style.left = coords.x + coords.width / 2 + "px";
          node.style.top = coords.y + coords.height + "px";
          break;
        case "right":
          node.style.left = coords.x + coords.width + "px";
          node.style.top =
            coords.y + coords.height / 2 - node.clientHeight / 2 + "px";
          break;
        case "top":
          node.style.left = coords.x + coords.width / 2 + "px";
          node.style.top = coords.y - node.clientHeight + "px";
          break;
        default:
          console.error("Invalid position");
          break;
      }
      node.style.opacity = 1;
      node.style.visibility = "visible";
    }
  });

  return (
    <div ref={tooltipRef} className={`tooltip-wrap ${position}`}>
      <div className="tooltip-content">
        <div
          className={`tooltip-text`}
          dangerouslySetInnerHTML={{
            __html: tooltipText,
          }}
        ></div>
        <div className="tooltip-arrow"></div>
      </div>
    </div>
  );
};

let TooltipContentWithState = () => {
  const { uiState } = useStores();
  if (uiState.tooltipText == null) return null;
  return (
    <TooltipContent
      tooltipText={uiState.tooltipText}
      tooltipCursorPosition={uiState.tooltipCursorPosition}
    />
  );
};

TooltipContentWithState = observer(TooltipContentWithState);
export default TooltipContentWithState;
