const OderComponent = ({ text }) => {
  return (
    <div className="plan-load-or">
      <div className="line-wrap">
        <div
          className="line"
          style={{
            "--border": "2px dashed var(--active-color)",
          }}
        />
      </div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="54"
        height="54"
        viewBox="0 0 54 54"
      >
        <g transform="translate(-517 -237)">
          <circle
            fill="#c5d75d"
            cx="27"
            cy="27"
            r="27"
            transform="translate(517 237)"
          />
          <text
            style={{
              fill: "#fff",
              fontSize: "23px",
              fontFamily: "inherit",
              fontWeight: "500",
              letterSpacing: "0.05em",
            }}
            transform="translate(544 264)"
            textAnchor="middle"
          >
            <tspan
              x="0"
              y="0"
              alignmentBaseline="middle"
              dominantBaseline="middle"
            >
              {text}
            </tspan>
          </text>
        </g>
      </svg>
    </div>
  );
};

export default OderComponent;
