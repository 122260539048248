import { observer } from "mobx-react-lite";
import { useIntl } from "react-intl";

import HeaderButton from "./HeaderButton";

import { ReactComponent as CsvSVG } from "../../assets/csv.svg";
import { convertBomListByBoxes } from "../../utils/bomUtils";

import bomItemFactory from "../../store/types/bomItemFactory";
import { UserCsvExport } from "../../utils/csv/user-csv-export";
import { convertBlobToBase64 } from "../../utils/uiUtils";
import { downloadFileFromBase64 } from "../elements/Link";

let DownloadCsvButton = ({
  settingsState,
  plan,
  planName,
  pricesDictionary,
}) => {
  const intl = useIntl();
  const { formatMessage } = intl;

  const planBomItems = convertBomListByBoxes(settingsState, plan.bomItems ?? [])
    .map((e) => bomItemFactory(e, pricesDictionary))
    .filter(({ name, quantity }) => name != null && quantity > 0);

  return (
    <HeaderButton
      onClick={async () => {
        const filename =
          formatMessage(
            {
              id: "texts.steps.bom.popup.csvFile.fileName",
            },
            { planName }
          ) + ".csv";

        const csvExport = new UserCsvExport(intl, planBomItems);

        csvExport.generate();
        const blob = csvExport.getToBlob();

        downloadFileFromBase64(await convertBlobToBase64(blob), filename);
      }}
      Icon={CsvSVG}
    />
  );
};
DownloadCsvButton = observer(DownloadCsvButton);

export default DownloadCsvButton;
