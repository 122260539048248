import React from "react";

const DefaultSVGStyles = () => {
  return (
    <defs>
      <style type="text/css">{`
        text { font-family: Swis721 Cn BT; font-stretch: condensed; letter-spacing: 0.6px; word-spacing: 0.6px; kerning: 0.6px; line-height: 1.25; fill: #3e4346;} 
        circle.size { display: none; } 
        .sector, .rect-sector { stroke: rgb(62, 67, 70); } 
        path.sector-start {display: none;}
        .invalid {
          fill: #ff7600;
        }
    `}</style>
    </defs>
  );
};
export default DefaultSVGStyles;
