import { extendObservable, action } from "mobx";

/**
 * Injects showTooltip and hideTooltip methods
 */
/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default state => {
  // inject variables
  extendObservable(state, {
    tooltipText: undefined,
    tooltipCursorPosition: undefined,
  });

  // inject API
  extendObservable(state, {
    showTooltip: action((text, cursorPosition) => {
      state.tooltipText = text;
      state.tooltipCursorPosition = cursorPosition;
    }),
    hideTooltip: action(() => {
      state.tooltipText = undefined;
      state.tooltipCursorPosition = undefined;
    }),
  });
};
