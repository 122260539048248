import { extendObservable, action } from "mobx";

/**
 * Injects reactions to elements/bomItems change.
 */
/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default (plan) => {
  const reactions = {
    onAreaChange: action(() => {
      plan.sprinklersNeedToRecalculate = true;
      plan.pipesNeedToRecalculate = true;
      plan.clearBomItems();
      plan.bomType = undefined;

      plan.cleanupRaster();
      plan.cleanupSprinklerCoverage();
      plan.cleanupSensorCoverage();
    }),
    onSprinklerChange: action(() => {
      plan.sprinklersModified = true;
      plan.pipesNeedToRecalculate = true;
      plan.checkPipeElements();
      plan.clearBomItems();
      plan.bomType = undefined;

      plan.fillSprinklerCoverage();
      plan.cleanupSensorCoverage();
    }),
    onSystemElementChange: action(() => {
      plan.pipesNeedToRecalculate = true;
      plan.checkPipeElements();
      plan.clearBomItems();
      plan.bomType = undefined;

      plan.cleanupSensorCoverage();
    }),
    onPipelineChange: action((...params) => {
      plan.pipesModified = true;
      plan.clearBomItems();
      plan.bomType = undefined;

      plan.cleanupSensorCoverage();
      plan.cleanupPipelineCircuits();
      plan.recalculatePipelineCircuits();
      plan.cleanupTrenches();
      plan.recalculateTrenches(...params);
    }),
    onPipesModifiedAtChange: action((...params) => {
      plan.cleanupPipelineCircuits();
      plan.recalculatePipelineCircuits();
      plan.cleanupTrenches();
      plan.recalculateTrenches(...params);
    }),
    onSensorElementChange: action(() => {
      plan.sensorModified = true;
      plan.clearBomItems();
      plan.bomType = undefined;
    }),
    onBomItemChange: action(() => {
      plan.bomModified = true;
    }),
  };

  extendObservable(plan, {
    onElementChange: (type) => {
      let reaction = undefined;
      switch (type) {
        case "sprinkler":
        case "rzws":
        case "raised-bed":
          reaction = reactions.onSprinklerChange;
          break;
        case "area":
          reaction = reactions.onAreaChange;
          break;
        case "pipe":
        case "pipeline-point":
          reaction = reactions.onPipelineChange;
          break;
        case "sensor":
          reaction = reactions.onSensorElementChange;
          break;
        case "system-element":
          reaction = reactions.onSystemElementChange;
          break;
        default:
          break;
      }

      if (typeof reaction === "function") reaction();
    },
    reactions,
  });
};
