const trenchingPipelinesAdapter = (pipelines = []) => {
  let result = [];
  pipelines.forEach((el) => {
    const color = el.color ?? el.pipelines?.[0]?.color ?? "#9EA1A2";
    result.push({
      id: el?.id,
      type: el?.type,
      color,
      lines: el?.lines?.map(
        ({ id, start, end, type, disabled, startPoint, endPoint }) => {
          return {
            id,
            start,
            end,
            type,
            color,
            disabled,
            startPoint: startPoint?.toJSON,
            endPoint: endPoint?.toJSON,
          };
        }
      ),
    });
  });
  return result;
};

export default trenchingPipelinesAdapter;
