import * as React from "react";
import { observer } from "mobx-react";

import { sizeInPixelByMeters } from "@dvsproj/ipat-core/planUtils";
import useStores from "../../hooks/useStores";

let Coverage = ({
  viewbox,
  zoomDelta,
  precipitationCoverage = {},
  coverageIsActive,
  scale,
  opacity,
}) => {
  const { offsetX, offsetY, width, height, data } = precipitationCoverage;

  const ref = React.useRef();

  React.useEffect(() => {
    const canvas = ref.current;
    const context = canvas.getContext("2d");
    context.imageSmoothingEnabled = false;

    if (data != null) {
      canvas.width = width;
      canvas.height = height;

      let idata = context.createImageData(width, height);
      for (let i = 0; i < idata.data.length; i++) idata.data[i] = data[i];

      context.putImageData(idata, 0, 0);
    } else {
      context.clearRect(0, 0, canvas.width, canvas.height);
    }
  }, [data, height, width]);

  const style = React.useMemo(() => {
    if (data != null) {
      const converter = (val) => sizeInPixelByMeters(val / 10, scale);

      const canvasScale = converter(width) / width / zoomDelta;

      return {
        left: `${(converter(offsetX) - viewbox.x) / zoomDelta}px`,
        top: `${(converter(offsetY) - viewbox.y) / zoomDelta}px`,
        transform: `scale(${canvasScale})`,
        width,
        height,
        opacity: coverageIsActive ? opacity : 0,
      };
    } else {
      return {};
    }
  }, [
    coverageIsActive,
    data,
    height,
    offsetX,
    offsetY,
    scale,
    viewbox.x,
    viewbox.y,
    width,
    zoomDelta,
    opacity,
  ]);

  return (
    <canvas
      ref={ref}
      style={{
        position: "relative",
        transformOrigin: "top left",
        maxWidth: "unset",
        ...style,
      }}
    />
  );
};

Coverage = observer(Coverage);

const COVERAGE_BY_TYPE = {
  sprinkler: {
    precipitationCoverageKey: "precipitationCoverage",
    fillCoverageKey: "fillSprinklerCoverage",
  },
  sensor: {
    precipitationCoverageKey: "sensorPrecipitationCoverage",
    fillCoverageKey: "fillSensorCoverage",
  },
};

let CoverageWithState = ({ viewbox, type, opacity }) => {
  const { uiState } = useStores();

  const {
    zoomState: { zoomDelta },
    plan = {},
    calculatingTitle,
  } = uiState;

  const { precipitationCoverageKey, fillCoverageKey } = COVERAGE_BY_TYPE[type];

  const precipitationCoverage = plan[precipitationCoverageKey];
  const fillCoverage = plan[fillCoverageKey];

  React.useEffect(() => {
    if (typeof fillCoverage === "function") {
      fillCoverage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (precipitationCoverage == null) return null;

  return (
    <Coverage
      opacity={opacity}
      viewbox={viewbox}
      zoomDelta={zoomDelta}
      scale={plan.scale}
      precipitationCoverage={precipitationCoverage}
      coverageIsActive={
        plan && precipitationCoverage && calculatingTitle == null
      }
    />
  );
};

CoverageWithState = observer(CoverageWithState);
export default CoverageWithState;
