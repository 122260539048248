import React from "react";
import { observer } from "mobx-react";

import WindowPopup from "./WindowPopup";
import { useIntl } from "react-intl";
import useStores from "../../hooks/useStores";
import CalculatonGifURL from "../../assets/calculation.gif";

function CalculationPopup({ calculatingTitle, calculatingText }) {
  return (
    <WindowPopup title={calculatingTitle} className="calculation">
      <div>
        <div className="calculation-icon">
          <img alt="" width="75px" height="90px" src={CalculatonGifURL} />
        </div>
        <div className="calculation-text">{calculatingText}</div>
      </div>
    </WindowPopup>
  );
}

let CalculationPopupWithState = () => {
  const { uiState } = useStores();
  const { formatMessage } = useIntl();

  const { calculatingTitle } = uiState;
  if (calculatingTitle == null) return null;

  return (
    <CalculationPopup
      calculatingTitle={formatMessage({ id: calculatingTitle })}
      calculatingText={formatMessage({
        id: uiState.settingsState.texts.steps.calculationText,
      })}
    />
  );
};

CalculationPopupWithState = observer(CalculationPopupWithState);
export default CalculationPopupWithState;
