import * as React from "react";
import {
  pixelSizeByZoom,
  svgTextCondensed,
} from "@dvsproj/ipat-core/planUtils";
import { round } from "@dvsproj/ipat-core/formatter";
import { useIntl } from "react-intl";

const AreaSize = ({ size, zoomDelta, label, ...props }) => {
  const { formatNumber, formatMessage } = useIntl();
  const roundSize = round(size, 1);
  const fontSize = pixelSizeByZoom(18, zoomDelta);
  return (
    <g
      {...props}
      style={{
        fontSize: fontSize,
        fontWeight: "bold",
      }}
    >
      <g>
        <text
          {...svgTextCondensed}
          x="0"
          y="0"
          dominantBaseline="hanging"
          textAnchor="start"
        >
          {label != null && (
            <tspan
              x="0"
              dy="0"
              dx={-2 * fontSize}
              fontSize={pixelSizeByZoom(12, zoomDelta)}
            >
              {formatMessage({ id: label })}
            </tspan>
          )}
          <tspan x="0" dx={-2 * fontSize} dy={label != null ? "1em" : "0"}>
            {formatNumber(roundSize)} m &#178;
          </tspan>
        </text>
      </g>
    </g>
  );
};

export default AreaSize;
