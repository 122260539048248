import { useIntl } from "react-intl";
import useStores from "./useStores";

const usePlanSizeValidation = () => {
  const { uiState } = useStores();
  const { showAlert, planSizeValidatation, settingsState } = uiState;
  const { formatMessage } = useIntl();

  return () =>
    setTimeout(async () => {
      if (!planSizeValidatation()) {
        const stepLabels = settingsState ? settingsState.texts.steps : null;

        await showAlert({
          title: stepLabels
            ? formatMessage({ id: stepLabels.planSizeErrorTitle })
            : null,
          description: stepLabels
            ? formatMessage({ id: stepLabels.planSizeError })
            : "Error",
        });
      }
    }, 0);
};

export default usePlanSizeValidation;
