import React from "react";
import { observer } from "mobx-react";
import { attach, detach } from "../../utils/HtmlHelpers";

import { pixelSizeByZoom } from "@dvsproj/ipat-core/planUtils";
import { ConnectPipeSVG } from "./PipePointIcons";

const TrenchViewPopup = ({
  id,
  x,
  y,
  point,
  pipes,
  zoomDelta,
  selectedElementId,
  onClose = () => {},
  setSelectedElement = () => {},
}) => {
  const ref = React.useRef();

  //do not copy-paste me without @simdim notification
  React.useEffect(() => {
    const node = ref.current;
    const listener = (e) => {
      e.stopPropagation();
    };
    if (node != null) {
      attach("dragStart", listener, node, {
        passive: false,
        capture: false,
      });
    }
    return () => {
      if (node != null) detach("dragStart", listener, node, { capture: false });
    };
  });

  const height = pixelSizeByZoom(Math.ceil(pipes.length / 4) * 68, zoomDelta);
  const width = pixelSizeByZoom(
    pipes.length > 3 ? 200 : pipes.length * 45 + 20,
    zoomDelta
  );
  const paddingY = height + pixelSizeByZoom(35, zoomDelta);
  return (
    <g
      id={id}
      className="element trench-view-popup"
      transform={`translate(${x}, ${y})`}
      fill="#fff"
      stroke="#3e4346"
      strokeWidth={pixelSizeByZoom(1, zoomDelta)}
    >
      <g
        transform={`translate(${point.x - pixelSizeByZoom(30, zoomDelta)}, ${
          point.y - paddingY
        })`}
      >
        <g ref={ref}>
          <rect
            x={0}
            y={0}
            rx={pixelSizeByZoom(20, zoomDelta)}
            ry={pixelSizeByZoom(20, zoomDelta)}
            width={width}
            height={height}
            stroke="#052334"
            transform="rotate(0 0 0)"
            strokeLinecap="square"
            strokeLinejoin="round"
            fill="#052334"
          />
          <g
            fill="#fff"
            stroke="#fff"
            className="popup-close"
            onClick={(e) => {
              onClose();
              e.stopPropagation();
            }}
            transform={`translate(${pixelSizeByZoom(
              pipes.length > 3 ? 180 : pipes.length * 45 + 5,
              zoomDelta
            )}, ${pixelSizeByZoom(30, zoomDelta)})`}
            style={{
              fontSize: pixelSizeByZoom(20, zoomDelta),
              letterSpacing: 0.6,
            }}
            dominantBaseline="middle"
            textAnchor="middle"
          >
            <text>
              <tspan>&#215;</tspan>
            </text>
          </g>
          <g className={`popup-container`}>
            {pipes.map(({ ids, color }, i) => {
              const selected =
                ids != null &&
                ids.length > 0 &&
                ids.indexOf(selectedElementId) >= 0;
              return (
                <g
                  transform={`translate(${
                    pixelSizeByZoom(10, zoomDelta) +
                    (i % 4) * pixelSizeByZoom(40, zoomDelta)
                  } ${
                    pixelSizeByZoom(10, zoomDelta) +
                    Math.floor(i / 4) * pixelSizeByZoom(40, zoomDelta)
                  }) scale(${pixelSizeByZoom(0.75, zoomDelta)})`}
                  key={`trenchpopup-key-${ids[0]}`}
                  className={`trench-item ${selected ? "active" : ""}`}
                  onClick={(e) => {
                    setSelectedElement(ids[0]);
                    e.stopPropagation();
                  }}
                  strokeWidth={1}
                >
                  <ConnectPipeSVG
                    color={color ? color : "#006CFF"}
                    hasArrows={ids != null && ids.length > 1}
                    stroke={
                      ids.indexOf(selectedElementId) >= 0
                        ? "#c5d75d"
                        : undefined
                    }
                  />
                </g>
              );
            })}
          </g>
          <g
            className="popup-arrow"
            transform={`translate(${pixelSizeByZoom(
              25,
              zoomDelta
            )} ${height}) scale(${pixelSizeByZoom(1.2, zoomDelta)})`}
          >
            <polygon
              fill="#052334"
              stroke="#052334"
              points="0 0 10 0 5 20 0 0"
            ></polygon>
          </g>
        </g>
      </g>
    </g>
  );
};

export default observer(({ element, ...props }) => {
  return (
    <TrenchViewPopup
      x={0}
      y={0}
      id={element.id}
      point={element.centerPoint}
      pipes={element.pipesForPopup}
      {...props}
    />
  );
});
