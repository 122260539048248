import { observable, extendObservable } from "mobx";

/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default (fn, queueMaxLength = +Infinity) => {
  const state = observable({
    isPending: false,
    queueLength: 0,
  });

  extendObservable(state, {
    call: async () => {
      if (!state.isPending) {
        try {
          state.isPending = true;
          if (state.queueLength > 0) {
            state.queueLength--;
          }
          await fn();
        } finally {
          state.isPending = false;
          if (state.queueLength > 0) {
            state.call();
          }
        }
      } else {
        if (state.queueLength < queueMaxLength) {
          state.queueLength++;
        }
      }
    },
  });

  return state;
};
