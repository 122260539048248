import React from "react";
import { observer } from "mobx-react";

import { sizeInPixelByMeters } from "@dvsproj/ipat-core/planUtils";
import useKeydownObserver from "../hooks/useKeyDownObserver";
import useStores from "../hooks/useStores";

/**
 * A moveable g component.
 * @param {*} props
 */
function Moveable({
  x = 0,
  y = 0,
  scale,
  children,
  move,
  onChange,
  onBeforeMove,
  valueDeltaInM = 0.1,
  valueDeltaIncInM = 1,
}) {
  const ref = React.useRef();

  const moveListener = React.useCallback(
    ({ dx, dy }, event) => {
      if (event.target.nodeName.toLowerCase() === "input") return;

      event?.preventDefault();
      const delta =
        event.shiftKey || event.altKey ? valueDeltaIncInM : valueDeltaInM;
      const deltaX = sizeInPixelByMeters(dx * delta, scale);
      const deltaY = sizeInPixelByMeters(dy * delta, scale);
      if (deltaX !== 0 || deltaY !== 0) {
        if (typeof onBeforeMove === "function") {
          onBeforeMove(ref.current);
        }

        move(x + deltaX, y + deltaY);

        if (typeof onChange === "function") {
          onChange(ref.current);
        }
      }
    },
    [valueDeltaIncInM, valueDeltaInM, scale, onBeforeMove, move, x, y, onChange]
  );

  useKeydownObserver("ArrowLeft", moveListener, { dx: -1, dy: 0 });
  useKeydownObserver("ArrowRight", moveListener, { dx: 1, dy: 0 });
  useKeydownObserver("ArrowUp", moveListener, { dx: 0, dy: -1 });
  useKeydownObserver("ArrowDown", moveListener, { dx: 0, dy: 1 });

  return <>{children}</>;
}

let MoveableWithState = ({
  id,
  draggable,
  children,
  moveablePoint,
  ...props
}) => {
  const { uiState } = useStores();
  return (id && id === uiState.selectedElementId && draggable) ||
    moveablePoint ? (
    <Moveable
      selected={id === uiState.selectedElementId}
      selectedTool={uiState.selectedTool}
      scale={uiState.plan.scale}
      {...props}
    >
      {children}
    </Moveable>
  ) : (
    <>{children}</>
  );
};

MoveableWithState = observer(MoveableWithState);

/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default MoveableWithState;
