export function attach(phase, fn, node, config) {
  if (node === void 0) {
    node = window;
  }
  switch (phase) {
    case "dragStart":
      node.addEventListener("mousedown", fn, config);
      node.addEventListener("touchstart", fn, config);
      return fn;
    case "drag":
      node.addEventListener("mousemove", fn, config);
      node.addEventListener("touchmove", fn, config);
      return fn;
    case "drop":
      node.addEventListener("mouseup", fn, config);
      node.addEventListener("touchend", fn, config);
      return fn;
    default:
      throw new Error("Invalid phase " + phase);
  }
}

export function detach(phase, fn, node, config) {
  if (node === void 0) {
    node = window;
  }
  switch (phase) {
    case "dragStart":
      node.removeEventListener("mousedown", fn, config);
      node.removeEventListener("touchstart", fn, config);
      return fn;
    case "drag":
      node.removeEventListener("mousemove", fn, config);
      node.removeEventListener("touchmove", fn, config);
      return fn;
    case "drop":
      node.removeEventListener("mouseup", fn, config);
      node.removeEventListener("touchend", fn, config);
      return fn;
    default:
      throw new Error("Invalid phase " + phase);
  }
}
