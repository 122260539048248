import React from "react";
import { observer } from "mobx-react";

import ToolsItem from "./ToolsItem";
import { ReactComponent as PipelineAddSVG } from "./../../assets/pipeline-add.svg";
import { ReactComponent as PipelineRecalcSVG } from "./../../assets/pipeline-recalc.svg";
import { useIntl } from "react-intl";
import useStores from "../../hooks/useStores";

function PipelineTools({
  selectedTool,
  setSelectedTool,
  labels,
  calculatePipelines,
}) {
  const { formatMessage } = useIntl();

  return (
    <React.Fragment>
      <ToolsItem
        icon={<PipelineAddSVG />}
        type="pipeline-add"
        isActive={selectedTool === "pipeline-add"}
        onClick={() => {
          setSelectedTool("pipeline-add");
        }}
        tooltip={labels ? formatMessage({ id: labels.pipeline.tooltip }) : null}
      />
      <ToolsItem
        icon={<PipelineRecalcSVG />}
        onClick={async () => {
          await calculatePipelines({ askRecalculation: true });
        }}
        hasLine={true}
        tooltip={formatMessage({ id: "texts.tools.pipelineRecalc.tooltip" })}
      />
    </React.Fragment>
  );
}

let PipelineToolsWithState = () => {
  const { uiState } = useStores();
  return (
    <PipelineTools
      selectedTool={uiState.selectedTool}
      setSelectedTool={uiState.setSelectedTool}
      labels={uiState.settingsState ? uiState.settingsState.texts.tools : null}
      calculatePipelines={uiState.calculatePipelines}
    />
  );
};

PipelineToolsWithState = observer(PipelineToolsWithState);
export default PipelineToolsWithState;
