let hidden, visibilityChange;
if (typeof document.hidden !== "undefined") {
  // Opera 12.10 and Firefox 18 and later support
  hidden = "hidden";
  visibilityChange = "visibilitychange";
} else if (typeof document.msHidden !== "undefined") {
  hidden = "msHidden";
  visibilityChange = "msvisibilitychange";
} else if (typeof document.webkitHidden !== "undefined") {
  hidden = "webkitHidden";
  visibilityChange = "webkitvisibilitychange";
}

export function addEventListener(callback) {
  window.addEventListener(visibilityChange, callback, false);
}

export function removeEventListener(callback) {
  window.removeEventListener(visibilityChange, callback, false);
}

export function isVisible() {
  return !document[hidden];
}
