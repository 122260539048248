import { observer } from "mobx-react-lite";
import React from "react";
import BomGroup from "./BomGroup";
import SmartGardenBomGroup from "./SmartGardenBomGroup";
import { useIntl } from "react-intl";

let GroupBomList = ({
  groupBomList,
  planIsEditable,
  onBomItemChange,
  buyOnlySomeProducts,
  showCustomerPrice,
  customerPriceMultiplier,
}) => {
  const { formatMessage } = useIntl();

  const bomList = React.useMemo(() => {
    let idx = 0; // TODO:(oleg) what is this? explain
    const result = [];

    for (const { id, label, childs } of groupBomList) {
      const isSmartGarden = id === "smart-garden";

      const Component = isSmartGarden ? SmartGardenBomGroup : BomGroup;
      result.push(
        <Component
          key={id ?? ""}
          groupIndex={idx}
          label={label}
          childs={childs}
          planIsEditable={planIsEditable}
          onBomItemChange={onBomItemChange}
          buyOnlySomeProducts={buyOnlySomeProducts}
          showCustomerPrice={showCustomerPrice}
          customerPriceMultiplier={customerPriceMultiplier}
        />
      );

      idx = idx + childs.length;
    }

    return result;
  }, [
    groupBomList,
    planIsEditable,
    onBomItemChange,
    buyOnlySomeProducts,
    showCustomerPrice,
    customerPriceMultiplier,
  ]);

  return (
    <>
      {bomList.length > 0 ? (
        bomList
      ) : (
        <div className="no-data">
          <span>
            {formatMessage({ id: "texts.steps.bom.popup.noData.text" })}
          </span>
        </div>
      )}
    </>
  );
};

GroupBomList = observer(GroupBomList);
export default GroupBomList;
