import * as React from "react";
import { v4 as uuid } from "uuid";
import { FormattedMessage, useIntl } from "react-intl";
import Button from "./../../elements/Button";
import Checkbox from "../../elements/Checkbox";
import { observer } from "mobx-react";
import { convertPlanJSON } from "@dvsproj/ipat-core/planUtils";
import { temporaryBackground, tempPlan } from "./../../../tempPlan";
import OderComponent from "./OderComponent";
import Link from "../../elements/Link";
import { urlDecorator } from "../../../utils/api";
import { planVersion } from "../../../store/planFactory/planVersion";

let PlanBackgroundForm = ({ labels, createPlan, hasDev }) => {
  const { formatMessage } = useIntl();

  const [terms, changeTerms] = React.useState(false);
  const [isNotify, changeNotify] = React.useState(true);

  return (
    <>
      <div className="plan-type row">
        <div className="plan-load-type">
          <div className="title">
            {labels
              ? formatMessage({ id: labels.uploadPlan.buttonTitle })
              : null}
          </div>
          <Button
            title={
              labels
                ? formatMessage({ id: labels.uploadPlan.button })
                : "upload"
            }
            type="image"
            disabled={!terms}
            onClick={async (data) => {
              createPlan({ background: data, isNotify }, "scale");
            }}
            fileOptions={{
              maxSize: labels.uploadPlan.maxSize,
              maxSizeError: formatMessage({
                id: labels.uploadPlan.maxSizeError,
              }),
              uploadError: formatMessage({
                id: labels.uploadPlan.uploadError,
              }),
            }}
            hint={formatMessage({ id: labels.uploadPlan.fileInfo })}
          />
          {hasDev && (
            <Button
              options={{
                style: {
                  background: "#fff",
                  color: "#000",
                },
              }}
              title="Temp plan"
              onClick={async () => {
                let pixelPlan = convertPlanJSON(tempPlan, "pixel");

                createPlan(
                  {
                    ...pixelPlan,
                    background: temporaryBackground,
                  },
                  "max-calculated"
                );
              }}
            />
          )}
        </div>

        <OderComponent text={formatMessage({ id: labels.uploadPlan.or })} />

        <div className="plan-load-type">
          <div className="title">
            {labels ? formatMessage({ id: labels.drawFree.buttonTitle }) : null}
          </div>
          <Button
            title={
              labels
                ? formatMessage({ id: labels.drawFree.button })
                : "draw free"
            }
            onClick={() => {
              createPlan({
                background: null,
                opacity: 1,
                scale: window.innerWidth / 100,
                isNotify,
              });
            }}
            disabled={!terms}
          />
          {hasDev && (
            <Button
              options={{
                style: {
                  background: "#fff",
                  color: "#000",
                },
              }}
              type="file"
              title="Load plan"
              onClick={async (data) => {
                const plan = JSON.parse(atob(data));
                if (plan && !plan.version) {
                  plan.elements
                    .filter(
                      (elem) =>
                        elem.type === "pipeline-point" &&
                        elem.pointType === "water-tap-point" &&
                        elem.parentId == null
                    )
                    .forEach((waterTapWithoutParent) => {
                      waterTapWithoutParent.parentId =
                        "system-element-" + uuid();
                      plan.elements.push({
                        id: waterTapWithoutParent.parentId,
                        x: waterTapWithoutParent.x,
                        y: waterTapWithoutParent.y,
                        type: "system-element",
                        systemType: waterTapWithoutParent.pointType,
                      });
                    });
                  plan.version = planVersion;
                }
                const pixelPlan = convertPlanJSON(plan, "pixel");
                createPlan(pixelPlan, "max-possible");
              }}
            />
          )}
        </div>
      </div>
      <div className="plan-terms row">
        <Checkbox
          value={isNotify}
          onChange={changeNotify}
          label={
            labels ? formatMessage({ id: labels.notification }) : "accept text"
          }
        />
      </div>
      <div className="plan-terms row">
        <Checkbox
          value={terms}
          onChange={changeTerms}
          label={
            <FormattedMessage
              id={labels.accept}
              values={{
                link: (
                  <Link
                    href={urlDecorator(
                      formatMessage({
                        id: "texts.steps.upload.popup.accept.link.url",
                      })
                    )}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {formatMessage({
                      id: "texts.steps.upload.popup.accept.link.title",
                    })}
                  </Link>
                ),
              }}
            />
          }
        />
      </div>
    </>
  );
};

PlanBackgroundForm = observer(PlanBackgroundForm);

export default PlanBackgroundForm;
