import React from "react";

function Radio({ value = false, onChange = () => {}, disabled, label }) {
  const clickHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (!disabled) {
      onChange(true);
    }
  };

  const className = [
    "radio",
    value ? "checked" : undefined,
    disabled ? "disabled" : undefined,
  ]
    .filter((e) => e)
    .join(" ");

  return (
    <div className="radio-wrap">
      <label className={className} onClick={clickHandler}>
        {value ? <i className="circle"></i> : ""}
      </label>
      {label && (
        <label
          onClick={clickHandler}
          dangerouslySetInnerHTML={{ __html: label }}
        />
      )}
    </div>
  );
}

export default Radio;
