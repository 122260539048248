import React from "react";

import { useIntl } from "react-intl";
import Scroll from "../../elements/Scroll";
import Popup from "../../popups/Popup";

import { ReactComponent as NoGo32SVG } from "../../../assets/nogo-x32.svg";
import { ReactComponent as AlarmSVG } from "../../../assets/alarm.svg";

function ErrorText({ error }) {
  const { formatMessage } = useIntl();

  return (
    <>
      <div className="icon" />
      <div>{formatMessage({ id: error })}</div>
    </>
  );
}

function ColorErrorText({ error }) {
  const { formatMessage } = useIntl();

  const { color, messages } = error;

  return (
    <>
      <div className="icon">
        <div
          className="error-tree-key"
          style={{ backgroundColor: color }}
        ></div>
      </div>
      <div className="error-treel-list">
        {messages.length > 1 ? (
          <ul>
            {messages.map((text, key) => (
              <li key={key}>{formatMessage({ id: text })}</li>
            ))}
          </ul>
        ) : (
          formatMessage({ id: messages[0] })
        )}
      </div>
    </>
  );
}

function ErrorsMessages({ errors }) {
  return (
    <>
      {errors.map((m, key) => {
        let Component = undefined;
        if (typeof m === "string") {
          Component = ErrorText;
        } else {
          Component = ColorErrorText;
        }

        return <Component error={m} key={key} />;
      })}
    </>
  );
}

function WarningMessages({ warnings }) {
  const { formatMessage } = useIntl();

  return (
    <>
      {warnings.map((m, key) => (
        <React.Fragment key={key}>
          <div className="icon">
            <AlarmSVG />
          </div>
          <div dangerouslySetInnerHTML={{ __html: formatMessage({ id: m }) }} />
        </React.Fragment>
      ))}
    </>
  );
}

function ErrorPopup({
  title,
  errors = [],
  warnings = [],
  hasActive = false,
  onClose = () => {},
}) {
  const { formatMessage } = useIntl();

  return (
    <Popup
      className="error"
      hasActive={hasActive}
      position="bottom"
      onClose={() => {
        onClose();
      }}
    >
      <div className="error-wrap">
        <Scroll>
          <div className="error-content">
            {title && (
              <>
                <div className="icon">
                  <NoGo32SVG />
                </div>
                <div className="error-title">
                  {formatMessage({ id: title })}
                </div>
              </>
            )}

            {errors.length > 0 && <ErrorsMessages errors={errors} />}
            {warnings.length > 0 && <WarningMessages warnings={warnings} />}
          </div>
        </Scroll>
      </div>
    </Popup>
  );
}

export default ErrorPopup;
