import React from "react";
import { observer } from "mobx-react";

import Properties from "./Properties";

import { ReactComponent as SensorSVG } from "./../../assets/sensor-plan-x24.svg";
import { useIntl } from "react-intl";
import useStores from "../../hooks/useStores";

function SensorProperties({ onDisable, disabled, onRemove, onChange, labels }) {
  const { formatMessage } = useIntl();

  return (
    <Properties
      onDisable={onDisable}
      disabled={disabled}
      onRemove={onRemove}
      onChange={onChange}
      header={{
        icon: <SensorSVG />,
        title: formatMessage({ id: labels }),
      }}
    ></Properties>
  );
}

let SensorPropertiesWithState = () => {
  const { uiState } = useStores();
  const { selectedElement } = uiState;
  return (
    <SensorProperties
      onRemove={uiState.onRemoveElement}
      onDisable={selectedElement.onDisable}
      disabled={selectedElement.disabled || !uiState.planIsEditable}
      onChange={
        uiState.reactions ? uiState.reactions.onSensorElementChange : () => {}
      }
      labels={
        uiState.settingsState
          ? uiState.settingsState.texts.tools.sensor.tooltip
          : null
      }
    />
  );
};

SensorPropertiesWithState = observer(SensorPropertiesWithState);
export default SensorPropertiesWithState;
