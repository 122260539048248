import { observer } from "mobx-react";
import { useIntl } from "react-intl";

import { ReactComponent as MpSVG } from "./../../../assets/mp_sprinklers.svg";
import { ReactComponent as GrSVG } from "./../../../assets/gr_sprinklers.svg";
import OderComponent from "./OderComponent";
import {
  MP_ROTATOR_ID,
  GR_ROTATOR_ID,
} from "@dvsproj/ipat-core/settings/settingsUtils";

const TypeComponent = ({
  active = false,
  onClick,
  Icon,
  title,
  description,
}) => {
  return (
    <button
      type="button"
      onClick={onClick}
      className={active ? "active" : undefined}
    >
      <div className="icon">
        <Icon />
      </div>
      <div className="info">
        <div className="title">{title}</div>
        <div className="description">{description}</div>
      </div>
    </button>
  );
};

let SprinklerTypeForm = ({
  labels,
  sprinklerSetType,
  changeSprinklerSetType,
}) => {
  const { formatMessage } = useIntl();

  return (
    <div className="sprinkler-type">
      <div className="sprinkler-type-title">
        {formatMessage({
          id: "texts.steps.upload.popup.uploadPlan.sprinklerType.title",
        })}
      </div>
      <TypeComponent
        active={sprinklerSetType === MP_ROTATOR_ID}
        onClick={() => {
          changeSprinklerSetType(MP_ROTATOR_ID);
        }}
        Icon={MpSVG}
        title={formatMessage({
          id: "texts.steps.upload.popup.uploadPlan.sprinklerType.mpRotator",
        })}
        description={formatMessage({
          id: "texts.steps.upload.popup.uploadPlan.sprinklerType.mpRotator.description",
        })}
      />
      <OderComponent text={formatMessage({ id: labels.uploadPlan.or })} />

      <TypeComponent
        active={sprinklerSetType === GR_ROTATOR_ID}
        onClick={() => {
          changeSprinklerSetType(GR_ROTATOR_ID);
        }}
        Icon={GrSVG}
        title={formatMessage({
          id: "texts.steps.upload.popup.uploadPlan.sprinklerType.grRotator",
        })}
        description={formatMessage({
          id: "texts.steps.upload.popup.uploadPlan.sprinklerType.grRotator.description",
        })}
      />
    </div>
  );
};

SprinklerTypeForm = observer(SprinklerTypeForm);

export default SprinklerTypeForm;
