import React from "react";
import { observer } from "mobx-react";

import { useDraggable, useDroppable, DragContext } from "react-draggable-hoc";

import { pixelSizeByZoom } from "@dvsproj/ipat-core/planUtils";
import { hexToRgba } from "@dvsproj/ipat-core/formatter";

import { action } from "mobx";
import useDelayedAction from "../../hooks/useDelayedAction";

const PipeLine = ({
  x,
  y,
  zoomDelta,
  id,
  color,
  lineOptions: { strokeWidth, strokeDasharray },
  hidden,
  path,
  disabled,
  selectedByPipe,
  selectableByPipe,
  addPoint = () => {},
  isSelected,
  setSelectedElement,
  selectPoint,
  deletePoint,
  selectedTool,
  draggable,
  hasError,
  clearMouseLine = () => {},
  cannotAddedAlert = () => {},
  useDefaultStyles = true,
  onRemoveElement,
  onAfter,
  setHoveredElementId = () => {},
  isRoundLine = false,
}) => {
  const defaultRef = React.useRef();
  const dndContext = React.useContext(DragContext);

  const [changeDelayed, delayed] = useDelayedAction();

  const addPointFromEvent = action((e) => {
    const point = addPoint({
      clientX: e ? e.x : undefined,
      clientY: e ? e.y : undefined,
    });
    if (point) {
      action(() => {
        point.isNew = true;
      })();
      setSelectedElement(point.id);
    }

    return point;
  });

  const dragStartListener = async (state) => {
    const { initial } = state;

    changeDelayed(() => {
      if ((isSelected || selectableByPipe) && !disabled) {
        // sync changes with dndContext
        const point = addPointFromEvent(initial);
        if (point) {
          dndContext.observer.dragProps = point.id;
        }
      } else {
        cannotAddedAlert();
        clearMouseLine();
      }
    });
  };

  const { state: dragState } = useDraggable(defaultRef, {
    onDragStart: dragStartListener,
    dragProps: id,
  });

  React.useEffect(() => {
    if (dragState.wasDetached && delayed != null) {
      delayed();
    }
  }, [dragState.wasDetached, delayed]);

  useDroppable(defaultRef, {
    onDrop: async ({ current, dragProps }) => {
      if (delayed != null) {
        changeDelayed();
      }
      const execute =
        dragProps === id && delayed != null
          ? changeDelayed
          : (executeAction) => executeAction();

      if (
        !disabled &&
        current &&
        ((dragProps === "container" && selectableByPipe) ||
          (dragProps === id && selectedTool === "pipeline-add"))
      ) {
        execute(() => {
          let point = addPoint({
            clientX: current ? current.x : undefined,
            clientY: current ? current.y : undefined,
          });
          if (point) {
            const result = selectPoint(point.id);
            if (result && result === "rejected") {
              deletePoint(point.id);
            } else {
              onAfter();
            }
          }
        });
      }
    },
    method: (state, ref) => {
      const node = ref.current;
      if (state.current && node) {
        const hovered = document.elementsFromPoint(
          state.current.x,
          state.current.y
        );
        const points = [].slice.call(document.querySelectorAll(".pipe-point"));
        if (hovered.some((n) => points.some((p) => p.contains(n)))) {
          return false;
        }
        return hovered.some((n) => node.contains(n));
      }

      return false;
    },
  });

  React.useEffect(() => {
    const node = defaultRef && defaultRef.current;
    if (node == null) return;

    const listener = () => {
      onRemoveElement(id, false);
    };

    node.addEventListener("dblclick", listener);

    return () => {
      node.removeEventListener("dblclick", listener);
    };
  });

  const allowActive =
    !draggable &&
    (selectedTool !== "pipeline-add" || selectableByPipe || isSelected);
  const active = isSelected || selectedByPipe;

  return (
    <g
      className={`draggable element pipe-line
      ${!allowActive ? "disabled" : ""} ${active ? "active" : ""} ${
        hidden ? "element-hidden" : ""
      }`}
      transform={`translate(${x}, ${y})`}
      fill="#fff"
      stroke="#3e4346"
      strokeWidth={pixelSizeByZoom(1, zoomDelta)}
    >
      <g
        onClick={(e) => {
          if (!draggable && selectedTool !== "pipeline-add") {
            const { clientX, clientY } = e;
            if (isSelected) {
              changeDelayed(() => {
                addPointFromEvent({ x: clientX, y: clientY });
                onAfter();
              });
            } else {
              setSelectedElement(id);
            }
          }
        }}
        onMouseEnter={() => {
          setHoveredElementId(id);
        }}
        onMouseLeave={() => {
          setHoveredElementId(undefined);
        }}
      >
        {!useDefaultStyles ? (
          <path
            className="pipe-line-bg-elem"
            d={path}
            stroke="#000"
            strokeWidth={pixelSizeByZoom(strokeWidth, zoomDelta)}
            fill="none"
            strokeLinecap={isRoundLine ? "round" : null}
            strokeLinejoin={isRoundLine ? "round" : null}
          />
        ) : null}
        <path
          d={path}
          ref={defaultRef}
          stroke={
            selectedTool !== "pipeline-add" || selectableByPipe
              ? color
              : hexToRgba(color, 0.6)
          }
          strokeWidth={pixelSizeByZoom(strokeWidth - 1, zoomDelta)}
          strokeDasharray={
            hasError || strokeDasharray
              ? `${pixelSizeByZoom(strokeWidth - 1, zoomDelta)}px`
              : ""
          }
          fill="none"
          className={`${
            selectableByPipe || isSelected
              ? !disabled
                ? "addable active"
                : "active"
              : ""
          }`}
          strokeLinecap={isRoundLine ? "round" : null}
          strokeLinejoin={isRoundLine ? "round" : null}
        />
      </g>
    </g>
  );
};

export default observer(
  ({
    element,
    segments,
    addPoint,
    addPipePoint,
    selectPoint,
    onSave,
    getSvgPoint,
    planIsEditable,
    selectedTool,
    selectedOtherTool,
    selectedElementId,
    setSelectedElement = () => {},
    removeElementById = () => {},
    onRemoveElement = () => {},
    ...props
  }) => {
    let path = element.path;
    if (segments && segments.length > 0) {
      path = segments.reduce((acc, curr) => {
        return (
          acc +
          ("M " +
            curr.start.x +
            " " +
            curr.start.y +
            " L " +
            curr.end.x +
            " " +
            curr.end.y +
            " ")
        );
      }, "");
      path += "Z";
    }
    return (
      <PipeLine
        id={element.id}
        x={0}
        y={0}
        path={path}
        disabled={
          element.disabled ||
          !planIsEditable ||
          selectedOtherTool === "draggable"
        }
        selectedTool={selectedTool}
        selectedByPipe={selectedTool === "pipeline-add" && element.selected}
        selectPoint={selectedTool === "pipeline-add" ? selectPoint : () => {}}
        onAfter={onSave}
        setSelectedElement={setSelectedElement}
        isSelected={element.id === selectedElementId}
        selectableByPipe={
          selectedTool === "pipeline-add" &&
          (element.selectableByPipe || element.id === selectedElementId)
        }
        addPoint={
          planIsEditable
            ? (e) => {
                let point = getSvgPoint(e);
                if (point) {
                  return addPipePoint({ x: point.x, y: point.y }, element.id);
                }
              }
            : () => {}
        }
        deletePoint={
          planIsEditable
            ? (id) => {
                removeElementById(id);
              }
            : () => {}
        }
        onRemoveElement={onRemoveElement}
        {...props}
      />
    );
  }
);
