import Link from "../elements/Link";

function ProductLink({ productLink, children, ...props }) {
  return productLink != null ? (
    <Link
      target="_blank"
      rel="noopener noreferrer"
      href={productLink}
      {...props}
    >
      {children}
    </Link>
  ) : (
    <div {...props}>{children}</div>
  );
}

export default ProductLink;
