import { createIntl } from "react-intl";
import { priceFormat } from "@dvsproj/ipat-core/formatter";

const CELL_SEPARATOR = ";";

export type Intl = ReturnType<typeof createIntl>;
export type BaseProduct = {
  quantity: number;
  pricePerUnit: number;
  price: number;
  articleNO: string;
  name: string;
  minInstallationTime: number;
  maxInstallationTime: number;
};

abstract class BaseCsvExport<T extends BaseProduct> {
  _lines: (string | number)[][] = [];
  _intl: Intl;
  _products: T[];

  abstract generate(): void;

  static _priceRounding(price: number) {
    return priceFormat(null, price);
  }

  constructor(intl: Intl, products: T[]) {
    this._intl = intl;
    this._products = products;
  }

  _formatField(field: string | number | undefined) {
    return typeof field === "string"
      ? `"${field.replaceAll('"', '""')}"`
      : `"${field}"`;
  }

  _formatPrice(price: number) {
    return priceFormat(this._intl, price);
  }

  getToBlob() {
    const blob = new Blob(
      [
        "\uFEFF" +
          this._lines.map((line) => line.join(CELL_SEPARATOR)).join("\r\n"),
      ],
      {
        type: "data:text/csv;charset=utf-8",
      }
    );

    return blob;
  }
}

export { BaseCsvExport };
