import React from "react";
import { observer } from "mobx-react";

import { adjustmentPoint } from "@dvsproj/ipat-core/areaUtils";
import {
  sizeInPixelByMeters,
  sizeInMetersByPixel,
  pixelSizeByZoom,
  svgTextCondensed,
} from "@dvsproj/ipat-core/planUtils";
import { toFixedPrecision } from "@dvsproj/ipat-core/formatter";

import { calculateTextsPositions } from "../../utils/uiUtils";

import SprinklerCircle from "./SprinklerCircle";
import SprinklerRect from "./SprinklerRect";
import SpecificSprinklerElement from "./SpecificSprinklerElement";
import DraggableSprinkler from "./DraggableSprinkler";
import DefaultSVGStyles from "./../DefaultSVGStyles";
import DefaultSVGFilters from "../DefaultSVGFilters";
import { useIntl } from "react-intl";
import usePlanSizeValidation from "../../hooks/usePlanSizeValidation";
import useStores from "../../hooks/useStores";

const Sprinklers = ({
  viewbox,
  width,
  height,
  sprinklers,
  rzws,
  raisedBeds,
  selectedElement,
  selectedElementId,
  currentStep,
  setHoveredElementId = () => {},
  setSelectedElement = () => {},
  planIsEditable,
  zoomDelta,
  scale,
  onChange = () => {},
  remove = () => {},
  pipelineHasDrawing,
  abjustPosition = () => {},
  useDefaultStyles = true,
  textsVisibility = {},
  sprinklersDistance,
  grayscale = false,
  coverageIsActive = false,
  ...props
}) => {
  const { formatNumber } = useIntl();

  const onChangeFactory = (sprinkler) => () => onChange(sprinkler);
  let sprinklerList = [];
  let activeSprinklerList = [];
  sprinklers.forEach((element, i) => {
    const fromElementDrawing =
      pipelineHasDrawing &&
      selectedElement &&
      selectedElement.type === "pipeline-point" &&
      selectedElement.x === element.x &&
      selectedElement.y === element.y;

    let current = null;
    const elementProps = {
      element,
      selectedElementId,
      setHoveredElementId,
      setSelectedElement,
      planIsEditable,
      zoomDelta,
      scale,
      onChange: onChangeFactory(element),
      remove,
      fromElementDrawing,
      abjustPosition: () => {
        abjustPosition(element);
      },
      noFillSector: coverageIsActive,
      textsVisibility,
      useDefaultStyles,
    };
    switch (element.sprinklerType) {
      case "circle":
        current = (
          <SprinklerCircle key={element.id} {...elementProps} {...props} />
        );
        break;
      case "rect":
        current = (
          <SprinklerRect key={element.id} {...elementProps} {...props} />
        );
        break;
      default:
        break;
    }
    if (element.id === selectedElementId) {
      activeSprinklerList.push(current);
    } else {
      sprinklerList.push(current);
    }
  });

  [...(rzws ?? []), ...(raisedBeds ?? [])].forEach((element, i) => {
    const current = (
      <SpecificSprinklerElement
        {...props}
        key={element.id}
        element={element}
        selectedElementId={selectedElementId}
        setHoveredElementId={setHoveredElementId}
        setSelectedElement={setSelectedElement}
        planIsEditable={planIsEditable}
        zoomDelta={zoomDelta}
        scale={scale}
        onChange={onChangeFactory(element)}
        remove={remove}
        fromElementDrawing={
          pipelineHasDrawing &&
          selectedElement &&
          selectedElement.type === "pipeline-point" &&
          selectedElement.x === element.x &&
          selectedElement.y === element.y
        }
        abjustPosition={() => {
          abjustPosition(element);
        }}
        textsVisibility={textsVisibility}
        useDefaultStyles={useDefaultStyles}
      />
    );

    if (element.id === selectedElementId) {
      activeSprinklerList.push(current);
    } else {
      sprinklerList.push(current);
    }
  });

  let sprinklersDistanceList;
  if (sprinklersDistance != null) {
    const textPositions = sprinklersDistance.map((p) => {
      return calculateTextsPositions(
        p.distances.map((d) => {
          return {
            x: (d.start.x + d.end.x) / 2,
            y: (d.start.y + d.end.y) / 2,
            prevPoint: d.start,
            nextPoint: d.end,
            isControlPoint: true,
            direction: d.direction != null ? d.direction : 1,
          };
        }),
        p.direction,
        7
      );
    });
    sprinklersDistanceList = sprinklersDistance.map((a, i) => {
      return a.distances.map((e, j) => {
        const textPosition =
          textPositions != null && textPositions[i] != null
            ? textPositions[i][j]
            : undefined;
        const distance = sizeInMetersByPixel(e.distance, scale);

        if (textPosition == null || distance < 1) return null;

        return (
          <g key={`distances-${j}`}>
            <line
              x1={e.start.x}
              y1={e.start.y}
              x2={e.end.x}
              y2={e.end.y}
              stroke="#000"
              strokeWidth="2"
            />
            <g
              key={`distances-text-${j}`}
              style={{
                fontSize: pixelSizeByZoom(18, zoomDelta),
                letterSpacing: pixelSizeByZoom(0.3, zoomDelta),
                fontWeight: "bold",
              }}
              transform={`translate(${
                textPosition.x + pixelSizeByZoom(textPosition.dx, zoomDelta)
              }, ${
                textPosition.y + pixelSizeByZoom(textPosition.dy, zoomDelta)
              })`}
            >
              <text
                x="0"
                y={
                  !useDefaultStyles || textPosition.dy <= 0
                    ? 0
                    : pixelSizeByZoom(15, zoomDelta)
                }
                dominantBaseline={
                  useDefaultStyles || textPosition.dy <= 0 ? "auto" : "hanging"
                }
                textAnchor={
                  Math.round(textPosition.dx) < 0
                    ? "end"
                    : Math.round(textPosition.dx) > 0
                    ? "start"
                    : "middle"
                }
                {...svgTextCondensed}
              >
                {formatNumber(toFixedPrecision(distance, 1))} m
              </text>
            </g>
          </g>
        );
      });
    });
  }

  return (
    <svg
      viewBox={`${viewbox.x} ${viewbox.y} ${viewbox.w} ${viewbox.h}`}
      width={width}
      height={height}
      preserveAspectRatio="xMidYMid meet"
    >
      {useDefaultStyles ? <DefaultSVGStyles /> : null}
      <DefaultSVGFilters />
      <g filter={grayscale ? "url(#grayscale)" : ""}>
        {sprinklersDistanceList}
        {sprinklerList}
        <g>
          {textsVisibility.sprinklerElements &&
            sprinklers.map((element) => {
              const { id } = element;
              if (id === selectedElementId) return null;
              return (
                <g key={id} className="element">
                  <DraggableSprinkler
                    element={element}
                    planIsEditable={planIsEditable}
                    zoomDelta={zoomDelta}
                    scale={scale}
                    shouldHiglight={currentStep < 4}
                    abjustPosition={() => {
                      abjustPosition(element);
                    }}
                    onMouseEnter={() => {
                      setHoveredElementId(id);
                    }}
                    onMouseLeave={() => {
                      setHoveredElementId(undefined);
                    }}
                    onClick={() => {
                      setSelectedElement(id);
                    }}
                    onChange={onChangeFactory(element)}
                    setSelectedElement={setSelectedElement}
                    remove={remove}
                    textsVisibility={textsVisibility}
                    useDefaultStyles={useDefaultStyles}
                  />
                </g>
              );
            })}
        </g>
        {activeSprinklerList}
      </g>
    </svg>
  );
};

let SprinklersWithState = ({ ...props }) => {
  const { uiState } = useStores();
  const planSizeValidation = usePlanSizeValidation();
  return (
    <Sprinklers
      setSelectedElement={
        uiState.selectedTool === "select"
          ? uiState.setSelectedElement
          : () => {}
      }
      setHoveredElementId={
        uiState.selectedTool === "select"
          ? uiState.setHoveredElementId
          : () => {}
      }
      hoveredElementId={uiState.hoveredElementId}
      selectedElementId={uiState.selectedElementId}
      currentStep={uiState.stepIdx}
      planIsEditable={uiState.planIsEditable}
      zoomDelta={uiState.zoomState.zoomDelta}
      scale={uiState.plan ? uiState.plan.scale : null}
      precision={uiState.precision}
      onChange={
        uiState.reactions
          ? (params) => {
              uiState.reactions.onSprinklerChange(params);
              planSizeValidation();
            }
          : () => {}
      }
      elementIsChanged={uiState.elementIsChanged}
      remove={uiState.onRemoveElement}
      selectedElement={uiState.selectedElement}
      pipelineHasDrawing={uiState.plan.pipelineHasDrawing}
      abjustPosition={(element) => {
        const p = adjustmentPoint(
          element,
          uiState.plan.areas,
          sizeInPixelByMeters(
            uiState.settingsState.adjustmentPointThreshold,
            uiState.plan.scale
          )
        );
        if (p?.point && (p.point.x !== element.x || p.point.y !== element.y)) {
          element.drag(p.point.x, p.point.y);
          if (uiState.reactions) uiState.reactions.onSprinklerChange();
        }
      }}
      coverageIsActive={
        uiState.plan != null &&
        ((uiState.plan.precipitationCoverage != null &&
          uiState.stepIdxByName("sprinklers") === uiState.stepIdx) ||
          (uiState.plan.sensorPrecipitationCoverage != null &&
            uiState.stepIdxByName("sensor") === uiState.stepIdx))
      }
      textsVisibility={{
        ...uiState.textsVisibility,
        sprinklersTexts:
          uiState.stepIdx != null &&
          uiState.stepIdxByName("sensor") === uiState.stepIdx
            ? false
            : uiState.textsVisibility?.sprinklersTexts,
        sprinklersWaterpressureText:
          (uiState.stepIdx != null &&
            uiState.stepIdxByName("pipeline") === uiState.stepIdx) ||
          uiState.textsVisibility.sprinklersWaterpressureText,
      }}
      useDefaultStyles={uiState.useDefaultStyles}
      {...props}
    />
  );
};

SprinklersWithState = observer(SprinklersWithState);

export { SprinklersWithState as Sprinklers };
export default SprinklersWithState;
