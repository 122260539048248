import { extendObservable, observable } from "mobx";
import { v4 as uuid } from "uuid";
import { linesByPoints } from "@dvsproj/ipat-core/geometryUtils";

/**
 * Represents a cable trenches, used only for generate BOM
 * @param {*} param0 - json
 * @returns object
 * contains:
 *  id - object identifier
 *  type,
 *  points
 *  distance
 */
const cableFactory = ({ id, type, points, distance, startId, stopId } = {}) => {
  const cable = observable({
    get type() {
      return type;
    },
    id: id || "cable-" + uuid(),
    get startId() {
      return startId;
    },
    get stopId() {
      return stopId;
    },
    get points() {
      return points;
    },
    get distance() {
      return distance;
    },
    get lines() {
      return linesByPoints(points, false, false)
        .map((p) => {
          return p != null && p.start != null && p.end != null
            ? { ...p, startPoint: p.start, endPoint: p.end }
            : null;
        })
        .filter((o) => o != null);
    },
  });

  extendObservable(cable, {
    get toJSON() {
      const { id, type, points, distance, startId, stopId } = this;

      return {
        id,
        type,
        points,
        distance,
        startId,
        stopId,
      };
    },
  });

  return cable;
};

export default cableFactory;
