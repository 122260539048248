import EventEmitter from "eventemitter3";

class WorkerThreadApi extends EventEmitter {
  constructor(filename) {
    super();

    this.worker = new Worker(filename);
    this.worker.addEventListener("message", this._listener.bind(this));
  }

  _listener({ data: params } = {}) {
    const { action, data } = params;
    if (action == null) {
      throw new Error("Invalid params");
    }

    this.emit(action, data);
  }

  send(action, data = {}) {
    try {
      this.worker.postMessage({
        action,
        data,
      });
    } catch (e) {
      console.error("workerThreadApi send failed: ", e);
    }
  }
}

export default WorkerThreadApi;
