import React from "react";

function WindowPopup({
  wrapperClassName = "",
  className = "",
  title,
  footer,
  children,
  background = true,
  close = undefined,
}) {
  return (
    <>
      <div className={`window-popup ${wrapperClassName}`}>
        <div className={`window-popup-container ${className}`}>
          {close != null ? (
            <div className="window-popup-close" onClick={close}>
              <i>&#215;</i>
            </div>
          ) : null}
          {title && <div className="window-popup-header">{title}</div>}
          <div className="window-popup-content">{children}</div>
          {footer && <div className="window-popup-footer">{footer}</div>}
        </div>
      </div>
      {background && <div className="window-blur"></div>}
    </>
  );
}

export default WindowPopup;
