import {
  calculateAngleByPoints,
  lineLength,
  rotatePoint,
  calculateAngleByLine,
  calculatePointsDirection,
} from "@dvsproj/ipat-core/geometryUtils";

import * as Formatter from "@dvsproj/ipat-core/formatter";

/**
 * Calculates points direction for svg coordinates system
 *
 * @param {*} points
 */
export const calculateSvgPointsDirection = (points) => {
  if (points && points.length > 0) {
    let result = calculatePointsDirection(points);
    return result != null && result >= 0 ? -1 : 1;
  }
  return null;
};

/**
 * Calculates angle or length text positions by points
 *
 * @param {*} points
 * @param {*} direction - areaDirection
 * @param {*} offset
 */
export const calculateTextsPositions = (
  points,
  areaDirection = 1,
  offset = 10
) => {
  if (points && points.length > 1) {
    let result = [];
    points.forEach((point) => {
      let dx = 0;
      let dy = 0;

      if (point.direction) {
        let positionX = point.x;
        let positionY = point.y;

        if (point.isControlPoint) {
          const p0 = point.prevPoint?.prevPoint?.prevPoint
            ? point.prevPoint.prevPoint.prevPoint
            : point.prevPoint;
          const p1 = point.prevPoint;
          const p2 = point.nextPoint;

          const direction =
            p0 === p1
              ? areaDirection
              : calculateSvgPointsDirection([p0, p1, p2]);

          if (p1 && p2 && p2.y - p1.y !== 0) {
            dx = offset;
            dx *=
              p2.y > p1.y
                ? direction //to down
                : -direction; //to up
          }

          if (p1 && p2 && p2.x - p1.x !== 0) {
            dy = offset;
            dy *=
              p2.x > p1.x
                ? -direction //to right
                : direction; //to left
          }

          const lineAngle = Math.abs(
            calculateAngleByLine(point.prevPoint, point.nextPoint)
          );
          dx = Math.abs(Math.sin((lineAngle * Math.PI) / 180)) * dx;
          dy = Math.abs(Math.cos((lineAngle * Math.PI) / 180)) * dy;

          dx = direction !== areaDirection ? -dx : dx;
          dy = direction !== areaDirection ? -dy : dy;

          //iT * iT * p1 + 2 * iT * t * p2 + t * t * p3
          positionX =
            0.25 * point.prevPoint.x + 0.5 * point.x + 0.25 * point.nextPoint.x;
          positionY =
            0.25 * point.prevPoint.y + 0.5 * point.y + 0.25 * point.nextPoint.y;
        } else {
          const angleOffset = 30;
          const angle = calculateAngleByPoints(
            point.prevPoint,
            point,
            point.nextPoint
          );
          const p1 = point;
          const p2 = point.nextPoint;

          const length = lineLength(p2, p1);
          const koef = angleOffset / length;
          let p3 = {
            x: p1.x + (p2.x - p1.x) * koef,
            y: p1.y + (p2.y - p1.y) * koef,
          };

          const a =
            point.direction !== areaDirection
              ? (areaDirection * (angle - 360)) / 2
              : (-areaDirection * angle) / 2;
          p3 = rotatePoint(p3, p1, a);

          dx = p3.x - p1.x;
          dy = p3.y - p1.y;
        }
        result.push({ x: positionX, y: positionY, dx, dy });
      } else {
        result.push(undefined);
      }
    });
    return result;
  }
  return null;
};

/**
 * Convert rem to px.
 *
 * @param {*} rem
 */
export const remToPx = (rem) => {
  return rem * parseFloat(16); //TODO  getComputedStyle(document.documentElement).fontSize
};

export const toFixedPrecision = Formatter.toFixedPrecision;

/**
 * Removes js script tag from dangerous html.
 *
 * @param {*} text
 */
export const removeScripts = (text) => {
  return text.replace(
    /<\s*script\b[^<]*(?:(?!<\/script\s*>)<[^<]*)*<\s*\/\s*script\s*>/gi,
    ""
  );
};

export function cookies() {
  return document.cookie.split(";").reduce((res, e) => {
    const item = e.split("=");
    res[item[0].trim()] = item[1];
    return res;
  }, {});
}

export const getImageSize = (imageSrc) => {
  return new Promise((resolve, reject) => {
    if (imageSrc == null) return reject(`imageSrc is empty`);

    const i = new Image();
    i.onload = () => {
      resolve({
        width: i.width,
        height: i.height,
      });
    };
    i.src = imageSrc;
  });
};

export const getExtremePointsFromPoints = (points) => {
  let minX, maxX, minY, maxY;

  points.forEach((e) => {
    if (e.x < minX || minX == null) minX = e.x;
    if (e.x > maxX || maxX == null) maxX = e.x;
    if (e.y < minY || minY == null) minY = e.y;
    if (e.y > maxY || maxY == null) maxY = e.y;
  });

  return { minX, maxX, minY, maxY };
};

export const stopPropagation = (e) => e.stopPropagation();

export const convertBlobToBase64 = (blob) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
};
