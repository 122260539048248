import React from "react";

import { ReactComponent as CheckedSVG } from "./../../assets/galka.svg";

function Checkbox({
  value = false,
  onChange = () => {},
  disabled = false,
  label = null,
  reversed = false,
}) {
  const handleChange = () => {
    if (!disabled) {
      onChange(!value);
    }
  };
  return (
    <label className={`checkbox-wrap ${reversed ? "reversed" : ""}`}>
      <label
        onClick={handleChange}
        className={`checkbox ${value ? "checked" : ""} ${
          disabled ? "disabled" : ""
        }`}
      >
        {value ? <CheckedSVG /> : ""}
      </label>
      {label && <label onClick={handleChange}>{label}</label>}
    </label>
  );
}

export default Checkbox;
