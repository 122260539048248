import temporaryBackgroundImage from "./assets/image.png";

export const temporaryBackground = {
  width: 1480, //1968
  height: 1152, //1326
  src: temporaryBackgroundImage,
};

export const tempPlan = {
  sprinklerSetType: "mp",
  scale: 43.44450759365181,
  opacity: 0.38541666666666674,
  unit: "pixel",
  editable: true,
  elements: [
    {
      id: "area-991ddfda-e3d3-4166-9025-5487443192d7",
      type: "area",
      areaType: "rectangle",
      x: 1120.3762823626057,
      y: 766.0272734688947,
      startAngle: 0,
      width: 195.91475888943003,
      height: 42.72284461763272,
      quantity: "dripline",
      points: [],
      crossability: true,
      description: "",
      enclosed: true,
      disabled: false,
    },
    {
      id: "area-20fa24e5-1a90-4f1f-affb-54c0ea346efe",
      type: "area",
      areaType: "rectangle",
      x: 565.4325649746444,
      y: 16.899167127279515,
      startAngle: 0,
      width: 1128.4150024354608,
      height: 28.03701899659037,
      quantity: "dripline",
      points: [],
      crossability: true,
      description: "",
      enclosed: true,
      disabled: false,
    },
    {
      id: "area-60174250-d490-49ff-97db-ea4938bcdea8",
      type: "area",
      areaType: "polygon",
      startAngle: 0,
      quantity: "should",
      points: [
        {
          id: "area-point-3e0b64dd-d11c-431c-940f-fed4f1050858",
          type: "point",
          x: 910,
          y: 34,
          isDefault: false,
        },
        {
          id: "area-point-2bc01d39-bfa9-486a-a886-293d2f738a63",
          type: "control-point",
          x: 455,
          y: 33.5,
          isDefault: true,
        },
        {
          id: "area-point-7f220317-35fd-4acc-9c42-bada1da98118",
          type: "point",
          x: 0,
          y: 33,
          isDefault: false,
        },
        {
          id: "area-point-865fdac0-be96-403c-992a-785ed0458638",
          type: "control-point",
          x: 1.5,
          y: 601.5,
          isDefault: true,
        },
        {
          id: "area-point-75f152ff-75ff-415d-878d-4244fd2ffcb0",
          type: "point",
          x: 3,
          y: 1170,
          isDefault: false,
        },
        {
          id: "area-point-7d6c904b-c4e1-48a0-b88f-321afaab93e3",
          type: "control-point",
          x: 687.5,
          y: 1171.5,
          isDefault: true,
        },
        {
          id: "area-point-141924bd-6d98-4667-a381-be535003dee7",
          type: "point",
          x: 1372,
          y: 1173,
          isDefault: false,
        },
        {
          id: "area-point-dac12d03-4072-4f78-93e1-6ab7aaeab3be",
          type: "control-point",
          x: 1372,
          y: 1147.5,
          isDefault: true,
        },
        {
          id: "area-point-13ad73b2-df53-45fc-a427-28a41fd66735",
          type: "point",
          x: 1372,
          y: 1122,
          isDefault: false,
        },
        {
          id: "area-point-389bf064-e087-4fe6-9d7a-b481a2e6a36c",
          type: "control-point",
          x: 1490.5,
          y: 1122.5,
          isDefault: true,
        },
        {
          id: "area-point-067ad154-fc08-4ad0-bd83-c6c07cae3e25",
          type: "point",
          x: 1609,
          y: 1123,
          isDefault: false,
        },
        {
          id: "area-point-d2dd62e3-5cec-4708-b32d-f726949010ab",
          type: "control-point",
          x: 1609.5,
          y: 1035.5,
          isDefault: true,
        },
        {
          id: "area-point-339880f9-9b19-4b7c-ade4-a384ed54965c",
          type: "point",
          x: 1610,
          y: 948,
          isDefault: false,
        },
        {
          id: "area-point-9453387e-5d01-419e-93f8-a8dd8774e4ab",
          type: "control-point",
          x: 1631,
          y: 948,
          isDefault: true,
        },
        {
          id: "area-point-5f8d1605-5c54-487a-8bbd-598a356c01c4",
          type: "point",
          x: 1652,
          y: 948,
          isDefault: false,
        },
        {
          id: "area-point-52276dd7-279f-48c8-af7d-40dfb74836cc",
          type: "control-point",
          x: 1652,
          y: 846.5,
          isDefault: true,
        },
        {
          id: "area-point-6fb3d886-1c3f-4eb4-8b98-693bbf93109d",
          type: "point",
          x: 1652,
          y: 745,
          isDefault: false,
        },
        {
          id: "area-point-96b65215-2e8b-4c45-a270-bfc6e8b28c8c",
          type: "control-point",
          x: 1435.5,
          y: 745.5,
          isDefault: true,
        },
        {
          id: "area-point-25c165c2-412e-411f-a8dc-46fd5d8cc450",
          type: "point",
          x: 1219,
          y: 746,
          isDefault: false,
        },
        {
          id: "area-point-3cb3618d-425a-4496-9ce2-bc21a1117a6d",
          type: "control-point",
          x: 1218.5,
          y: 767.5,
          isDefault: true,
        },
        {
          id: "area-point-a2bad8dd-8089-4b50-813e-4431f3de58f8",
          type: "point",
          x: 1218,
          y: 789,
          isDefault: false,
        },
        {
          id: "area-point-a99642cf-f132-4e72-9c58-1d6e0c2e2aa0",
          type: "control-point",
          x: 1120,
          y: 789,
          isDefault: true,
        },
        {
          id: "area-point-0dfdb666-20be-4854-b494-6663f3322bf7",
          type: "point",
          x: 1022,
          y: 789,
          isDefault: false,
        },
        {
          id: "area-point-9955e3d5-2da1-409b-bf1e-32d08be312e4",
          type: "control-point",
          x: 1022,
          y: 767,
          isDefault: true,
        },
        {
          id: "area-point-bb583e0b-9255-4bdf-bfc2-80c725beaedd",
          type: "point",
          x: 1022,
          y: 745,
          isDefault: false,
        },
        {
          id: "area-point-1b4b8af7-33df-4914-98fd-f94e9c102031",
          type: "control-point",
          x: 987.5,
          y: 744.5,
          isDefault: true,
        },
        {
          id: "area-point-3d8ab019-aa2a-4f7a-8a78-6bae3c90bd01",
          type: "point",
          x: 953,
          y: 744,
          isDefault: false,
        },
        {
          id: "area-point-7a2703b0-2e65-4e07-84fc-bec2ddbb2378",
          type: "control-point",
          x: 952.5,
          y: 657.5,
          isDefault: true,
        },
        {
          id: "area-point-e22c4b0c-00ae-4235-a131-7efba6704ebb",
          type: "point",
          x: 952,
          y: 571,
          isDefault: false,
        },
        {
          id: "area-point-6c075ca7-c474-4319-83c4-aa54a917954b",
          type: "control-point",
          x: 931,
          y: 571,
          isDefault: true,
        },
        {
          id: "area-point-fc6c9f02-c27d-4b1a-8894-1914a83fb417",
          type: "point",
          x: 910,
          y: 571,
          isDefault: false,
        },
        {
          id: "area-point-2c6fd2a8-4aeb-4853-916d-386e02f34a41",
          type: "control-point",
          x: 910.5,
          y: 504,
          isDefault: true,
        },
        {
          id: "area-point-95524e1c-0359-47e3-b16e-caf120463e76",
          type: "point",
          x: 911,
          y: 437,
          isDefault: false,
        },
        {
          id: "area-point-28698e23-4b41-4bce-964d-6a8d9e8ae375",
          type: "control-point",
          x: 991,
          y: 437,
          isDefault: true,
        },
        {
          id: "area-point-9c735cdb-74e0-4b6c-8e6d-b43d7b28e643",
          type: "point",
          x: 1071,
          y: 437,
          isDefault: false,
        },
        {
          id: "area-point-24b58963-5b06-4e47-85d0-cda3c9779b13",
          type: "control-point",
          x: 1100.5,
          y: 437,
          isDefault: true,
        },
        {
          id: "area-point-67fb5769-95a3-420d-bf1a-1bb4ed61d7a0",
          type: "point",
          x: 1130,
          y: 437,
          isDefault: false,
        },
        {
          id: "area-point-bbccaf35-4b10-4f87-8ba3-64852c2a17a0",
          type: "control-point",
          x: 1129.5,
          y: 301.5,
          isDefault: true,
        },
        {
          id: "area-point-e22d914c-6bb8-4567-9998-9f4a3b23de73",
          type: "point",
          x: 1129,
          y: 166,
          isDefault: false,
        },
        {
          id: "area-point-c706e069-b3a1-4a2e-a119-69868986f1bc",
          type: "control-point",
          x: 1112.5,
          y: 166,
          isDefault: true,
        },
        {
          id: "area-point-e0d5269c-ec49-4897-a7ce-be733964d25a",
          type: "point",
          x: 1096,
          y: 166,
          isDefault: false,
        },
        {
          id: "area-point-2c0364ef-0f6f-4c73-8757-f3275c7810bc",
          type: "control-point",
          x: 1096.5,
          y: 100,
          isDefault: true,
        },
        {
          id: "area-point-18564d01-303b-48ac-8df6-352d9b439d41",
          type: "point",
          x: 1097,
          y: 34,
          isDefault: false,
        },
        {
          id: "area-point-76e9faf2-cc60-425a-8104-8a63c6bd2ae1",
          type: "control-point",
          x: 1003.5,
          y: 34,
          isDefault: true,
        },
      ],
      crossability: true,
      description: "",
      enclosed: true,
      disabled: false,
    },
    {
      id: "area-389f28d7-edb2-48d8-b12f-afb8d2b17296",
      type: "area",
      areaType: "polygon",
      startAngle: 0,
      quantity: "dripline",
      points: [
        {
          id: "area-point-11bb2ea6-17a5-46eb-91bc-5821a7ae12a1",
          type: "point",
          x: 1652,
          y: 949,
          isDefault: false,
        },
        {
          id: "area-point-f48d80b6-7941-44c4-9152-4ec26e11db86",
          type: "control-point",
          x: 1631.5,
          y: 949,
          isDefault: true,
        },
        {
          id: "area-point-dbd78394-16f3-4430-bc26-d829390c8d62",
          type: "point",
          x: 1611,
          y: 949,
          isDefault: false,
        },
        {
          id: "area-point-082f7441-22d2-4c17-b315-e0ba604ae30a",
          type: "control-point",
          x: 1610.5,
          y: 1036.5,
          isDefault: true,
        },
        {
          id: "area-point-bb80c1db-ad46-4861-b0a0-11dcaa71bac9",
          type: "point",
          x: 1610,
          y: 1124,
          isDefault: false,
        },
        {
          id: "area-point-67663e64-8d68-439f-8eca-3ec34fc292e1",
          type: "control-point",
          x: 1491.5,
          y: 1123.5,
          isDefault: true,
        },
        {
          id: "area-point-587e69d3-4f28-4d49-ad0e-1405c791ae9e",
          type: "point",
          x: 1373,
          y: 1123,
          isDefault: false,
        },
        {
          id: "area-point-38e6f83c-acf0-4520-b579-b4c3a8f9e9ca",
          type: "control-point",
          x: 1373,
          y: 1148,
          isDefault: true,
        },
        {
          id: "area-point-a61887f4-d9aa-4b15-a83e-b1ae53c6bdcd",
          type: "point",
          x: 1373,
          y: 1173,
          isDefault: false,
        },
        {
          id: "area-point-b9125d5a-579c-4297-8bd4-b1237bc51612",
          type: "control-point",
          x: 1512.5,
          y: 1173,
          isDefault: true,
        },
        {
          id: "area-point-a810e98f-b299-47ee-a7db-af776659a4c6",
          type: "point",
          x: 1652,
          y: 1173,
          isDefault: false,
        },
        {
          id: "area-point-77c9e446-743e-432e-b94e-3dbf3ae5ebfd",
          type: "control-point",
          x: 1652,
          y: 1061,
          isDefault: true,
        },
      ],
      crossability: true,
      description: "",
      enclosed: true,
      disabled: false,
    },
    {
      id: "system-element-f1f874b0-157d-4ce5-a84f-afcc4ebc9b5e",
      x: 1500,
      y: 776,
      systemType: "water-supply",
      type: "system-element",
      waterQuality: "drinking",
      recom: {
        waterSeparation: {
          name: "Trinkwasser\nTrennstation",
          description:
            "Planen Sie, das Trinkwassernetz fr die Bewsserungsanlage zu nutzen, so schtzt unsere Trinkwasser-Trennstation Ihre Trinkwasser-Installation gem DIN EN 1717 vor Verunreinigungen.",
          groupKey: "systemElements",
          order: 2,
          value: false,
          bom: [
            {
              type: "expert",
              articleNO: "ZS-TWTSECO",
              name: "Trinkwasser Trennstations-Set fr Bewsserungsanlagen",
              description: null,
            },
          ],
        },
      },
      waterQuantity: "1.8",
      waterFilter: "",
      controllerType: "wifi-smart",
      valveBoxType: "box-type",
      disabled: false,
    },
    {
      id: "system-element-b6a1c40d-abf5-4d58-9afd-e02888674245",
      x: 1448,
      y: 774,
      systemType: "controller",
      type: "system-element",
      waterQuality: "drinking",
      recom: {},
      waterQuantity: 0,
      waterFilter: "",
      controllerType: "standart",
      valveBoxType: "box-type",
      disabled: false,
    },
    {
      id: "system-element-07602149-8c69-4f2a-b2e1-7565dc627e98",
      x: 1403,
      y: 773,
      systemType: "valve-box",
      type: "system-element",
      waterQuality: "drinking",
      recom: {},
      waterQuantity: 0,
      waterFilter: "",
      controllerType: "wifi-smart",
      valveBoxType: "box-type",
      disabled: false,
    },
    {
      id: "area-ff2967ee-5fc9-4ca4-a4ad-ceff822ca97a",
      type: "area",
      areaType: "polygon",
      startAngle: 0,
      quantity: "dripline",
      points: [
        {
          id: "area-point-193e9e6f-df59-4970-acfe-1e99742be211",
          type: "point",
          x: 912,
          y: 438,
          isDefault: false,
        },
        {
          id: "area-point-aefed683-cdef-440f-9af0-6d1513854dce",
          type: "control-point",
          x: 1020.5,
          y: 438,
          isDefault: true,
        },
        {
          id: "area-point-8002378c-0b69-4748-a535-2bc01b37086e",
          type: "point",
          x: 1129,
          y: 438,
          isDefault: false,
        },
        {
          id: "area-point-12977043-c263-45c7-b3b8-1dde50365ac8",
          type: "control-point",
          x: 1129,
          y: 459,
          isDefault: true,
        },
        {
          id: "area-point-2986369e-5737-4048-8a67-dd22c63d8957",
          type: "point",
          x: 1129,
          y: 480,
          isDefault: false,
        },
        {
          id: "area-point-a88dfcd6-2377-438d-86a9-97e0267791a1",
          type: "control-point",
          x: 1040,
          y: 480,
          isDefault: true,
        },
        {
          id: "area-point-9dce1e27-9b58-4cb1-af1a-083d5eb8cce8",
          type: "point",
          x: 951,
          y: 480,
          isDefault: false,
        },
        {
          id: "area-point-11a93fb2-08c3-47b4-96b6-91af1b1d64d0",
          type: "control-point",
          x: 951,
          y: 525,
          isDefault: true,
        },
        {
          id: "area-point-88bdbc69-4ca0-4e0c-895b-a15466c440ae",
          type: "point",
          x: 951,
          y: 570,
          isDefault: false,
        },
        {
          id: "area-point-cf8569a5-e0f7-49ee-886e-c6478ea5defa",
          type: "control-point",
          x: 931,
          y: 570,
          isDefault: true,
        },
        {
          id: "area-point-05a52051-1e19-47bf-9b7b-ffc9ac81aaa4",
          type: "point",
          x: 911,
          y: 570,
          isDefault: false,
        },
        {
          id: "area-point-3b82ae9b-2b4b-4eec-9ad7-f804384f43fd",
          type: "control-point",
          x: 911.5,
          y: 504,
          isDefault: true,
        },
      ],
      crossability: false,
      description: "",
      enclosed: true,
      disabled: false,
    },
    {
      id: "sprinkler-366d4e8c-2ff4-4c00-952b-1d21727e957f",
      name: "MP3500H",
      color: "#DFC9B2",
      type: "sprinkler",
      sprinklerType: "circle",
      x: 1093.3184445612192,
      y: 34,
      startAngle: 180,
      circleRadius: 447,
      circleSectorAngle: 90,
      disabled: false,
      invalid: {
        startAngle: false,
        circleRadius: false,
        circleSectorAngle: false,
        rectHeight: false,
        rectWidth: false,
      },
      prsType: "PRS40",
      waterflow: 289.998,
    },
    {
      id: "sprinkler-8bbe4cf1-351a-41d2-996e-6c9c47e0d601",
      name: "MP3000H",
      color: "#198DC1",
      type: "sprinkler",
      sprinklerType: "circle",
      x: 1123.4776668418285,
      y: 175.20388859695217,
      startAngle: 180,
      circleRadius: 312,
      circleSectorAngle: 90,
      disabled: false,
      invalid: {
        startAngle: false,
        circleRadius: false,
        circleSectorAngle: false,
        rectHeight: false,
        rectWidth: false,
      },
      prsType: "PRS40",
      waterflow: 199.998,
    },
    {
      id: "sprinkler-d6980465-79ef-4115-b264-41e7674483a4",
      name: "MP3000H",
      color: "#198DC1",
      type: "sprinkler",
      sprinklerType: "circle",
      x: 1123.4776668418287,
      y: 428.6368891224383,
      startAngle: 270,
      circleRadius: 273,
      circleSectorAngle: 90,
      disabled: false,
      invalid: {
        startAngle: false,
        circleRadius: false,
        circleSectorAngle: false,
        rectHeight: false,
        rectWidth: false,
      },
      prsType: "PRS30",
      waterflow: 199.998,
    },
    {
      id: "sprinkler-6917495d-a616-4fec-b703-0cfc1b0da600",
      name: "MP800H",
      color: "#f76f2f",
      type: "sprinkler",
      sprinklerType: "circle",
      x: 990,
      y: 436,
      startAngle: 270,
      circleRadius: 80,
      circleSectorAngle: 180,
      disabled: false,
      invalid: {
        startAngle: false,
        circleRadius: false,
        circleSectorAngle: false,
        rectHeight: false,
        rectWidth: false,
      },
      prsType: "PRS30",
      waterflow: 99.99,
    },
    {
      id: "sprinkler-f425ca1f-45fd-4b2b-81df-9a68e8cccd87",
      name: "MP2000TQ",
      color: "#65945D",
      type: "sprinkler",
      sprinklerType: "circle",
      x: 910,
      y: 432.3184445612191,
      startAngle: 180,
      circleRadius: 217,
      circleSectorAngle: 270,
      disabled: false,
      invalid: {
        startAngle: false,
        circleRadius: false,
        circleSectorAngle: false,
        rectHeight: false,
        rectWidth: false,
      },
      prsType: "PRS40",
      waterflow: 299.997,
    },
    {
      id: "sprinkler-2ed2c7ac-2779-4390-a6d0-1c28cc275805",
      name: "MP3500H",
      color: "#DFC9B2",
      type: "sprinkler",
      sprinklerType: "circle",
      x: 942.7961114030478,
      y: 574.6815554387808,
      startAngle: 180,
      circleRadius: 456,
      circleSectorAngle: 90,
      disabled: false,
      invalid: {
        startAngle: false,
        circleRadius: false,
        circleSectorAngle: false,
        rectHeight: false,
        rectWidth: false,
      },
      prsType: "PRS40",
      waterflow: 289.998,
    },
    {
      id: "sprinkler-16af96d2-d74a-454b-bedd-b0fcf6baf0e4",
      name: "MP3000TQ",
      color: "#FCEEBF",
      type: "sprinkler",
      sprinklerType: "circle",
      x: 949.3184445612192,
      y: 747.6815554387808,
      startAngle: 91,
      circleRadius: 395,
      circleSectorAngle: 269,
      disabled: false,
      invalid: {
        startAngle: false,
        circleRadius: false,
        circleSectorAngle: false,
        rectHeight: false,
        rectWidth: false,
      },
      prsType: "PRS40",
      waterflow: 597.7718,
    },
    {
      id: "sprinkler-88fc999f-3621-4bf2-8871-0dd2bb15c66e",
      name: "MP3500H",
      color: "#DFC9B2",
      type: "sprinkler",
      sprinklerType: "circle",
      x: 1025.6815554387808,
      y: 750.5223331581714,
      startAngle: 180,
      circleRadius: 430,
      circleSectorAngle: 91,
      disabled: false,
      invalid: {
        startAngle: false,
        circleRadius: false,
        circleSectorAngle: false,
        rectHeight: false,
        rectWidth: false,
      },
      prsType: "PRS40",
      waterflow: 293.2202,
    },
    {
      id: "sprinkler-677bbd45-0b19-4aa6-9c65-fd37be9440a5",
      name: "MP800H",
      color: "#f76f2f",
      type: "sprinkler",
      sprinklerType: "circle",
      x: 1120,
      y: 789,
      startAngle: 90,
      circleRadius: 98,
      circleSectorAngle: 180,
      disabled: false,
      invalid: {
        startAngle: false,
        circleRadius: false,
        circleSectorAngle: false,
        rectHeight: false,
        rectWidth: false,
      },
      prsType: "PRS30",
      waterflow: 99.99,
    },
    {
      id: "sprinkler-070eb209-9de0-4665-bfa5-83c47113ade5",
      name: "MP3500H",
      color: "#DFC9B2",
      type: "sprinkler",
      sprinklerType: "circle",
      x: 1219,
      y: 746,
      startAngle: 90,
      circleRadius: 430,
      circleSectorAngle: 91,
      disabled: false,
      invalid: {
        startAngle: false,
        circleRadius: false,
        circleSectorAngle: false,
        rectHeight: false,
        rectWidth: false,
      },
      prsType: "PRS40",
      waterflow: 293.2202,
    },
    {
      id: "sprinkler-3dcd8b41-dc3f-41ec-bf8c-7dbfa2d5dd03",
      name: "MP3500H",
      color: "#DFC9B2",
      type: "sprinkler",
      sprinklerType: "circle",
      x: 1652,
      y: 745,
      startAngle: 180,
      circleRadius: 430,
      circleSectorAngle: 90,
      disabled: false,
      invalid: {
        startAngle: false,
        circleRadius: false,
        circleSectorAngle: false,
        rectHeight: false,
        rectWidth: false,
      },
      prsType: "PRS40",
      waterflow: 289.998,
    },
    {
      id: "sprinkler-9481306b-092f-40f4-83a9-5cf4aea9d75b",
      name: "MP2000H",
      color: "#5E5A59",
      type: "sprinkler",
      sprinklerType: "circle",
      x: 1652,
      y: 948,
      startAngle: 270,
      circleRadius: 204,
      circleSectorAngle: 90,
      disabled: false,
      invalid: {
        startAngle: false,
        circleRadius: false,
        circleSectorAngle: false,
        rectHeight: false,
        rectWidth: false,
      },
      prsType: "PRS40",
      waterflow: 99.999,
    },
    {
      id: "sprinkler-5ac2e772-f09f-4d84-86d9-b8db193ba8b6",
      name: "MP800H",
      color: "#f76f2f",
      type: "sprinkler",
      sprinklerType: "circle",
      x: 1609,
      y: 1035,
      startAngle: 180,
      circleRadius: 87,
      circleSectorAngle: 180,
      disabled: false,
      invalid: {
        startAngle: false,
        circleRadius: false,
        circleSectorAngle: false,
        rectHeight: false,
        rectWidth: false,
      },
      prsType: "PRS30",
      waterflow: 99.99,
    },
    {
      id: "sprinkler-cb78ef4a-715f-4d11-bc2c-da2e16891f64",
      name: "MP3000H",
      color: "#198DC1",
      type: "sprinkler",
      sprinklerType: "circle",
      x: 1609,
      y: 1123,
      startAngle: 270,
      circleRadius: 377,
      circleSectorAngle: 90,
      disabled: false,
      invalid: {
        startAngle: false,
        circleRadius: false,
        circleSectorAngle: false,
        rectHeight: false,
        rectWidth: false,
      },
      prsType: "PRS40",
      waterflow: 199.998,
    },
    {
      id: "sprinkler-d167b22d-3a6b-4aee-a537-df97d97b46e6",
      name: "MP800H",
      color: "#f76f2f",
      type: "sprinkler",
      sprinklerType: "circle",
      x: 1490,
      y: 1122,
      startAngle: 270,
      circleRadius: 118,
      circleSectorAngle: 180,
      disabled: false,
      invalid: {
        startAngle: false,
        circleRadius: false,
        circleSectorAngle: false,
        rectHeight: false,
        rectWidth: false,
      },
      prsType: "PRS40",
      waterflow: 99.99,
    },
    {
      id: "sprinkler-c9ee4829-ab55-43aa-bdaa-c5eee4b03ccd",
      name: "MP3500H",
      color: "#DFC9B2",
      type: "sprinkler",
      sprinklerType: "circle",
      x: 1372,
      y: 1173,
      startAngle: 270,
      circleRadius: 421.4117236584225,
      circleSectorAngle: 90,
      disabled: false,
      invalid: {
        startAngle: false,
        circleRadius: false,
        circleSectorAngle: false,
        rectHeight: false,
        rectWidth: false,
      },
      prsType: "PRS40",
      waterflow: 289.998,
    },
    {
      id: "sprinkler-fac185c1-2de4-4437-ad89-0e703035bca0",
      name: "MP3500H",
      color: "#DFC9B2",
      type: "sprinkler",
      sprinklerType: "circle",
      x: 915,
      y: 1172,
      startAngle: 270,
      circleRadius: 425.75617441778775,
      circleSectorAngle: 180,
      disabled: false,
      invalid: {
        startAngle: false,
        circleRadius: false,
        circleSectorAngle: false,
        rectHeight: false,
        rectWidth: false,
      },
      prsType: "PRS40",
      waterflow: 579.996,
    },
    {
      id: "sprinkler-0188a811-438b-4f29-a2e5-b2a1815b7aa4",
      name: "MP3500H",
      color: "#DFC9B2",
      type: "sprinkler",
      sprinklerType: "circle",
      x: 459,
      y: 1170,
      startAngle: 270,
      circleRadius: 430,
      circleSectorAngle: 180,
      disabled: false,
      invalid: {
        startAngle: false,
        circleRadius: false,
        circleSectorAngle: false,
        rectHeight: false,
        rectWidth: false,
      },
      prsType: "PRS40",
      waterflow: 579.996,
    },
    {
      id: "sprinkler-748e56d2-8e87-4a6f-bbbf-8277a5fe16f3",
      name: "MP3500H",
      color: "#DFC9B2",
      type: "sprinkler",
      sprinklerType: "circle",
      x: 3,
      y: 1169,
      startAngle: 0,
      circleRadius: 456,
      circleSectorAngle: 90,
      disabled: false,
      invalid: {
        startAngle: false,
        circleRadius: false,
        circleSectorAngle: false,
        rectHeight: false,
        rectWidth: false,
      },
      prsType: "PRS40",
      waterflow: 289.998,
    },
    {
      id: "sprinkler-fc145be3-aab7-4384-b9ea-e489d3c13f25",
      name: "MP3000H",
      color: "#198DC1",
      type: "sprinkler",
      sprinklerType: "circle",
      x: 2,
      y: 791,
      startAngle: 0,
      circleRadius: 382,
      circleSectorAngle: 180,
      disabled: false,
      invalid: {
        startAngle: false,
        circleRadius: false,
        circleSectorAngle: false,
        rectHeight: false,
        rectWidth: false,
      },
      prsType: "PRS40",
      waterflow: 399.996,
    },
    {
      id: "sprinkler-a074cd7f-b64e-4727-a1ab-6e9f499d1efd",
      name: "MP3000H",
      color: "#198DC1",
      type: "sprinkler",
      sprinklerType: "circle",
      x: 1,
      y: 412,
      startAngle: 0,
      circleRadius: 382,
      circleSectorAngle: 180,
      disabled: false,
      invalid: {
        startAngle: false,
        circleRadius: false,
        circleSectorAngle: false,
        rectHeight: false,
        rectWidth: false,
      },
      prsType: "PRS40",
      waterflow: 399.996,
    },
    {
      id: "sprinkler-8b5eb477-9290-45cd-8f59-f0c72875d11e",
      name: "MP3500H",
      color: "#DFC9B2",
      type: "sprinkler",
      sprinklerType: "circle",
      x: 0,
      y: 33,
      startAngle: 90,
      circleRadius: 456,
      circleSectorAngle: 90,
      disabled: false,
      invalid: {
        startAngle: false,
        circleRadius: false,
        circleSectorAngle: false,
        rectHeight: false,
        rectWidth: false,
      },
      prsType: "PRS40",
      waterflow: 289.998,
    },
    {
      id: "sprinkler-c0e0bccb-2aeb-4846-aafd-583ee9bc6ef9",
      name: "MP3000H",
      color: "#198DC1",
      type: "sprinkler",
      sprinklerType: "circle",
      x: 303,
      y: 33,
      startAngle: 90,
      circleRadius: 304,
      circleSectorAngle: 180,
      disabled: false,
      invalid: {
        startAngle: false,
        circleRadius: false,
        circleSectorAngle: false,
        rectHeight: false,
        rectWidth: false,
      },
      prsType: "PRS40",
      waterflow: 399.996,
    },
    {
      id: "sprinkler-b741f2b0-1ee0-43af-9d82-245326de64b8",
      name: "MP3000H",
      color: "#198DC1",
      type: "sprinkler",
      sprinklerType: "circle",
      x: 606,
      y: 33,
      startAngle: 90,
      circleRadius: 304,
      circleSectorAngle: 180,
      disabled: false,
      invalid: {
        startAngle: false,
        circleRadius: false,
        circleSectorAngle: false,
        rectHeight: false,
        rectWidth: false,
      },
      prsType: "PRS40",
      waterflow: 399.996,
    },
    {
      id: "sprinkler-d0106a83-575b-4e51-b0c6-78bb45aaf21a",
      name: "MP2000H",
      color: "#5E5A59",
      type: "sprinkler",
      sprinklerType: "circle",
      x: 909,
      y: 34,
      startAngle: 90,
      circleRadius: 182,
      circleSectorAngle: 180,
      disabled: false,
      invalid: {
        startAngle: false,
        circleRadius: false,
        circleSectorAngle: false,
        rectHeight: false,
        rectWidth: false,
      },
      prsType: "PRS30",
      waterflow: 199.998,
    },
    {
      id: "sprinkler-bc9da99d-1bed-4d4c-900d-c3b753bbf842",
      name: "MP2000F",
      color: "#CF6067",
      type: "sprinkler",
      sprinklerType: "circle",
      x: 739,
      y: 329,
      startAngle: 270,
      circleRadius: 260,
      circleSectorAngle: 360,
      disabled: false,
      invalid: {
        startAngle: false,
        circleRadius: false,
        circleSectorAngle: false,
        rectHeight: false,
        rectWidth: false,
      },
      prsType: "PRS40",
      waterflow: 399.996,
    },
    {
      id: "sprinkler-db13055c-4c1d-4b68-84d8-0f3d49d3740f",
      name: "MP3000F",
      color: "#D1D0CE",
      type: "sprinkler",
      sprinklerType: "circle",
      x: 466,
      y: 565,
      startAngle: 270,
      circleRadius: 395,
      circleSectorAngle: 360,
      disabled: false,
      invalid: {
        startAngle: false,
        circleRadius: false,
        circleSectorAngle: false,
        rectHeight: false,
        rectWidth: false,
      },
      prsType: "PRS40",
      waterflow: 799.992,
    },
  ],
  bomItems: [],
  status: "system element is added (count: 3)",
  sprinklersModified: true,
  pipesModified: true,
  bomModified: false,
  showWaterSupplyVideo: false,
  background: { width: 1652, height: 1173 },
  version: "0.0.1",
};
