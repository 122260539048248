import { BomItem, Plan } from "../../../config/plan-types";
import { Settings } from "../../../config/settings-types";
import { findFittingByTubeType } from "../common-utils";

function rzwsKits(settings: Settings, plan: Plan) {
  const { bomList, elements } = settings;
  const { rzws } = elements;

  let bomItems: BomItem[] = [];
  if (plan.rzws.length === 0) return bomItems;

  const rzwsByTube = findFittingByTubeType(plan.irrigationTubeType, rzws.types);

  bomItems.push({
    bomId: rzwsByTube.bomId,
    qualityList: bomList[rzwsByTube.bomId],
    quantity: plan.rzws.length,
  });

  return bomItems;
}

export { rzwsKits };
