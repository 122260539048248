import React from "react";

import { observer } from "mobx-react";

import Sprinkler from "./Sprinkler";
import { SprinklerCircleBody } from "./SprinklerCircle";
import { SprinklerRectBody } from "./SprinklerRect";
import Draggable from "../Draggable";
import useStores from "../../hooks/useStores";

const DraggableSprinkler = ({
  element,
  id,
  x = 0,
  y = 0,
  disabled,
  elementIsChanged,
  move,
  clone,
  onChange,
  remove,
  select,
  onClick,
  abjustPosition = () => {},
  ...props
}) => {
  let current = <Sprinkler {...props} />;
  if (elementIsChanged) {
    switch (element?.sprinklerType) {
      case "circle":
        current = (
          <SprinklerCircleBody
            {...props}
            key={`circle-body-${id}`}
            id={`circle-body-${id}`}
            title={element.name}
            startAngle={element.startAngle}
            sectorAngle={element.circleSectorAngle}
            radius={element.circleRadius}
            waterflow={element.waterflow}
            noFillSector={true}
          />
        );
        break;
      case "rect":
        current = (
          <SprinklerRectBody
            {...props}
            key={`rect-body-${id}`}
            id={`rect-body-${id}`}
            title={element.name}
            startAngle={element.startAngle}
            width={element.rectWidth}
            height={element.rectHeight}
            type={element.rectType}
            color={element.color}
            hidden={element.hidden}
            waterflow={element.waterflow}
            noFillSector={true}
          />
        );
        break;
      default:
        break;
    }
  }

  return (
    <Draggable
      id={id}
      x={x}
      y={y}
      move={move}
      movePhase="drag"
      onAfterMove={() => {
        abjustPosition();
        onChange();
      }}
      draggable={!disabled}
      cloneable={!disabled}
      dragProps={`draggableSprinkler-${id}`}
      onClick={select}
      onDoubleClick={remove}
    >
      {current}
    </Draggable>
  );
};

export default observer(
  ({
    element,
    shouldHiglight,
    planIsEditable,
    scale,
    zoomDelta,
    setSelectedElement = () => {},
    remove = () => {},
    ...props
  }) => {
    const { uiState } = useStores();
    return (
      <DraggableSprinkler
        {...props}
        element={element}
        id={element.id}
        x={element.x}
        y={element.y}
        move={(x, y, state) => {
          element.drag(x, y);
        }}
        elementIsChanged={uiState.elementIsChanged}
        color={element.color}
        hasInvalid={element.hasInvalid}
        nozzleType={element.nozzleType}
        inNonCrossedArea={shouldHiglight && element.inNonCrossedArea}
        disabled={element.disabled || !planIsEditable}
        remove={() => {
          remove(element.id, false);
        }}
        select={() => {
          setSelectedElement(element.id);
        }}
        scale={scale}
        zoomDelta={zoomDelta}
      />
    );
  }
);
