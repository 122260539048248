import { observer } from "mobx-react";
import React from "react";

import useStores from "../../hooks/useStores";
import { tourConfigFactory } from "./tour-config";
import { createTourDrawer } from "./tour-drawer";

export const Context = React.createContext({});

const converter = (element, container) => {
  const boundingRect = element.ref.getBoundingClientRect();
  const containerRect = container.getBoundingClientRect();

  if (element.type === "circle") {
    const padding = element.padding ? element.padding[0] : 0;
    return {
      id: element.id,
      type: "circle",
      x: boundingRect.left + element.offset[0],
      y: boundingRect.top - containerRect.top + element.offset[1],
      width: boundingRect.width + padding,
      height: boundingRect.width + padding,
    };
  }

  const [paddingTop, paddingRight, paddingBottom, paddingLeft] =
    element.padding == null ? [0, 0, 0, 0] : element.padding;
  if (
    element.type === "shield" ||
    element.type === "rectangle" ||
    element.type == null
  ) {
    return {
      id: element.id,
      type: element.type,
      x: boundingRect.left - paddingLeft,
      y: boundingRect.top - containerRect.top - paddingTop,
      width: boundingRect.width + paddingRight + paddingLeft,
      height: boundingRect.height + paddingTop + paddingBottom,
      ref: element.ref,
    };
  }
};

export default observer(function TourContextProvider({ children }) {
  const [tourNode, setTourNode] = React.useState();
  const onTourRefChange = React.useCallback((node) => {
    setTourNode(node);
  }, []);

  const { uiState } = useStores();
  const [stepName, setStepName] = React.useState(uiState.step.name);
  const tourConfigs = tourConfigFactory(uiState);
  const [tourConfig, setTourConfig] = React.useState(
    tourConfigs.find((tc) => tc.canBeShown?.())
  );
  const [tourEnabled, setTourEnabled] = React.useState(
    tourConfig ? !localStorage.getItem(`tour-${tourConfig.id}-disabled`) : false
  );
  const [tourCanBeShown, setTourCanBeShown] = React.useState(
    tourConfig ? tourConfig?.canBeShown() : false
  );
  const stepIndex =
    tourConfig == null || tourConfig?.steps[0]?.isRelevant?.() ? 0 : 1;
  const [tourStepIndex, setTourStepIndex] = React.useState(stepIndex);
  const [tourStep, setTourStep] = React.useState(
    tourConfig?.steps[tourStepIndex]
  );
  const postActionCallback = React.useRef();

  const [highlightedElements, setHighlightedElements] = React.useState([]);
  const [clipPath, setClipPath] = React.useState();
  const resizeObserverRefs = React.useRef();

  const computePreviousRelevantStepIndex = React.useCallback(() => {
    if (!tourConfig) return -1;

    let previousAnchorStepIndex = tourStepIndex - 1;
    for (; previousAnchorStepIndex >= 0; previousAnchorStepIndex--) {
      if (tourConfig.steps[previousAnchorStepIndex]?.isRelevant?.()) break;
    }

    return previousAnchorStepIndex;
  }, [tourConfig, tourStepIndex]);
  const computeNextRelevantStepIndex = React.useCallback(
    (startIndex) => {
      const currentIndex = startIndex ?? tourStepIndex;
      let nextAnchorStepIndex = currentIndex + 1;
      for (
        ;
        nextAnchorStepIndex < tourConfig.steps.length;
        nextAnchorStepIndex++
      ) {
        if (tourConfig.steps[nextAnchorStepIndex]?.isRelevant?.()) break;
      }

      return nextAnchorStepIndex;
    },
    [tourConfig, tourStepIndex]
  );

  const previousStepAvailable = React.useMemo(() => {
    const previousAnchorStepIndex = computePreviousRelevantStepIndex();
    return previousAnchorStepIndex >= 0;
  }, [computePreviousRelevantStepIndex]);
  const nextStepAvailable = React.useMemo(() => {
    if (!tourConfig) return false;

    const nextAnchorStepIndex = computeNextRelevantStepIndex();
    return nextAnchorStepIndex < tourConfig.steps.length;
  }, [computeNextRelevantStepIndex, tourConfig]);

  const goToStep = React.useCallback(
    async (stepIndex) => {
      if (!tourConfig?.id) return;

      postActionCallback.current?.();
      postActionCallback.current = tourConfig.steps[stepIndex]?.postAction;

      const stepsCount = tourConfig.steps.length;
      if (stepIndex >= stepsCount) {
        localStorage.setItem(`tour-${tourConfig.id}-disabled`, "true");

        setTourEnabled(false);
        setTourStepIndex(0);
        setTourStep(tourConfig.steps[0]);

        return;
      }

      tourConfig.steps[stepIndex]?.action?.();
      if (resizeObserverRefs.current?.disconnect) {
        resizeObserverRefs.current.disconnect();
        delete resizeObserverRefs.current;
      }
      await forNextStepControlElement(tourConfig.steps[stepIndex]);

      setTourStepIndex(stepIndex);
      setTourStep(tourConfig.steps[stepIndex]);
    },
    [tourConfig?.id, tourConfig?.steps]
  );

  const forNextStepControlElement = async (nextStep) => {
    const elementSelectoFn =
      nextStep.containerSelectorFn ?? nextStep.controllerSelectorFn;
    if (elementSelectoFn == null)
      throw new Error("elementSelectoFn can't be null");

    return new Promise((res) => {
      let counter = 0;
      const interval = setInterval(() => {
        if (counter >= 500) {
          clearInterval(interval);
          console.error(
            "Failed to locate tour element, step id:",
            nextStep?.id
          );
          return null;
        }

        const element = elementSelectoFn();
        if (element !== null) {
          clearInterval(interval);
          res(true);
        }

        counter++;
      }, 10);
    });
  };

  const takeNextStep = React.useCallback(async () => {
    if (!tourConfig.id) return;

    if (tourStepIndex + 1 >= tourConfig.steps.length) {
      localStorage.setItem(`tour-${tourConfig.id}-disabled`, "true");

      setTourEnabled(false);
      await goToStep(0);
      return;
    }

    await goToStep(tourStepIndex + 1);
  }, [goToStep, tourConfig?.id, tourConfig?.steps, tourStepIndex]);

  const clipElements = React.useCallback((tourNode, tourStep) => {
    const getHiglightElement =
      tourStep.containerSelectorFn ?? tourStep.controllerSelectorFn;
    const currentElementToHighlight = getHiglightElement();
    if (currentElementToHighlight == null) return;

    const highlightedElement = converter(
      {
        id: tourStep.id,
        type: tourStep.visualOptions?.clipType ?? "rectangle",
        padding: tourStep.visualOptions?.padding,
        offset: tourStep.visualOptions?.offset ?? [0, 0],
        ref: currentElementToHighlight,
      },
      tourNode
    );
    setHighlightedElements([highlightedElement]);
    setClipPath(
      createTourDrawer().draw(
        [highlightedElement],
        tourNode.clientWidth,
        tourNode.clientHeight
      )
    );

    let index = 0;
    resizeObserverRefs.current = new ResizeObserver((entries) => {
      if (index === 0) {
        index++;
        return;
      }

      const highlightedElement = converter(
        {
          id: tourStep.id,
          type: tourStep.visualOptions?.clipType ?? "rectangle",
          padding: tourStep.visualOptions?.padding,
          offset: tourStep.visualOptions?.offset ?? [0, 0],
          ref: currentElementToHighlight,
        },
        tourNode
      );
      if ((highlightedElement.x >= 0, highlightedElement.y >= 0)) {
        setHighlightedElements([highlightedElement]);
        setClipPath(
          createTourDrawer().draw(
            [highlightedElement],
            tourNode.clientWidth,
            tourNode.clientHeight
          )
        );
      }
    });

    const container = tourStep.containerSelectorFn?.();
    if (container) resizeObserverRefs.current.observe(container);
  }, []);

  React.useEffect(() => {
    setTourCanBeShown(tourConfig?.canBeShown());
  }, [tourConfig]);
  const showTour = React.useCallback(() => {
    if (!tourConfig) return;

    const stepIndex = tourConfig?.steps[0]?.isRelevant?.() ? 0 : 1;
    setTourStepIndex(stepIndex);
    setTourStep(tourConfig?.steps[stepIndex]);
    setTourEnabled(true);
  }, [tourConfig]);
  const hideTour = React.useCallback(() => {
    setTourEnabled(false);
    localStorage.setItem(`tour-${tourConfig.id}-disabled`, "true");
  }, [tourConfig?.id]);
  const resetTour = React.useCallback(async () => {
    if (tourNode == null) return;

    hideTour();
    setClipPath(
      createTourDrawer().draw([], tourNode.clientWidth, tourNode.clientHeight)
    );
    setHighlightedElements([]);
    if (resizeObserverRefs.current) {
      resizeObserverRefs.current.disconnect();
      delete resizeObserverRefs.current;
    }
  }, [hideTour, tourNode]);

  React.useEffect(() => {
    const nextTourConfig = tourConfigs.find((tc) => tc.canBeShown?.());
    if (nextTourConfig == null) return;

    if (tourConfig?.id != null && nextTourConfig.id === tourConfig.id) return;

    setTourConfig(nextTourConfig);
    const stepIndex = nextTourConfig.steps[0].isRelevant?.() ? 0 : 1;
    setTourStepIndex(stepIndex);
    setTourStep((p) => {
      return nextTourConfig.steps[stepIndex];
    });

    if (localStorage.getItem(`tour-${nextTourConfig.id}-disabled`) == null) {
      setTourEnabled(true);
    }
  }, [tourConfig, tourConfig?.id, tourConfigs]);

  React.useEffect(() => {
    if (!tourStep) return;

    const tourCanBeShown = tourConfig.canBeShown();
    if (!tourCanBeShown) return;

    const resizeListener = (e) => {
      if (tourNode == null) return;

      clipElements(tourNode, tourStep);
    };
    window.addEventListener("resize", resizeListener);

    if (tourNode != null) clipElements(tourNode, tourStep);

    return () => window.removeEventListener("resize", resizeListener);
  }, [clipElements, tourConfig, tourNode, tourStep]);

  React.useEffect(() => {
    if (stepName !== uiState.step.name) {
      const canBeShown = tourConfigs.find((tc) => tc.canBeShown?.()) != null;
      setTourCanBeShown(canBeShown);
      setStepName(uiState.step.name);
    }
  }, [stepName, tourConfigs, uiState.step.name]);

  return (
    <Context.Provider
      value={React.useMemo(
        () => ({
          allowedElements: tourConfig?.allowedElements ?? [],
          clipPath,
          computeNextRelevantStepIndex,
          computePreviousRelevantStepIndex,
          goToStep,
          hideTour,
          highlightedElements,
          nextStepAvailable,
          onTourRefChange,
          previousStepAvailable,
          resetTour,
          setTourEnabled,
          showTour,
          takeNextStep,
          tourCanBeShown,
          tourEnabled,
          tourNode,
          tourStep,
        }),
        [
          clipPath,
          computeNextRelevantStepIndex,
          computePreviousRelevantStepIndex,
          goToStep,
          hideTour,
          highlightedElements,
          nextStepAvailable,
          onTourRefChange,
          previousStepAvailable,
          resetTour,
          showTour,
          takeNextStep,
          tourCanBeShown,
          tourConfig?.allowedElements,
          tourEnabled,
          tourNode,
          tourStep,
        ]
      )}
    >
      {children}
    </Context.Provider>
  );
});

export function useTourContext() {
  return React.useContext(Context);
}
