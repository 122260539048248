import { BomItem, Plan } from "../../config/plan-types";
import { Settings } from "../../config/settings-types";

function sensorKits(settings: Settings, plan: Plan) {
  const { bomList } = settings;
  const GATEWAY_BOM = "smart-gateway";
  const SENSOR_BOM = "smart-sensor";

  let bomItems: BomItem[] = [];
  if (plan.sensors.length === 0) return bomItems;

  bomItems.push({
    bomId: SENSOR_BOM,
    qualityList: bomList[SENSOR_BOM],
    quantity: plan.sensors.length,
  });

  bomItems.push({
    bomId: GATEWAY_BOM,
    qualityList: bomList[GATEWAY_BOM],
    quantity: 1,
  });

  return bomItems;
}

export { sensorKits };
