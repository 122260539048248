import Dropdown from "../elements/Dropdown";

function QualityList({ value, options, onChange, disabled }) {
  if (!(options && options.length > 1)) return null;

  return (
    <Dropdown
      current={value}
      data={options.map(({ type }) => ({ label: type, value: type }))}
      onChange={onChange}
      disabled={disabled}
    />
  );
}

export default QualityList;
