import EventEmitter from "eventemitter3";
import * as Utils from "./visibilityUtils";

class VisibilityHandler extends EventEmitter {
  constructor() {
    super();
    this.init();
  }

  init() {
    this.visible = Utils.isVisible();
    Utils.addEventListener(this.handleFocusChange);
  }

  handleFocusChange = () => {
    const value = Utils.isVisible();
    if (this.visible !== value) {
      this.visible = value;
      console.info(`Tab visibility changed to ${this.visible}`);
      this.emit("change", this.visible);
    }
  };

  clear() {
    Utils.removeEventListener(this.handleFocusChange);
  }
}

export default VisibilityHandler;
