import React from "react";

export const Watermark = ({ ...props }) => (
  <g className="watermark" {...props}>
    <g transform="translate(-103,-18)" fill="#3e4346" style={{ opacity: 0.6 }}>
      <path
        className="watermark-cls-1"
        d="M21.978,13.174a3.229,3.229,0,0,0-3.261,3.261,3.4,3.4,0,0,0,1.3,2.609L14.935,32.152V11.283l4.957-4.957a2.047,2.047,0,0,0,1.37.457A2.152,2.152,0,1,0,19.109,4.63,1.821,1.821,0,0,0,19.5,5.8L14.935,10.37V6.522a3.3,3.3,0,0,0,2.8-3.261,3.261,3.261,0,0,0-6.522,0,3.229,3.229,0,0,0,2.739,3.2V29.413l-3.261-18a3.095,3.095,0,1,0-1.043.2l1.826,10.174L3.913,14.8A2.2,2.2,0,0,0,4.239,13.7a2.171,2.171,0,0,0-2.152-2.152A2.2,2.2,0,0,0,0,13.7a2.171,2.171,0,0,0,2.152,2.152,2.279,2.279,0,0,0,1.435-.522l8.087,7.5,2.217,12.261a.488.488,0,0,0,.457.391h.065a.49.49,0,0,0,.457-.326L20.935,19.5a3.046,3.046,0,0,0,1.109.2A3.229,3.229,0,0,0,25.3,16.435,3.285,3.285,0,0,0,21.978,13.174ZM7.5,8.348a2.217,2.217,0,1,1,2.217,2.217A2.2,2.2,0,0,1,7.5,8.348Zm4.7-5.087a2.217,2.217,0,1,1,4.435,0,2.217,2.217,0,0,1-4.435,0Zm9.783,15.391A2.217,2.217,0,1,1,24.2,16.435,2.16,2.16,0,0,1,21.978,18.652Z"
      />
      <g transform="translate(23.087 22.435)">
        <path
          className="watermark-cls-2"
          d="M35.4,47.748V34.9h3.848a7.3,7.3,0,0,1,1.891.2,2.609,2.609,0,0,1,1.174.652,3.647,3.647,0,0,1,.978,1.7,12.284,12.284,0,0,1,.261,3.13,25.342,25.342,0,0,1-.2,3.848,5.764,5.764,0,0,1-.652,1.957,2.535,2.535,0,0,1-1.239,1.109,8.531,8.531,0,0,1-2.739.326H35.4Zm2.348-1.891h1.043a3.877,3.877,0,0,0,1.109-.13,1.014,1.014,0,0,0,.522-.391,3.369,3.369,0,0,0,.457-1.37,25.728,25.728,0,0,0,.13-2.8,13.835,13.835,0,0,0-.2-2.739,3.032,3.032,0,0,0-.587-1.3,1.42,1.42,0,0,0-.522-.326,4.343,4.343,0,0,0-1.043-.065h-.913Z"
          transform="translate(-35.4 -34.574)"
        />
        <path
          className="watermark-cls-2"
          d="M52.37,47.748,49.5,34.9h2.543l1.891,10.239L55.7,34.9h2.543L55.565,47.748Z"
          transform="translate(-40.304 -34.574)"
        />
        <path
          className="watermark-cls-2"
          d="M64.779,43.143h2.413v.848a3.22,3.22,0,0,0,.326,1.63,1.222,1.222,0,0,0,1.043.457,1.594,1.594,0,0,0,1.174-.391,1.534,1.534,0,0,0,.391-1.109,1.572,1.572,0,0,0-.261-.978,4.928,4.928,0,0,0-1.174-1.043l-1.239-.848a7.219,7.219,0,0,1-2.022-1.826,3.772,3.772,0,0,1-.457-1.826,3.245,3.245,0,0,1,.978-2.543,3.789,3.789,0,0,1,2.674-.913,3.7,3.7,0,0,1,2.739.978,3.761,3.761,0,0,1,.978,2.8v.457H70.062v-.326a2.838,2.838,0,0,0-.326-1.5,1.194,1.194,0,0,0-1.109-.457A1.132,1.132,0,0,0,67.388,37.8c0,.522.522,1.109,1.5,1.826h0l1.3.913A7.2,7.2,0,0,1,72.084,42.3a3.949,3.949,0,0,1,.522,2.087,3.449,3.449,0,0,1-.978,2.674,3.924,3.924,0,0,1-2.87.978,3.724,3.724,0,0,1-2.87-.978,4.22,4.22,0,0,1-.978-3.065.931.931,0,0,1-.13-.848Z"
          transform="translate(-45.605 -34.47)"
        />
        <path
          className="watermark-cls-2"
          d="M86.1,47.748V34.9h3.848a3.531,3.531,0,0,1,2.478.783,2.941,2.941,0,0,1,.783,2.348,3.992,3.992,0,0,1-.391,1.957,1.754,1.754,0,0,1-1.174.848,2.056,2.056,0,0,1,1.435.913A3.708,3.708,0,0,1,93.6,43.77a4.341,4.341,0,0,1-.848,3,3.356,3.356,0,0,1-2.674.978Zm2.283-7.63h.783a1.564,1.564,0,0,0,1.239-.391,2.427,2.427,0,0,0,.326-1.435,2.1,2.1,0,0,0-.326-1.3,1.747,1.747,0,0,0-1.3-.326h-.717Zm0,5.8h.978a1.594,1.594,0,0,0,1.3-.457,2.508,2.508,0,0,0,.391-1.565,2.6,2.6,0,0,0-.391-1.63,1.954,1.954,0,0,0-1.565-.457h-.717Z"
          transform="translate(-53.035 -34.574)"
        />
        <path
          className="watermark-cls-2"
          d="M100.3,47.748V34.9h6.587v2.152h-4.174v3h3.913v2.022h-3.913V45.6h4.3v2.152Z"
          transform="translate(-57.974 -34.574)"
        />
        <path
          className="watermark-cls-2"
          d="M113.3,47.748V34.9h4.3a4.117,4.117,0,0,1,2.8.783,3.241,3.241,0,0,1,.848,2.543,3.345,3.345,0,0,1-.457,1.957,1.928,1.928,0,0,1-1.37.848c1.109.326,1.761,1.239,1.761,2.87v.2l.065,1.7a3.159,3.159,0,0,0,.2,1.174,4.864,4.864,0,0,0,.457.783h-2.87a2.432,2.432,0,0,1-.13-.783,6.368,6.368,0,0,1-.065-1.239l-.065-1.37V44.03a2.143,2.143,0,0,0-.457-1.565c-.326-.326-.978-.391-1.957-.391h-.848v5.674Zm2.348-7.435h1.37a1.988,1.988,0,0,0,1.435-.391,2.18,2.18,0,0,0,.391-1.5,1.657,1.657,0,0,0-.457-1.37,2.6,2.6,0,0,0-1.63-.391h-1.174v3.652Z"
          transform="translate(-62.496 -34.574)"
        />
        <path
          className="watermark-cls-2"
          d="M128.4,47.748V34.9h6.587v2.152h-4.239v3h3.913v2.022h-3.913V45.6h4.3v2.152Z"
          transform="translate(-67.748 -34.574)"
        />
        <path
          className="watermark-cls-2"
          d="M147.552,47.574l-.2-1.435a2.99,2.99,0,0,1-.978,1.3,3.52,3.52,0,0,1-1.761.391,3.065,3.065,0,0,1-2.87-1.5,13.476,13.476,0,0,1-.848-5.609,19.544,19.544,0,0,1,.2-3.065,4.606,4.606,0,0,1,.587-1.761,2.672,2.672,0,0,1,1.3-1.109,4.656,4.656,0,0,1,2.022-.391,4.22,4.22,0,0,1,3.065.978,4.22,4.22,0,0,1,.978,3.065v.326h-2.217v-.13a2.705,2.705,0,0,0-.457-1.761,1.555,1.555,0,0,0-1.3-.587,1.437,1.437,0,0,0-1.435.783,13.093,13.093,0,0,0-.391,4.239,11.144,11.144,0,0,0,.391,3.652,1.389,1.389,0,0,0,1.3.913,1.424,1.424,0,0,0,1.37-.652,4.632,4.632,0,0,0,.391-2.217v-.391h-1.63V40.6h3.978v7.043h-1.5Z"
          transform="translate(-72.096 -34.4)"
        />
        <path
          className="watermark-cls-2"
          d="M156.9,47.748V34.9h2.478l3.326,9-.065-9h2.283V47.748h-2.478l-3.391-9,.065,9Z"
          transform="translate(-77.661 -34.574)"
        />
        <path
          className="watermark-cls-2"
          d="M172.5,34.9h2.413v8.674A3.777,3.777,0,0,0,175.3,45.6a1.53,1.53,0,0,0,1.3.522,1.618,1.618,0,0,0,1.3-.522,3.754,3.754,0,0,0,.326-2.022V34.9h2.348v8.87a12.044,12.044,0,0,1-.13,1.891A3,3,0,0,1,180,46.7a3.2,3.2,0,0,1-1.37,1.043,6.253,6.253,0,0,1-2.022.326,4.186,4.186,0,0,1-3.13-.978,4.946,4.946,0,0,1-.913-3.326V34.9Z"
          transform="translate(-83.087 -34.574)"
        />
        <path
          className="watermark-cls-2"
          d="M188.1,47.748V34.9h2.478l3.326,9-.065-9h2.283V47.748h-2.478l-3.391-9,.065,9Z"
          transform="translate(-88.513 -34.574)"
        />
        <path
          className="watermark-cls-2"
          d="M209.952,47.574l-.2-1.435a2.99,2.99,0,0,1-.978,1.3,3.52,3.52,0,0,1-1.761.391,3.065,3.065,0,0,1-2.87-1.5,13.476,13.476,0,0,1-.848-5.609,19.544,19.544,0,0,1,.2-3.065,4.607,4.607,0,0,1,.587-1.761,2.672,2.672,0,0,1,1.3-1.109,4.656,4.656,0,0,1,2.022-.391,4.22,4.22,0,0,1,3.065.978,4.22,4.22,0,0,1,.978,3.065v.326h-2.217v-.13a2.705,2.705,0,0,0-.457-1.761,1.555,1.555,0,0,0-1.3-.587,1.437,1.437,0,0,0-1.435.783,13.092,13.092,0,0,0-.391,4.239,11.144,11.144,0,0,0,.391,3.652,1.389,1.389,0,0,0,1.3.913,1.424,1.424,0,0,0,1.37-.652,4.632,4.632,0,0,0,.391-2.217v-.391h-1.63V40.6h3.978v7.043h-1.5Z"
          transform="translate(-93.8 -34.4)"
        />
        <path
          className="watermark-cls-2"
          d="M218.8,43.143h2.413v.848a3.22,3.22,0,0,0,.326,1.63,1.077,1.077,0,0,0,1.043.457,1.594,1.594,0,0,0,1.174-.391,1.534,1.534,0,0,0,.391-1.109,1.572,1.572,0,0,0-.261-.978,4.927,4.927,0,0,0-1.174-1.043l-1.239-.848a7.219,7.219,0,0,1-2.022-1.826A3.772,3.772,0,0,1,219,38.057a3.245,3.245,0,0,1,.978-2.543,3.789,3.789,0,0,1,2.674-.913,3.7,3.7,0,0,1,2.739.978,3.761,3.761,0,0,1,.978,2.8v.457h-2.283v-.326a2.838,2.838,0,0,0-.326-1.5,1.194,1.194,0,0,0-1.109-.457,1.132,1.132,0,0,0-1.239,1.239c0,.522.522,1.109,1.5,1.826h0l1.3.913A7.2,7.2,0,0,1,226.1,42.3a3.949,3.949,0,0,1,.522,2.087,3.449,3.449,0,0,1-.978,2.674,3.924,3.924,0,0,1-2.87.978,3.724,3.724,0,0,1-2.87-.978,4.22,4.22,0,0,1-.978-3.065,1.662,1.662,0,0,1-.13-.848Z"
          transform="translate(-99.191 -34.47)"
        />
        <path
          className="watermark-cls-1"
          d="M233.6,47.748V34.9h4.174a3.732,3.732,0,0,1,2.739.848,3.8,3.8,0,0,1,.848,2.8,3.913,3.913,0,0,1-.913,2.8,3.488,3.488,0,0,1-2.739.913h-1.761v5.478Zm2.283-7.239h.978a2.143,2.143,0,0,0,1.565-.457,1.972,1.972,0,0,0,.457-1.5,2.3,2.3,0,0,0-.391-1.5,2.266,2.266,0,0,0-1.565-.391h-1.043v3.848Z"
          transform="translate(-104.339 -34.574)"
        />
        <path
          className="watermark-cls-1"
          d="M247.9,47.748V34.9h2.413V45.6h4.3v2.217H247.9Z"
          transform="translate(-109.313 -34.574)"
        />
        <path
          className="watermark-cls-1"
          d="M259.2,47.748,262.07,34.9h3.2L268.2,47.748h-2.609l-.652-3.261H262.2l-.587,3.261Zm3.456-5.152h2.022l-1.043-5.413Z"
          transform="translate(-113.243 -34.574)"
        />
        <path
          className="watermark-cls-1"
          d="M275,47.748V34.9h2.478l3.326,9-.065-9h2.283V47.748h-2.478l-3.391-9,.065,9Z"
          transform="translate(-118.739 -34.574)"
        />
        <path
          className="watermark-cls-1"
          d="M290.6,47.748V34.9h6.587v2.152h-4.239v3h3.913v2.022h-3.913V45.6h4.3v2.152Z"
          transform="translate(-124.165 -34.574)"
        />
        <path
          className="watermark-cls-1"
          d="M303.6,47.748V34.9h4.3a4.117,4.117,0,0,1,2.8.783,3.241,3.241,0,0,1,.848,2.543,3.345,3.345,0,0,1-.457,1.957,1.928,1.928,0,0,1-1.37.848c1.109.326,1.7,1.239,1.761,2.87v.2l.065,1.7a3.159,3.159,0,0,0,.2,1.174,4.862,4.862,0,0,0,.457.783h-2.87a2.433,2.433,0,0,1-.13-.783,6.365,6.365,0,0,1-.065-1.239l-.065-1.37V44.03a2.143,2.143,0,0,0-.457-1.565c-.326-.326-.978-.391-1.957-.391h-.848v5.674Zm2.283-7.435h1.37a1.988,1.988,0,0,0,1.435-.391,2.18,2.18,0,0,0,.391-1.5,1.657,1.657,0,0,0-.456-1.37,2.6,2.6,0,0,0-1.63-.391h-1.174v3.652Z"
          transform="translate(-128.687 -34.574)"
        />
      </g>
    </g>
  </g>
);
