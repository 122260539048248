import React from "react";
import BomType from "./BomType";
import { observer } from "mobx-react";

import BomList from "./BomList";
import useStores from "../../hooks/useStores";
import { availableStatuses } from "../../store/planFactory/injectPlanSerialization";

let BomPage = () => {
  const { uiState } = useStores();
  const { step, calculatingTitle, canFetchPrice, fetchPrices, plan } = uiState;

  const isBomPage = step.name === "recommendations";

  React.useEffect(() => {
    if (isBomPage && canFetchPrice) fetchPrices();
  }, [isBomPage, canFetchPrice, fetchPrices]);

  const userIsACustomer = uiState.user.customer;
  const planStatus = uiState.plan?.status;
  const isCheckStatus =
    [availableStatuses.ToCheck, availableStatuses.DuplicateToCheck].indexOf(
      planStatus
    ) >= 0;
  const isFinalize = planStatus === availableStatuses.Finalize;

  if (!isBomPage) return null;
  if (canFetchPrice === true) return null;
  if (calculatingTitle != null) return null;
  if (userIsACustomer && (isCheckStatus || isFinalize)) return null;

  if (plan.bomForceRecalculation === false) {
    return <BomList />;
  }

  return plan.bomType == null || userIsACustomer ? <BomType /> : <BomList />;
};
BomPage = observer(BomPage);

export default BomPage;
