import * as React from "react";
import { sleep } from "@dvsproj/ipat-core/helpers";

const usePending = (fn, ms) => {
  const [pending, setPending] = React.useState(false);

  const send = React.useCallback(
    async (...params) => {
      if (typeof fn == "function") {
        setPending(true);

        let response = undefined;

        try {
          response = await fn(...params);
          if (ms != null) {
            await sleep(ms);
          }
        } finally {
          setPending(false);
        }

        return response;
      }

      return undefined;
    },
    [fn, ms]
  );

  return [pending, send];
};

export default usePending;
