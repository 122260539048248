import React from "react";
import { useDraggable } from "react-draggable-hoc";
import { observer } from "mobx-react";
import useStores from "../hooks/useStores";

const PlanDraggableContainer = ({
  x,
  y,
  zoomDelta,
  children,
  move,
  draggable = true,
  className,
}) => {
  const disabled = !draggable;
  const ref = React.useRef();
  const { deltaX, deltaY, isDragged } = useDraggable(ref, {
    dragProps: disabled ? 1 : 0,
    disabled,
  });

  const deltas = React.useRef({ deltaX: 0, deltaY: 0 });

  React.useEffect(() => {
    const dx = deltaX - deltas.current.deltaX;
    const dy = deltaY - deltas.current.deltaY;
    if (isDragged && (dx !== 0 || dy !== 0)) {
      move({
        x,
        y,
        deltaX: zoomDelta * dx,
        deltaY: zoomDelta * dy,
      });
    }
    deltas.current = { deltaX, deltaY };
  }, [isDragged, deltaX, deltaY, move, x, y, zoomDelta]);

  return (
    <div
      className={`${className ? className : ""} ${
        !disabled ? "draggable" : ""
      }`}
      ref={ref}
      style={{ zIndex: 100 }}
    >
      {children}
    </div>
  );
};

let PlanDraggableContainerWithState = ({ ...props }) => {
  const { uiState } = useStores();
  return (
    <PlanDraggableContainer
      zoomDelta={
        uiState.zoomState && uiState.zoomState.zoomDelta
          ? uiState.zoomState.zoomDelta
          : 1
      }
      selectedElement={uiState.selectedElement}
      {...props}
    />
  );
};

PlanDraggableContainerWithState = observer(PlanDraggableContainerWithState);
export default PlanDraggableContainerWithState;
