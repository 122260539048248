import React from "react";
import { observer } from "mobx-react";

import { pixelSizeByZoom } from "@dvsproj/ipat-core/planUtils";
import Draggable from "./Draggable";
import usePlanSizeValidation from "../hooks/usePlanSizeValidation";
import { ReactComponent as SensorSVG } from "../assets/sensor-plan-x24.svg";
import { ReactComponent as AlarmSVG } from "../assets/alarm.svg";
import useStores from "../hooks/useStores";

function SensorElement({
  id,
  x,
  y,
  drag,
  disabled = false,
  zoomDelta,
  isSelected,
  setSelectedElement,
  onChange,
  name,
  isOnInvalidArea = true,
}) {
  return (
    <Draggable
      x={x}
      y={y}
      move={drag}
      onAfterMove={onChange}
      draggable={!disabled}
      dragProps={id}
    >
      <g
        onClick={() => {
          setSelectedElement(id);
        }}
        className="element system-element"
        transform={`translate(${-pixelSizeByZoom(
          17,
          zoomDelta
        )},${-pixelSizeByZoom(17, zoomDelta)}) scale(${pixelSizeByZoom(
          1,
          zoomDelta
        )})`}
      >
        {isSelected ? (
          <circle
            stroke={"#000"}
            strokeWidth={2}
            cx={14}
            cy={14}
            r={14}
          ></circle>
        ) : null}
        <SensorSVG />
        {isOnInvalidArea ? (
          <AlarmSVG transform={`translate(14, -7.5)`} />
        ) : null}
        <g transform={`translate(20, 18)`}>
          <text x="1em" y="0" className={`sensor-text`}>
            {name}
          </text>
        </g>
      </g>
    </Draggable>
  );
}

let SensorElementWithState = ({ element, ...props }) => {
  const { uiState } = useStores();
  const planSizeValidation = usePlanSizeValidation();

  return (
    <SensorElement
      type={element.systemType}
      id={element.id}
      x={element.x}
      y={element.y}
      name={element.name}
      drag={element.drag}
      disabled={element.disabled || !uiState.planIsEditable}
      zoomDelta={uiState.zoomState.zoomDelta}
      setSelectedElement={uiState.setSelectedElement}
      isSelected={element.id === uiState.selectedElementId}
      isOnInvalidArea={element.isOnInvalidArea}
      onChange={
        uiState.reactions
          ? (params) => {
              uiState.reactions.onSensorElementChange(params);
              planSizeValidation();
            }
          : () => {}
      }
      {...props}
    />
  );
};

SensorElementWithState = observer(SensorElementWithState);
export default SensorElementWithState;
