import React from "react";
import WindowPopup from "./WindowPopup";

function ErrorPopup({ message, title = "Error", className = "" }) {
  return (
    <WindowPopup className={`error ${className || ""}`} title={title}>
      {message}
    </WindowPopup>
  );
}

export default ErrorPopup;
