import React from "react";

function ToolsBlock({
  children,
  label = null,
  hasHeaderLine = true,
  hasLine = false,
  singleChild,
  type,
}) {
  return (
    <div className="tools-block">
      {hasHeaderLine ? <div className="tools-line"></div> : ""}
      {label ? <label className={type ?? ""}>{label}</label> : ""}
      <div
        className={`tools-block-elements ${
          singleChild ? "single-element" : ""
        }`}
      >
        {children}
      </div>
      {hasLine ? <div className="tools-line"></div> : ""}
    </div>
  );
}

export default ToolsBlock;
