import React from "react";
import { observer } from "mobx-react";

import WindowPopup from "./WindowPopup";
import Button from "../elements/Button";
import { useAutoFocus } from "../../hooks/useAutoFocus";
import TubeType from "./TubeType";
import useStores from "../../hooks/useStores";
import BomRecalculationPopup from "./BomRecalculationPopup";
import SprinklerRecalculationPopup from "./SprinklerRecalculationPopup";
import CheckPlanPopup from "./CheckPlanPopup";

export function WindowDialog({
  title,
  text,
  icon,
  confirm,
  cancel,
  confirmLabel,
  cancelLabel,
}) {
  const ref = React.useRef();
  useAutoFocus(ref);

  return (
    <WindowPopup className="window-dialog" title={title}>
      <form>
        <div className="dialog-text">
          {icon && <div className="dialog-image">{icon}</div>}
          {typeof text === "string" ? (
            <div
              className="dialog-message"
              dangerouslySetInnerHTML={{
                __html: text,
              }}
            ></div>
          ) : (
            <div className="dialog-message">{text}</div>
          )}
        </div>
        <div className="dialog-buttons">
          {cancel != null && (
            <Button title={cancelLabel} onClick={cancel} className="cancel" />
          )}
          {confirm != null && (
            <Button
              title={confirmLabel}
              onClick={confirm}
              className="ok"
              ref={ref}
            />
          )}
        </div>
      </form>
    </WindowPopup>
  );
}

const PopupById = {
  recalc_sprinklers: SprinklerRecalculationPopup,
  tube_type: TubeType,
  recalc_bom: BomRecalculationPopup,
  check_plan: CheckPlanPopup,
};

let WindowDialogWithState = () => {
  const { uiState } = useStores();
  const {
    messageTitle,
    messageDescription,
    messageIcon,
    cancelLabel,
    cancelCallback,
    confirmLabel,
    confirmCallback,
    popupId,
  } = uiState;

  if (cancelCallback == null && confirmCallback == null) return null;

  if (popupId != null) {
    const Component = PopupById[popupId];
    return <Component />;
  }

  return (
    <WindowDialog
      title={messageTitle}
      text={messageDescription}
      icon={messageIcon}
      cancel={cancelCallback}
      confirm={confirmCallback}
      cancelLabel={cancelLabel}
      confirmLabel={confirmLabel}
    />
  );
};

WindowDialogWithState = observer(WindowDialogWithState);
export default WindowDialogWithState;
