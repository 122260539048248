/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";

export const useAutoFocus = (ref = null, ...deps) => {
  React.useEffect(() => {
    if (ref) {
      let node = ref.current;
      if (node) {
        node.focus();
      }
    }
  }, deps);
};
