import React from "react";

function Toggle({ current, onChange, disabled }) {
  return (
    <div
      className={`toggle ${current ? "selected" : ""}`}
      onClick={() => {
        if (!disabled) {
          onChange(!current);
        }
      }}
    >
      <div className="toggle-button"></div>
    </div>
  );
}

export default Toggle;
