import { observer } from "mobx-react";
import { useIntl } from "react-intl";

let PlanNameForm = ({ labels, planName, setPlanName }) => {
  const { formatMessage } = useIntl();

  return (
    <>
      <div style={{ flex: "auto" }} />
      <div className="plan-name">
        <label className="title">
          {labels
            ? formatMessage({ id: labels.uploadPlan.planName })
            : "planName"}
        </label>
        <input
          type="text"
          defaultValue={planName}
          onChange={(e) => {
            setPlanName(e.target.value);
          }}
        />
      </div>
      <div style={{ flex: "auto" }} />
    </>
  );
};

PlanNameForm = observer(PlanNameForm);

export default PlanNameForm;
