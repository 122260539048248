import { BomItem, Plan } from "../../../config/plan-types";
import { Settings } from "../../../config/settings-types";
import { findFittingByTubeType } from "../common-utils";

function raisedBedKits(settings: Settings, plan: Plan) {
  const { bomList, elements } = settings;
  const { raisedBed, raisedBedTube, driplineConnectionPoints } = elements;

  let bomItems: BomItem[] = [];
  if (plan.raisedBeds.length === 0) return bomItems;

  const quantity = plan.raisedBeds.length;

  bomItems.push({
    bomId: raisedBed.bomId,
    qualityList: bomList[raisedBed.bomId],
    quantity,
  });

  bomItems.push({
    bomId: raisedBedTube.bomId,
    qualityList: bomList[raisedBedTube.bomId],
    quantity,
  });

  const connectionPoint = findFittingByTubeType(
    plan.irrigationTubeType,
    driplineConnectionPoints
  );
  bomItems.push({
    bomId: connectionPoint.bomId,
    qualityList: bomList[connectionPoint.bomId],
    quantity,
  });

  return bomItems;
}

export { raisedBedKits };
