import React from "react";
import { sleep } from "@dvsproj/ipat-core/helpers";

import ButtonWithRef from "../elements/Button";
import { redirectTo } from "../elements/Link";

const FinishPlanningDialogHeader = React.forwardRef(
  ({ formatMessage, showPopup, planToCheck, sprinklerStep }, ref) => {
    const checkPlanTextId = sprinklerStep
      ? "texts.steps.sprinklers.finishPlanning.text"
      : "texts.steps.pipeline.finishPlanning.text";
    return (
      <div className="finish-planning-header">
        <div className="dialog-text">
          <div className="dialog-message">
            {formatMessage({ id: checkPlanTextId })}
          </div>
        </div>
        <div className="dialog-buttons">
          <ButtonWithRef
            title={formatMessage({
              id: "texts.steps.sprinklers.finishPlanning.button",
            })}
            onClick={async () => {
              const result = await showPopup({ id: "check_plan" });
              if (result) {
                try {
                  const url = await planToCheck();
                  await sleep(0);

                  if (url) redirectTo(url);
                } catch (e) {
                  console.error(e);
                }
              }
            }}
            ref={ref}
          />
        </div>
      </div>
    );
  }
);
export { FinishPlanningDialogHeader };
