import { extendObservable, action } from "mobx";

/**
 * Injects visibility and grayscale values for generate image
 */
/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default (state) => {
  // inject pdf config
  extendObservable(state, {
    useDefaultStyles: false,
    textsVisibility: {
      areaBorder: true,
      areaTexts: true,
      areaWatermark: true,
      areaSize: false,
      sprinklerElements: true,
      sprinklersTexts: true,
      sprinklersPrsInfo: true,
      sprinklersInfo: true,
      sprinklersIrrigation: true,
      sprinklersWaterflowText: true,
      sprinklersWaterpressureText: false,
    },
    grayscale: {
      background: false,
      overlay: false,
      grid: false,
      area: false,
      trenches: false,
      sprinkler: false,
      magnificationZones: false,
      systemElement: false,
      tubing: false,
      pipeline: false,
    },
  });

  extendObservable(state, {
    setGrayscale: action((key, value) => {
      state.grayscale[key] = value;
    }),
    setTextsVisibility: action((key, value) => {
      state.textsVisibility[key] = value;
    }),
    clearTextsVisibility: action(() => {
      Object.keys(state.textsVisibility).forEach((key) => {
        state.textsVisibility[key] = false;
      });
      state.textsVisibility.areaBorder = true;
    }),
  });
};
