import React from "react";
import { observer } from "mobx-react";

import { useIntl } from "react-intl";
import ErrorPopup from "./ErrorPopup";
import StepItem from "./StepItem";
import StepControl from "./StepControl";
import StepLabel from "./StepTitle";
import StepItemIcon from "./StepItemIcon";
import { availableStatuses } from "../../../store/planFactory/injectPlanSerialization";
import useStores from "../../../hooks/useStores";

const useAsyncError = () => {
  const [error, setError] = React.useState();
  if (error) {
    console.error(error);
  }
  return React.useCallback(
    (e) => {
      setError(() => {
        throw e;
      });
    },
    [setError]
  );
};

function Steps({
  sprinklerSetType,
  stepOrder,
  nextStep,
  prevStep,
  maxPossibleStep,
  toStep,
  step,
  stepIdx,
  stepValidation,
  hasCalculation,
  status,
  labels,
  selectedElement,
}) {
  const { formatMessage } = useIntl();

  const { title: errorTitle, errors, warnings } = stepValidation;

  const hasErrors = errors != null;

  const [errorPopup, setErrorPopup] = React.useState(false);

  const isCheckStatus =
    [availableStatuses.ToCheck, availableStatuses.DuplicateToCheck].indexOf(
      status
    ) >= 0;

  React.useEffect(() => {
    setErrorPopup(warnings != null);
  }, [warnings, selectedElement]);

  return (
    <div className={`steps ${isCheckStatus ? "to-check" : ""}`}>
      <StepLabel
        stepIdx={stepIdx}
        stepTitle={step.title}
        isCheckStatus={isCheckStatus}
        labels={labels}
      />
      <div className={`steps-container step-${stepIdx}`}>
        <div className="steps-wrap">
          <StepControl
            key={`prev-${stepIdx}`}
            title={formatMessage({ id: labels.previous })}
            disabled={prevStep == null || hasCalculation}
            onClick={() => {
              if (prevStep) {
                prevStep();
              }
              setErrorPopup(false);
            }}
          />

          {stepOrder.map((stepItem, idx) => {
            if (stepItem.name === "upload") return null;

            return (
              <StepItem
                key={idx}
                icon={
                  <StepItemIcon
                    stepName={stepItem.name}
                    isCheckStatus={isCheckStatus}
                    sprinklerSetType={sprinklerSetType}
                  />
                }
                tooltip={formatMessage({ id: stepItem.tooltip })}
                hasActive={idx <= stepIdx}
                hasLine={idx < stepOrder.length - 1}
                onClick={() => {
                  if (idx > stepIdx && hasErrors) {
                    setErrorPopup(true);
                  } else {
                    toStep(idx > maxPossibleStep ? maxPossibleStep : idx);
                    setErrorPopup(false);
                  }
                }}
                onLineClick={() => {
                  if (idx === stepIdx && hasErrors) {
                    setErrorPopup(true);
                  }
                }}
                disabled={idx > maxPossibleStep || hasCalculation}
                nextDisabled={stepIdx === idx && hasErrors}
                hasError={stepIdx === idx && hasErrors}
              />
            );
          })}

          <StepControl
            key={`next-${stepIdx}`}
            title={formatMessage({ id: labels.next })}
            disabled={nextStep == null || hasCalculation}
            onClick={() => {
              if (!hasErrors) {
                if (nextStep != null) {
                  nextStep();
                }
                setErrorPopup(false);
              } else {
                setErrorPopup(true);
              }
            }}
          />
        </div>
        <ErrorPopup
          labels={labels}
          title={errorTitle}
          errors={errors}
          warnings={warnings}
          hasActive={errorPopup}
          onClose={() => setErrorPopup(false)}
        />
      </div>
    </div>
  );
}

let StepsWithState = () => {
  const { uiState } = useStores();
  const throwError = useAsyncError();
  const catchFn = (e) => {
    console.error(e);
    return throwError(new Error(e));
  };

  return (
    <Steps
      prevStep={
        uiState.prevStep != null
          ? async () => {
              await uiState.prevStep().catch(catchFn);
              uiState.savePlanPosition();
            }
          : null
      }
      nextStep={
        uiState.nextStep != null
          ? async () => {
              await uiState.nextStep().catch(catchFn);
              uiState.savePlanPosition();
            }
          : null
      }
      stepOrder={uiState.stepOrder}
      toStep={async (idx) => {
        await uiState.toStep(idx).catch(catchFn);
        uiState.savePlanPosition();
      }}
      step={uiState.step}
      stepValidation={
        uiState.step.validateStep != null ? uiState.step.validateStep() : {}
      }
      stepIdx={uiState.stepIdx}
      maxPossibleStep={uiState.maxPossibleStep}
      hasCalculation={uiState.calculatingTitle != null}
      status={uiState.plan ? uiState.plan.status : null}
      labels={uiState.settingsState ? uiState.settingsState.texts.steps : {}}
      sprinklerSetType={uiState.plan?.sprinklerSetType}
      selectedElement={uiState.selectedElement}
    />
  );
};

StepsWithState = observer(StepsWithState);
export default StepsWithState;
