let smartlookInited = false;

function initSmartLook() {
  const smartlookId = window.APP_CONFIG?.REACT_APP_SMARTLOOK_ID;

  if (process.env.NODE_ENV !== "production") return;
  if (smartlookId == null) return;
  if (smartlookInited) return;

  smartlookInited = true;
  window.smartlook ||
    (function (d) {
      var o = (window.smartlook = function () {
          o.api.push(arguments);
        }),
        h = d.getElementsByTagName("head")[0];
      var c = d.createElement("script");
      // eslint-disable-next-line no-array-constructor
      o.api = new Array();
      c.async = true;
      c.type = "text/javascript";
      c.charset = "utf-8";
      c.src = "https://rec.smartlook.com/recorder.js";
      h.appendChild(c);
    })(document);
  window.smartlook("init", smartlookId);
}

export default initSmartLook;
