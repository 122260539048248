import React from "react";
import { observer } from "mobx-react";

import usePolling from "../../hooks/usePolling";

import WindowPopup from "./WindowPopup";

import { ReactComponent as AuthSVG } from "../../assets/auth.svg";
import { urlDecorator } from "../../utils/api";
import { FormattedMessage, useIntl } from "react-intl";
import Link from "../elements/Link";
import useStores from "../../hooks/useStores";

const LoginPopup = ({ labels, loginURL }) => {
  const { formatMessage } = useIntl();

  return (
    <WindowPopup title={formatMessage({ id: labels.title })} className="login">
      <div className="login-container">
        <div>
          <AuthSVG />
        </div>
        <div className="login-text">
          <FormattedMessage
            id={labels.text}
            values={{
              link: (
                <Link href={loginURL} target="_blank" rel="noopener noreferrer">
                  {formatMessage({
                    id: "texts.steps.upload.popup.auth.text.link.title",
                  })}
                </Link>
              ),
            }}
          />
        </div>
      </div>
    </WindowPopup>
  );
};

let LoginPopupWithState = ({ ...props }) => {
  const { uiState } = useStores();
  const user = uiState.user;

  const fetchingRef = React.useRef();

  const userInfoPolling = usePolling(user?.fetch, 0.5 * 60 * 1000);
  React.useEffect(() => {
    if (user?.auth) {
      fetchingRef.current = setTimeout(userInfoPolling.start, 0);
      return () => {
        if (fetchingRef.current) clearTimeout(fetchingRef.current);
        userInfoPolling.stop();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.auth]);

  return user.fetched && !user?.auth ? (
    <LoginPopup
      {...props}
      labels={uiState.settingsState.texts.steps.upload.popup.auth}
      loginURL={
        uiState.user?.loginPageUrl
          ? urlDecorator(uiState.user.loginPageUrl)
          : urlDecorator(uiState.settingsState.links.loginURL)
      }
    />
  ) : null;
};

LoginPopupWithState = observer(LoginPopupWithState);
export default LoginPopupWithState;
