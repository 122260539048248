import { extendObservable, action } from "mobx";
import { throttle } from "lodash";
import {
  getSizeByZoom,
  pixelSizeByZoom,
  getZoomByScale,
} from "@dvsproj/ipat-core/planUtils";
import { lineLength } from "@dvsproj/ipat-core/geometryUtils";
import { getExtremePointsFromPoints } from "../utils/uiUtils";

export const zoomStateFactory = ({ uiState }) => {
  const zoomScale = 100;
  const state = {};

  extendObservable(state, {
    zoom: 0,
    stepDelta: 1,
    defaultZoomDelta: 1,
    get maxZoom() {
      return getZoomByScale(9, state.zoomStep, uiState.containerWidth);
    },
    get minZoom() {
      return getZoomByScale(1 / 5, state.zoomStep, uiState.containerWidth);
    },
    setDefaultZoomDelta: action((val) => {
      state.defaultZoomDelta = val;
    }),
    validateZoom: action((zoom) => {
      if (zoom < state.maxZoom) {
        return state.maxZoom;
      }
      if (zoom > state.minZoom) {
        return state.minZoom;
      }
      return zoom;
    }),
    setQuickZoom: action((has) => {
      state.stepDelta = has ? 3 : 1;
    }),
    setZoom: throttle(
      action((zoom) => {
        state.zoom = zoom;
      }),
      200
    ),
    centerZoom: action((zoom, center) => {
      const rightZoom = state.validateZoom(zoom);

      let k =
        -(rightZoom - state.zoom) * (state.zoomStep / uiState.containerWidth);

      state.zoom = rightZoom;
      uiState.setMove(
        uiState.offsets.x + center.x * k,
        uiState.offsets.y + center.y * k
      );
    }),
    panTo: action((points, padding = 0) => {
      if (points.length === 0) return undefined;

      const { minX, maxX, minY, maxY } = getExtremePointsFromPoints(points);

      const zoomX = getZoomByScale(
        (uiState.containerWidth - padding * 2) /
          lineLength({ x: maxX, y: 0 }, { x: minX, y: 0 }),
        state.zoomStep,
        uiState.containerWidth
      );

      const zoomY = getZoomByScale(
        (uiState.containerHeight - padding * 2) /
          lineLength({ x: 0, y: maxY }, { x: 0, y: minY }),
        state.zoomStep,
        uiState.containerWidth
      );

      const zoom = state.validateZoom(Math.max(zoomX, zoomY));

      state.setZoom(zoom);

      uiState.setMove(
        minX -
          Math.abs(
            (pixelSizeByZoom(
              uiState.containerWidth,
              uiState.zoomState.zoomDelta
            ) -
              lineLength({ x: maxX, y: 0 }, { x: minX, y: 0 })) /
              2
          ),
        minY -
          Math.abs(
            (pixelSizeByZoom(
              uiState.containerHeight,
              uiState.zoomState.zoomDelta
            ) -
              lineLength({ x: 0, y: maxY }, { x: 0, y: minY })) /
              2
          )
      );
    }),
    get sizeByZoom() {
      return getSizeByZoom(
        uiState.containerWidth,
        uiState.containerHeight,
        state.zoom,
        state.zoomStep
      );
    },
    get zoomStep() {
      return uiState.plan && uiState.plan.width
        ? uiState.plan.width / zoomScale
        : uiState.containerWidth / zoomScale;
    },
    get zoomDelta() {
      return uiState.containerWidth && uiState.containerWidth > 0
        ? (uiState.containerWidth + state.zoom * state.zoomStep) /
            uiState.containerWidth
        : state.defaultZoomDelta;
    },
  });

  return state;
};

export default zoomStateFactory;
