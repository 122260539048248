import { useEffect } from "react";

const useKeydownObserver = (targetKey, callback = () => {}, params = {}) => {
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event?.key === targetKey) {
        callback(params, event);
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [targetKey, callback, params]);

  return null;
};

export default useKeydownObserver;
