import React from "react";
import { observer } from "mobx-react";

import { WindowDialog } from "./WindowDialog";
import { ReactComponent as SaveSVG } from "../../assets/save-big-icon.svg";
import { useIntl } from "react-intl";
import useStores from "../../hooks/useStores";

const SavePopup = ({ title, text, confirm, confirmLabel }) => {
  return (
    <WindowDialog
      title={title}
      icon={<SaveSVG />}
      text={text}
      confirm={confirm}
      confirmLabel={confirmLabel}
    />
  );
};

let SavePopupWithState = () => {
  const { uiState } = useStores();
  const { formatMessage } = useIntl();

  const { hasSavePopup, changeHasSavePopup, settingsState } = uiState;
  if (hasSavePopup !== true || settingsState == null) return null;
  const labels = settingsState.dialog.save;

  return (
    <SavePopup
      title={formatMessage({ id: labels.title })}
      text={formatMessage({ id: labels.text })}
      confirm={changeHasSavePopup}
      confirmLabel={formatMessage({ id: settingsState.dialog.ok })}
    />
  );
};

SavePopupWithState = observer(SavePopupWithState);
export default SavePopupWithState;
