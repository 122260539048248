import ToolButton from "../elements/ToolButton";

function BomNavBar({ value, onClick, options }) {
  return (
    <ul className="other-container">
      {options.map((o, i) => (
        <li key={i} className={o.value === value ? "active" : ""}>
          <ToolButton
            onClick={(e) => {
              onClick(o.value);
            }}
          >
            {o.title}
          </ToolButton>
        </li>
      ))}
    </ul>
  );
}

export default BomNavBar;
