import React from "react";
import VisibilityHandler from "../utils/browserVisibility/visibilityHandler";

const browserVisibilityHandler = new VisibilityHandler();

function usePolling(callback, interval) {
  const timerRef = React.useRef();
  const visibilityRef = React.useRef(true);

  const start = React.useCallback(() => {
    if (typeof callback !== "function") return;

    timerRef.current = setTimeout(async function poll() {
      // check visibility before poll
      if (!visibilityRef.current || !navigator.onLine) return;

      try {
        await callback();
      } catch (e) {
        console.error("Polling error", e);
      }

      timerRef.current = setTimeout(poll, interval);
    });
  }, [callback, interval]);

  const stop = React.useCallback(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
  }, []);

  React.useEffect(() => {
    const listener = (visibility) => {
      visibilityRef.current = visibility;
    };

    browserVisibilityHandler.on("change", listener);

    return () => {
      browserVisibilityHandler.off("change", listener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { start, stop };
}

export default usePolling;
