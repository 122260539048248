export type DrawerElement = {
  id: string;
  x: number;
  y: number;
  width: number;
  height: number;
  type: string;
};
type Point = { x: number; y: number };

class TourDrawer {
  private _elements: DrawerElement[] = [];

  _drawCircleElement(startPoint: Point, elementPath: DrawerElement) {
    const r = elementPath.width / 2;
    return `M${startPoint.x + r} ${startPoint.y} a${r} ${r} 0 1 0 0.0001 0 Z`;
  }

  _drawRectangleElement(startPoint: Point, elementPath: DrawerElement) {
    const borderRadius = [12, 12, 12, 12];
    const [topLeft, topRight, bottomLeft, bottomRight] = borderRadius;

    return `M${startPoint.x},${startPoint.y + topLeft} v${
      elementPath.height - bottomLeft - topLeft
    } a${bottomLeft},${bottomLeft} 0 0 0 ${bottomLeft},${bottomLeft} h${
      elementPath.width - bottomLeft - bottomRight
    } a${bottomRight},${bottomRight} 0 0 0 ${bottomRight},-${bottomRight} v-${
      elementPath.height - bottomRight - topRight
    } a${topRight},${topRight} 0 0 0 -${topRight},-${topRight} h-${
      elementPath.width - topLeft - topRight
    } a${topLeft},${topLeft} 0 0 0 -${topLeft},${topLeft} Z`;
  }

  _drawShieldElement(startPoint, elementPath: DrawerElement) {
    const borderRadius = [13, 13, 84, 84];
    const [topLeft, topRight, bottomLeft, bottomRight] = borderRadius;
    const verticalOffset = elementPath.height - bottomLeft - topLeft;
    return `M${startPoint.x},${startPoint.y + topLeft} v${
      verticalOffset > 0 ? verticalOffset : 0
    } a${bottomLeft},${bottomLeft} 0 0 0 ${bottomLeft},${bottomLeft} h${
      elementPath.width - bottomLeft - bottomRight
    } a${bottomRight},${bottomRight} 0 0 0 ${bottomRight},-${bottomRight} v${
      verticalOffset > 0 ? -verticalOffset : 0
    } a${topRight},${topRight} 0 0 0 -${topRight},-${topRight} h-${
      elementPath.width - topLeft - topRight
    } a${topLeft},${topLeft} 0 0 0 -${topLeft},${topLeft} Z`;
  }

  draw(elements: DrawerElement[], width: number, height: number) {
    this._elements = [...elements];
    const result = [`M 0 0 h ${width} v ${height} h -${width} v -${height} Z`];
    while (this._elements.length > 0) {
      const elementPath = this._elements.shift()!;
      const startPoint = { x: elementPath.x, y: elementPath.y };

      let element;
      switch (elementPath.type) {
        case "circle":
          element = this._drawCircleElement(startPoint, elementPath);
          break;
        case "shield":
          element = this._drawShieldElement(startPoint, elementPath);
          break;
        case "rectangle":
        default:
          element = this._drawRectangleElement(startPoint, elementPath);
          break;
      }

      result.push(element);
    }

    return result.join(" ");
  }
}

export function createTourDrawer() {
  return new TourDrawer();
}
