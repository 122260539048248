import useStores from "../../hooks/useStores";
import { observer } from "mobx-react";
import React from "react";
import { useIntl } from "react-intl";

import { useAutoFocus } from "../../hooks/useAutoFocus";
import ButtonWithRef from "../elements/Button";
import WindowPopup from "./WindowPopup";

let BomRecalculationPopup = () => {
  const { formatMessage } = useIntl();
  const { uiState } = useStores();
  const { confirmCallback } = uiState;

  const ref = React.useRef();
  useAutoFocus(ref);

  return (
    <WindowPopup
      className="window-dialog bom-recalc-type"
      title={formatMessage({ id: "texts.steps.bom.recalculationPopup.title" })}
    >
      <form>
        <div className="dialog-text">
          <div className="dialog-message">
            {formatMessage({
              id: "texts.steps.bom.recalculationPopup.question",
            })}
          </div>
        </div>

        <div className="dialog-buttons">
          <ButtonWithRef
            title={formatMessage({
              id: "texts.steps.bom.recalculationPopup.no",
            })}
            onClick={() => confirmCallback(false)}
            className="cancel"
          />
          <ButtonWithRef
            title={formatMessage({
              id: "texts.steps.bom.recalculationPopup.yes",
            })}
            onClick={() => confirmCallback(true)}
            className="ok"
            ref={ref}
          />
        </div>
      </form>
    </WindowPopup>
  );
};
BomRecalculationPopup = observer(BomRecalculationPopup);

export default BomRecalculationPopup;
