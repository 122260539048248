import React from "react";
import { observer } from "mobx-react";

import { useDraggable } from "react-draggable-hoc";

import { pixelSizeByZoom } from "@dvsproj/ipat-core/planUtils";
import useStores from "../../hooks/useStores";
import Moveable from "../Moveable";

const Point = React.forwardRef(
  (
    { controlActive, dblClick, onAfter, zoomDelta, changeActive },
    controlRef
  ) => (
    <rect
      className={`area-control-item ${controlActive ? "active" : ""}`}
      ref={controlRef}
      onDoubleClick={(e) => {
        e.stopPropagation();
        dblClick();
        onAfter();
      }}
      x={-pixelSizeByZoom(8, zoomDelta)}
      y={-pixelSizeByZoom(8, zoomDelta)}
      width={pixelSizeByZoom(16, zoomDelta)}
      height={pixelSizeByZoom(16, zoomDelta)}
      onMouseUp={(e) => changeActive(false)}
      onTouchEnd={(e) => changeActive(false)}
    ></rect>
  )
);

const ControlPoint = React.forwardRef(
  (
    { controlActive, dblClick, onAfter, changeActive, zoomDelta },
    controlRef
  ) => (
    <circle
      className={`area-control-item ${controlActive ? "active" : ""}`}
      ref={controlRef}
      onDoubleClick={(e) => {
        e.stopPropagation();
        dblClick();
        onAfter();
      }}
      onMouseUp={(e) => changeActive(false)}
      onTouchEnd={(e) => changeActive(false)}
      cx={0}
      cy={0}
      r={pixelSizeByZoom(7, zoomDelta)}
    ></circle>
  )
);

const Line = React.forwardRef(
  ({ d, color, zoomDelta, controlActive, changeActive }, controlRef) => (
    <path
      ref={controlRef}
      d={d}
      stroke={`rgba(${color.r}, ${color.g}, ${color.b}, 1)`}
      strokeWidth={pixelSizeByZoom(4, zoomDelta)}
      fill="none"
      className={`addable ${controlActive ? "active" : ""}`}
      onMouseUp={(e) => changeActive(false)}
      onTouchEnd={(e) => changeActive(false)}
    />
  )
);

const PointComponent = React.forwardRef(
  (
    {
      type,
      controlActive,
      changeActive,
      zoomDelta,
      dblClick,
      onAfter,
      d,
      color,
    },
    controlRef
  ) => {
    let Component = null;
    switch (true) {
      case type === "point":
        Component = Point;
        break;
      case type === "control-point":
        Component = ControlPoint;
        break;
      case type === "line":
        Component = Line;
        break;
      default:
        break;
    }

    return (
      <Component
        type={type}
        controlActive={controlActive}
        changeActive={changeActive}
        zoomDelta={zoomDelta}
        dblClick={dblClick}
        onAfter={onAfter}
        d={d}
        color={color}
        ref={controlRef}
      />
    );
  }
);

const AreaControlItem = ({
  id,
  type = "point",
  x,
  y,
  zoomDelta,
  move,
  d,
  color,
  dblClick = () => {},
  addPoint = () => {},
  onAfter = () => {},
  onHover,
  onPointChange,
  onInteractionEnd,
  isMovablePoint,
}) => {
  const [controlActive, changeActive] = React.useState(false);

  const controlRef = React.useRef();

  const listener = (state) => {
    const { current } = state;
    changeActive(true);
    if (type === "line") {
      addPoint({
        clientX: current ? current.x : undefined,
        clientY: current ? current.y : undefined,
      });
    }
  };

  const arrowKeysMoveListener = React.useCallback(
    (x, y) => {
      const point = {
        x: x ?? 0,
        y: y ?? 0,
        deltaX: 0,
        deltaY: 0,
        arrowKeyMove: true,
      };
      move(point);
      if (onPointChange) onPointChange(point);
    },
    [move, onPointChange]
  );

  const { deltaX, deltaY, isDragged } = useDraggable(controlRef, {
    onDragStart: listener,
    dragProps: id,
    disabled: false,
  });

  const ref = React.useRef();
  const deltas = React.useRef({ deltaX: 0, deltaY: 0 });

  React.useEffect(() => {
    const dx = deltaX - deltas.current.deltaX;
    const dy = deltaY - deltas.current.deltaY;
    if (isDragged && (dx !== 0 || dy !== 0)) {
      move({
        x,
        y,
        deltaX: zoomDelta * dx,
        deltaY: zoomDelta * dy,
      });
    }
    if (
      !isDragged &&
      (deltas.current.deltaX !== 0 || deltas.current.deltaY !== 0)
    ) {
      changeActive(false);
      onAfter();
    }
    deltas.current = { deltaX, deltaY };
  }, [isDragged, deltaX, deltaY, move, x, y, zoomDelta, onAfter]);

  return (
    <g
      className="draggable"
      ref={ref}
      transform={`translate(${x}, ${y})`}
      fill={isMovablePoint ? "#9ea1a2" : "#fff"}
      stroke="#3e4346"
      strokeWidth={pixelSizeByZoom(1, zoomDelta)}
      onMouseOver={onHover}
      onTouchEnd={onInteractionEnd}
      onMouseUp={onInteractionEnd}
    >
      <Moveable
        x={x}
        y={y}
        move={arrowKeysMoveListener}
        moveablePoint={isMovablePoint}
      >
        <PointComponent
          type={type}
          controlActive={controlActive}
          changeActive={changeActive}
          zoomDelta={zoomDelta}
          dblClick={dblClick}
          onAfter={onAfter}
          d={d}
          color={color}
          ref={controlRef}
        />
      </Moveable>
    </g>
  );
};

let AreaControlItemWithState = ({ ...props }) => {
  const { uiState } = useStores();
  return (
    <AreaControlItem
      zoomDelta={uiState.zoomState?.zoomDelta ? uiState.zoomState.zoomDelta : 1}
      {...props}
    />
  );
};

AreaControlItemWithState = observer(AreaControlItemWithState);
export default AreaControlItemWithState;
