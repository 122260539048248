import { observer } from "mobx-react-lite";
import HeaderButton from "./HeaderButton";

import { downloadFileFromBase64 } from "../elements/Link";
import { convertPlanJSON } from "@dvsproj/ipat-core/planUtils";

let DownloadPlanButton = ({ plan }) => {
  return (
    <HeaderButton
      className="download"
      onClick={async (e) => {
        if (plan != null) {
          console.debug(
            "Download file. elements count = " + plan.elements.length,
            plan.bomItems.length
          );

          let planJSON = plan.toJSON;
          if (plan.background) {
            const { width, height, src } = plan.background;
            planJSON.background = {
              width,
              height,
              src,
            };
          }

          downloadFileFromBase64(
            `data:application/json;charset=utf-8,%EF%BB%BF` +
              btoa(JSON.stringify(convertPlanJSON(planJSON, "10cm"))),
            "plan.json"
          );
        }
      }}
      Icon={() => <>&#10507;</>}
    />
  );
};
DownloadPlanButton = observer(DownloadPlanButton);

export default DownloadPlanButton;
