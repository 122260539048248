import React from "react";

const DefaultSVGFilters = () => {
  return (
    <filter id="grayscale" colorInterpolationFilters="sRGB">
      <feColorMatrix
        type="matrix"
        values="0.3333 0.3333 0.3333 0 0.001 0.3333 0.3333 0.3333 0 0.001 0.3333 0.3333 0.3333 0 0.001 0 0 0 1 0.001"
      />
    </filter>
  );
};
export default DefaultSVGFilters;
