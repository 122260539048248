import React from "react";

export const ConnectionPointSVG = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="37"
      height="37"
      viewBox="-1 -1 38 38"
    >
      <path
        fill={color}
        d="M18,0A18,18,0,1,0,36,18,17.981,17.981,0,0,0,18,0Z"
        stroke="#f5f5f5"
      />
      <g transform="translate(6.75 8.887)">
        <path
          id="Pfad_122"
          data-name="Pfad 122"
          fill="#fff"
          d="M27.263,9.025V12.4h-3.6v2.813H22.2V12.4H12.413v2.813H10.95V12.4H7.125V9.025H27.263M28.388,7.9H6v5.625H9.6v2.812h3.712V13.525H20.85v2.812h3.712V13.525h3.6V7.9Z"
          transform="translate(-6 -7.9)"
        />
      </g>
      <path
        fill="#fff"
        d="M24.4,23.087a3.15,3.15,0,1,1-6.3,0c0-1.687,3.15-6.187,3.15-6.187S24.4,21.287,24.4,23.087Zm-1.687-1.012a.788.788,0,1,0,.787.788A.845.845,0,0,0,22.713,22.075Z"
        transform="translate(2.263 2.113)"
      />
      <path
        fill="#fff"
        d="M14.4,23.087a3.144,3.144,0,0,1-3.15,3.15,3.076,3.076,0,0,1-3.15-3.15c0-1.687,3.15-6.187,3.15-6.187S14.4,21.287,14.4,23.087Zm-1.8-1.012a.788.788,0,1,0,.787.788A.769.769,0,0,0,12.6,22.075Z"
        transform="translate(1.012 2.113)"
      />
    </svg>
  );
};

export const TPointSVG = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="37"
      height="37"
      viewBox="-1 -1 38 38"
    >
      <g transform="translate(-1076.353 -571.236)">
        <circle
          fill={color}
          stroke="#f5f5f5"
          cx="18"
          cy="18"
          r="18"
          transform="translate(1076.353 571.236)"
        />
        <g>
          <rect
            fill="#f5f5f5"
            width="9.211"
            height="2.046"
            transform="translate(1089.75 579.991)"
          />
          <rect
            fill="#f5f5f5"
            width="2.054"
            height="9.612"
            transform="translate(1103.566 587.65)"
          />
          <path
            fill="#f5f5f5"
            d="M1106.6,588.619v7.673h4.316c.152-.358.278-.73.408-1.1H1107.7l0-5.479h4.628c0-.16.024-.316.024-.477,0-.208-.024-.41-.031-.617Z"
          />
          <path
            fill="#f5f5f5"
            d="M1090.717,578.9h7.273V571.6h0c-.359-.074-.728-.116-1.094-.168V577.8h-5.079v-6.37c-.368.052-.739.1-1.1.169h0Z"
          />
          <rect
            fill="#f5f5f5"
            width="2.054"
            height="9.612"
            transform="translate(1083.086 587.65)"
          />
          <path
            fill="#f5f5f5"
            d="M1081.005,589.713l0,5.479h-3.623c.129.37.255.742.408,1.1h4.315v-7.673h-5.723c-.007.207-.031.409-.031.617,0,.161.02.317.024.477Z"
          />
          <path
            fill="#f5f5f5"
            d="M1102.548,588.162H1101.1a2.656,2.656,0,0,1-2.653-2.652v-2.32h-8.2v2.32a2.656,2.656,0,0,1-2.653,2.652h-1.446v8.588h16.394Z"
          />
        </g>
      </g>
    </svg>
  );
};

export const LPointSVG = ({ color }) => {
  return (
    <svg width="37" height="37" viewBox="-1 -2 39 40">
      <g transform="translate(-687 -285)" stroke="#f5f5f5">
        <circle
          id="Ellipse_411"
          data-name="Ellipse 411"
          fill={color}
          cx="18"
          cy="18"
          r="19"
          transform="translate(687 285)"
        ></circle>
        <g id="Gruppe_maskieren_4" data-name="Gruppe maskieren 4">
          <path
            id="Vereinigungsmenge_22"
            data-name="Vereinigungsmenge 22"
            fill="#f5f5f5"
            stroke="rgba(0,0,0,0)"
            strokeMiterlimit="10"
            d="M15.8,31.387V21.519H18v9.868Zm-3.644-.548A11.627,11.627,0,0,1,.548,19.227V16.742H9.318v2.485a2.845,2.845,0,0,0,2.842,2.841h2.549v8.771Zm6.821-.546V22.62H30.516A19.479,19.479,0,0,1,27.6,30.293Zm1.1-1.1h6.913a18.218,18.218,0,0,0,2.2-5.479H20.079ZM0,15.507V13.315H9.867v2.191Zm1.1-3.316V2.543A19.371,19.371,0,0,1,8.773,0V12.191ZM2.2,3.184V11.1H7.679V1.266A18.187,18.187,0,0,0,2.2,3.184Z"
            transform="translate(693.615 283.74)"
          ></path>
        </g>
      </g>
    </svg>
  );
};

export const ConnectPipeSVG = ({
  color,
  hasArrows = false,
  stroke = "#f5f5f5",
}) => {
  return (
    <svg
      id="CONECT_PIPE"
      xmlns="http://www.w3.org/2000/svg"
      width="37"
      height="37"
      viewBox="0 0 36 36"
    >
      <g id="Ellipse_411" data-name="Ellipse 411" fill={color} stroke={stroke}>
        <circle stroke="none" cx="18" cy="18" r="18" />
        <circle fill="none" cx="18" cy="18" r="17.5" />
      </g>
      <path
        id="Vereinigungsmenge_21"
        data-name="Vereinigungsmenge 21"
        fill="#f5f5f5"
        stroke="rgba(0,0,0,0)"
        strokeMiterlimit="10"
        d="M23.7,9H2.938C1.265,9,0,7.066,0,4.5S1.265,0,2.938,0H23.855a.675.675,0,0,1,.265.06c1.56.168,2.713,2.021,2.713,4.469,0,2.565-1.264,4.5-2.938,4.5C23.829,9.029,23.768,9.005,23.7,9Zm.134-7.959a.425.425,0,0,1-.043.015,1.781,1.781,0,0,0-1.126,1.055c-.007.013-.014.031-.021.044a4.034,4.034,0,0,0-.191.444c-.023.066-.041.14-.064.211-.036.117-.075.233-.1.359s-.049.263-.069.4c-.013.084-.031.163-.042.25a6.168,6.168,0,0,0,0,1.435c0,.016.006.031.007.047a5.536,5.536,0,0,0,.11.617c0,.021.011.039.016.059a5.117,5.117,0,0,0,.158.524c.023.063.051.116.077.175.046.109.09.219.142.316a3.363,3.363,0,0,0,.2.32c.017.024.033.053.05.076A1.39,1.39,0,0,0,23.815,8a.6.6,0,0,1,.078.023h0c.832,0,1.763-1.5,1.763-3.5s-.931-3.5-1.763-3.5C23.876,1.029,23.854,1.039,23.835,1.041ZM1.174,4.5c0,2,.931,3.5,1.763,3.5H22c-.007-.009-.013-.023-.02-.031a4.1,4.1,0,0,1-.376-.591c-.019-.037-.04-.073-.059-.111a5.247,5.247,0,0,1-.263-.656c-.018-.057-.04-.111-.058-.17a6.556,6.556,0,0,1-.158-.715c-.012-.07-.03-.134-.04-.205a7.647,7.647,0,0,1-.068-.991,7.17,7.17,0,0,1,.074-1.012c.008-.057.022-.107.031-.164a6.233,6.233,0,0,1,.181-.791c.011-.033.022-.066.034-.1a5.055,5.055,0,0,1,.312-.758l.015-.029A3.864,3.864,0,0,1,22.052,1H2.938C2.106,1,1.174,2.5,1.174,4.5ZM3.737,7.014v-.5h15.89v.5Z"
        transform="translate(4.583 13.486)"
      />
      {hasArrows && (
        <g id="Omnidirect_Pipe" transform="translate(-624.5 -149.84)">
          <path
            id="FF"
            d="M904.351 520.415h9.4l-2.553-2"
            fill="none"
            stroke="#fff"
            transform="translate(-267.351 -364.874)"
          />
          <path
            id="REW"
            d="M904.351 520.415h9.4l-2.553-2"
            fill="none"
            stroke="#fff"
            transform="rotate(180 775.878 338.979)"
          />
        </g>
      )}
    </svg>
  );
};

export const SprinklerPointSVG = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="37"
      height="37"
      viewBox="0 0 37 37"
    >
      <g id="CONECTION_POINT" transform="translate(-1038.93 -604.23)">
        <path
          id="Pfad_456"
          data-name="Pfad 456"
          fill={color}
          stroke="#f5f5f5"
          d="M1075.43,622.73a17.989,17.989,0,0,1-18,18,17.64,17.64,0,0,1-2.93-.24,18,18,0,1,1,20.93-17.76Z"
        />
        <g id="Gruppe_1076" data-name="Gruppe 1076">
          <rect
            id="Rechteck_1652"
            data-name="Rechteck 1652"
            fill="#f5f5f5"
            width="9"
            height="2"
            transform="translate(1052.936 625.219)"
          />
          <path
            id="Pfad_457"
            data-name="Pfad 457"
            fill="#f5f5f5"
            d="M1060.37,628.2v12.29a17.849,17.849,0,0,1-2.94.24,17.64,17.64,0,0,1-2.93-.24V628.2Z"
          />
          <rect
            id="Rechteck_1653"
            data-name="Rechteck 1653"
            fill="#f5f5f5"
            width="2.31"
            height="1.662"
            transform="translate(1056.281 613.819)"
          />
          <path
            id="Pfad_458"
            data-name="Pfad 458"
            fill="#f5f5f5"
            d="M1061.633,611.878a4.2,4.2,0,1,0-8.39,0v1.33h8.39Zm-1,.33h-6.39v-.33a3.2,3.2,0,1,1,6.39,0Z"
          />
          <path
            id="Pfad_459"
            data-name="Pfad 459"
            fill="#f5f5f5"
            d="M1060.343,623.239v-3.621h1.39v-3.57h-8.59v3.57h1.38v3.621h-3.16v1h12.146v-1Zm-6.2-4.621v-1.57h6.59v1.57h-1.39v4.56h-3.82v-4.56Z"
          />
        </g>
      </g>
    </svg>
  );
};

export const StartPointSVG = ({ color }) => {
  return (
    <svg
      id="ENDPOINT"
      xmlns="http://www.w3.org/2000/svg"
      width="37"
      height="37"
      viewBox="0 0 37 37"
    >
      <g transform="translate(-996.321 -568.988)">
        <circle
          id="Ellipse_411"
          data-name="Ellipse 411"
          fill={color}
          stroke="#f5f5f5"
          cx="18"
          cy="18"
          r="18"
          transform="translate(996.321 568.988)"
        />
        <g id="Gruppe_1077" data-name="Gruppe 1077">
          <rect
            id="Rechteck_1654"
            data-name="Rechteck 1654"
            fill="#f5f5f5"
            width="2.054"
            height="9.612"
            transform="translate(1011.648 582.043)"
          />
          <rect
            id="Rechteck_1655"
            data-name="Rechteck 1655"
            fill="#f5f5f5"
            width="2.069"
            height="7.672"
            transform="translate(1014.757 583.014)"
          />
          <path
            id="Pfad_460"
            data-name="Pfad 460"
            fill="#f5f5f5"
            d="M996.71,590.69h13.96v-7.68h-13.9v.01l-.21,1.09h13.01v5.48H996.51Z"
          />
        </g>
      </g>
    </svg>
  );
};

export const CircuitSVG = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="37"
      height="37"
      viewBox="0 0 37 37"
    >
      <g id="Cirquit_Icon" transform="translate(0.5 0.5)">
        <circle
          id="Ellipse_434"
          data-name="Ellipse 434"
          fill={color}
          stroke="#f5f5f5"
          cx="18"
          cy="18"
          r="18"
        />
        <path
          id="Pfad_482"
          data-name="Pfad 482"
          fill="#fff"
          d="M1154.92,639.09a2.56,2.56,0,0,0-1.61.56l-5.65-4.36a2.643,2.643,0,1,0-5.13-.9,2.541,2.541,0,0,0,.12.78l-11.92,8.25a2.67,2.67,0,0,0-1.68-.6,2.64,2.64,0,1,0,0,5.28,2.665,2.665,0,0,0,1.78-.69l9.7,5.97a2.117,2.117,0,0,0-.04.44,2.64,2.64,0,1,0,5.28,0,2.512,2.512,0,0,0-.29-1.18l8.23-8.57a2.638,2.638,0,1,0,1.21-4.98Zm-11.39-4.7a1.645,1.645,0,0,1,3.29,0,1.941,1.941,0,0,1-.02.24,1.621,1.621,0,0,1-.89,1.22,1.53,1.53,0,0,1-.74.18,1.653,1.653,0,0,1-1.63-1.48A.859.859,0,0,1,1143.53,634.39Zm-14.48,12.71a1.64,1.64,0,1,1,0-3.28,1.549,1.549,0,0,1,.8.22,1.6,1.6,0,0,1,.82,1.25.47.47,0,0,1,.02.17.222.222,0,0,1-.01.08,1.594,1.594,0,0,1-.76,1.3A1.537,1.537,0,0,1,1129.05,647.1Zm15.72,6.72a1.639,1.639,0,0,1-3.27.16.807.807,0,0,1-.01-.16,1.611,1.611,0,0,1,.59-1.25,1.566,1.566,0,0,1,1.05-.39,1.53,1.53,0,0,1,.6.12,1.632,1.632,0,0,1,.99,1.13A1.736,1.736,0,0,1,1144.77,653.82Zm-.31-2.28a2.6,2.6,0,0,0-1.33-.36,2.628,2.628,0,0,0-1.93.85l-9.59-5.92a2.818,2.818,0,0,0,.08-.65,2.605,2.605,0,0,0-.12-.79l11.91-8.26a2.6,2.6,0,0,0,3.27.08l5.68,4.37a2.524,2.524,0,0,0-.15.87,2.606,2.606,0,0,0,.35,1.3Zm10.46-8.17a1.427,1.427,0,0,1-.46-.07,1.656,1.656,0,0,1-1.09-1.05,1.5,1.5,0,0,1-.09-.52.647.647,0,0,1,.02-.2,1.574,1.574,0,0,1,.84-1.23,1.5,1.5,0,0,1,.78-.21,1.64,1.64,0,1,1,0,3.28Z"
          transform="translate(-1124.016 -626.043)"
        />
      </g>
    </svg>
  );
};

export const DriplinePointSVG = () => (
  <svg width="17" height="17" viewBox="0 0 17 17">
    <g transform="translate(.5 .5)">
      <path
        d="M8 0a8 8 0 1 0 8 8 7.991 7.991 0 0 0-8-8z"
        fill="#7826ff"
        stroke="#3e4346"
      />
      <g transform="translate(3 3.95)">
        <path
          d="M15.45 8.4v1.5h-1.6v1.25h-.65V9.9H8.85v1.25H8.2V9.9H6.5V8.4h8.95m.5-.5H6v2.5h1.6v1.25h1.65V10.4h3.35v1.25h1.65V10.4h1.6V7.9z"
          fill="#fff"
          transform="translate(-6 -7.9)"
        />
      </g>
      <path
        d="M20.9 19.65a1.4 1.4 0 0 1-2.8 0c0-.75 1.4-2.75 1.4-2.75s1.4 1.95 1.4 2.75zm-.75-.45a.35.35 0 1 0 .35.35.376.376 0 0 0-.35-.35z"
        fill="#fff"
        transform="translate(-9.05 -8.45)"
      />
      <path
        d="M10.9 19.65a1.4 1.4 0 0 1-1.4 1.4 1.367 1.367 0 0 1-1.4-1.4c0-.75 1.4-2.75 1.4-2.75s1.4 1.95 1.4 2.75zm-.8-.45a.35.35 0 1 0 .35.35.342.342 0 0 0-.35-.35z"
        fill="#fff"
        transform="translate(-4.05 -8.45)"
      />
    </g>
  </svg>
);

export const WaterflowSVG = () => (
  <svg width="12" height="12" viewBox="0 0 12 12">
    <path
      fill="#3e4346"
      d="M 5.003 0 s -3.966 5.665 -4 7.919 a 0.273 0.273 0 0 1 -0.006 0.032 a 4 4 0 1 0 8 0 v -0.032 C 8.964 5.669 5.003 0 5.003 0 Z m 2.8 7.932 a 1.025 1.025 0 1 1 -2.05 0 v -0.013 a 1.025 1.025 0 1 1 2.05 0 Z"
    />
  </svg>
);

export const WaterdropSVG = () => (
  <svg width="12" height="12" viewBox="0 0 12 12">
    <path fill="#ff7600" d="M 0 6 a 5 5 90 1 0 10 0 a 5 5 90 1 0 -10 0 Z" />
    <path
      fill="#f5f5f5"
      d="M 4.488 7.26 L 4 4.1784 V 2.22 H 5.944 V 4.2072 L 5.504 7.26 Z M 4.032 9.7584 V 8.0448 H 5.944 V 9.78 Z"
    />
  </svg>
);

export const RzwsPointSVG = () => (
  <svg width="36" height="36" viewBox="0 0 36 36">
    <circle data-name="Ellipse 552" cx="18" cy="18" r="18" fill="#26791c" />
    <path
      data-name="Pfad 2784"
      d="M4 22.424h2v2H4zm-4 2h2v-2H0zm12.056 0h2v-2h-2zm4 0h2v-2h-2zM9.983 21.07v3.33h-2v-3.33H.028v-2h7.955v-2.709c-3.911-.309-6.954-2.51-6.954-5.175a4.272 4.272 0 0 1 1.733-3.268 2.938 2.938 0 0 1-.175-.992A3.694 3.694 0 0 1 4.492 3.9a2.2 2.2 0 0 1-.151-.8c0-1.737 2.013-3.1 4.583-3.1s4.583 1.363 4.583 3.1a2.189 2.189 0 0 1-.15.794 3.7 3.7 0 0 1 1.9 3.028 2.937 2.937 0 0 1-.174.992 4.272 4.272 0 0 1 1.733 3.268c0 2.638-2.983 4.82-6.837 5.164v2.72h8.045v2zm-2-5.712v-3.663L5.692 9.4l.708-.7 1.584 1.584V9.056h2v3.023l1.532-1.531.707.707-2.24 2.238v1.854c3.3-.313 5.837-2.057 5.837-4.161a3.457 3.457 0 0 0-1.64-2.7.5.5 0 0 1-.151-.631 2.033 2.033 0 0 0 .232-.924 2.934 2.934 0 0 0-1.8-2.4.5.5 0 0 1-.183-.71 1.324 1.324 0 0 0 .228-.715c0-1.14-1.64-2.1-3.583-2.1s-3.583.964-3.583 2.1a1.317 1.317 0 0 0 .228.715.5.5 0 0 1-.182.71 2.935 2.935 0 0 0-1.8 2.4 2.034 2.034 0 0 0 .233.924.5.5 0 0 1-.151.631 3.457 3.457 0 0 0-1.64 2.7c0 2.129 2.6 3.89 5.954 4.172"
      transform="translate(9.188 4.483)"
      fill="#f5f5f5"
    />
  </svg>
);

export const RaisedBedPointSVG = () => (
  <svg width="36" height="36" viewBox="0 0 36 36">
    <circle data-name="Ellipse 552" cx="18" cy="18" r="18" fill="#a75712" />
    <path
      data-name="Pfad 2773"
      d="M27 24.152H9v-7H7v9h22v-9h-2z"
      fill="#f5f5f5"
    />
    <path
      data-name="Rechteck 2489"
      transform="translate(11 20.259)"
      fill="#f5f5f5"
      d="M0 0h14v2H0z"
    />
    <path
      data-name="Pfad 2774"
      d="M13.542 11.36a8.524 8.524 0 0 0 3.437 2.021v3.67h2v-4.7a8.593 8.593 0 0 0 3.57-2.064 8.771 8.771 0 0 0 2.2-4.129l.138-.724-.724.14a8.737 8.737 0 0 0-4.115 2.2 8.782 8.782 0 0 0-2.166 3.994 8.326 8.326 0 0 0-1.831-2.926 8.732 8.732 0 0 0-4.114-2.2l-.724-.139.138.724a8.784 8.784 0 0 0 2.195 4.129m7.211-2.871A7.488 7.488 0 0 1 23.526 6.8a7.509 7.509 0 0 1-1.686 2.785 7.481 7.481 0 0 1-2.77 1.688 7.536 7.536 0 0 1 1.683-2.784m-5.415 1.068a7.436 7.436 0 0 1 1.641 2.676v.092a7.466 7.466 0 0 1-2.728-1.671 7.544 7.544 0 0 1-1.683-2.785 7.5 7.5 0 0 1 2.77 1.688"
      fill="#f5f5f5"
    />
    <path
      data-name="Rechteck 2490"
      transform="translate(14.972 17.052)"
      fill="#f5f5f5"
      d="M0 0h2v2H0z"
    />
    <path
      data-name="Rechteck 2491"
      transform="translate(10.972 17.052)"
      fill="#f5f5f5"
      d="M0 0h2v2H0z"
    />
    <path
      data-name="Rechteck 2492"
      transform="translate(23.028 17.052)"
      fill="#f5f5f5"
      d="M0 0h2v2H0z"
    />
    <path
      data-name="Pfad 2775"
      d="M21.028 19.052v-2h-2.055v2h2.055z"
      fill="#f5f5f5"
    />
  </svg>
);
