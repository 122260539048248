import React from "react";
import { observer } from "mobx-react";

import {
  pixelSizeByZoom,
  sizeInMetersByPixel,
  svgTextCondensed,
} from "@dvsproj/ipat-core/planUtils";
import { toFixedPrecision } from "@dvsproj/ipat-core/formatter";
import { remToPx } from "../../utils/uiUtils";

import { RectAndCircleAreaControl } from "./AreaControl";
import { Watermark } from "./Watermark";
import { ReactComponent as UnLockSVG } from "./../../assets/shape-unlock.svg";
import { ReactComponent as LockSVG } from "./../../assets/shape-lock.svg";

import Draggable from "../Draggable";
import { useIntl } from "react-intl";

const CircleArea = ({
  id,
  x,
  y,
  width,
  height,
  startAngle,
  color,
  watermarkCanBeShow,
  changeWidth,
  changeHeight,
  changeStartAngle,
  move,
  disabled,
  scale,
  zoomDelta,
  isSelected,
  onChange,
  setSelectedElement,
  precision,
  filter,
  useDefaultStyles,
  textsVisibility,
}) => {
  const { formatNumber } = useIntl();

  return (
    <Draggable
      id={id}
      x={x}
      y={y}
      move={move}
      onAfterMove={onChange}
      draggable={!disabled}
      cloneable={!disabled}
      dragProps={id}
      onClick={() => {
        setSelectedElement(id);
      }}
    >
      <g
        onClick={() => {
          setSelectedElement(id);
        }}
        className={`element area ${isSelected ? "active" : ""}`}
        transform={`rotate(${startAngle})`}
        filter={filter}
      >
        <g>
          <ellipse
            id={`circle-${id}`}
            className="circle"
            fill={`rgb(${color.r}, ${color.g}, ${color.b})`}
            fillOpacity="0.25"
            stroke={`rgba(${color.r}, ${color.g}, ${color.b}, 1)`}
            strokeWidth={
              textsVisibility && !textsVisibility.areaBorder
                ? 0
                : pixelSizeByZoom(
                    useDefaultStyles ? 1 : isSelected ? 4 : 2,
                    zoomDelta
                  )
            }
            cx={0}
            cy={0}
            rx={width / 2}
            ry={height / 2}
          />
          {watermarkCanBeShow && textsVisibility.areaWatermark && (
            <Watermark
              x={0}
              y={0}
              transform={`rotate(-${startAngle}) scale(${zoomDelta})`}
            />
          )}
          {textsVisibility.areaTexts && (
            <g
              style={{
                fontSize: pixelSizeByZoom(remToPx(0.75), zoomDelta),
                letterSpacing: pixelSizeByZoom(0.6, zoomDelta),
                fontWeight: "bold",
              }}
            >
              <g
                transform={`translate(${
                  width / 2 + pixelSizeByZoom(10, zoomDelta)
                }, ${pixelSizeByZoom(5, zoomDelta)})`}
              >
                <text x="" y="0" {...svgTextCondensed}>
                  {formatNumber(
                    toFixedPrecision(
                      sizeInMetersByPixel(height, scale),
                      precision
                    )
                  )}{" "}
                  m
                </text>
              </g>

              <g
                transform={`translate(0, ${
                  height / 2 + pixelSizeByZoom(19, zoomDelta)
                })`}
              >
                <text x="0" y="0" textAnchor="middle" {...svgTextCondensed}>
                  {formatNumber(
                    toFixedPrecision(
                      sizeInMetersByPixel(width, scale),
                      precision
                    )
                  )}{" "}
                  m
                </text>
              </g>
            </g>
          )}

          {isSelected && (
            <g
              className="lock"
              transform={`scale(${pixelSizeByZoom(
                1,
                zoomDelta
              )}) translate(${-12}, ${-12}) `}
            >
              <g>{disabled ? <LockSVG /> : <UnLockSVG />}</g>
            </g>
          )}
        </g>
        {isSelected && !disabled && (
          <RectAndCircleAreaControl
            width={width}
            height={height}
            zoomDelta={zoomDelta}
            scale={scale}
            startAngle={startAngle}
            changeAngle={changeStartAngle}
            onAfter={onChange}
            changeHeight={(delta) => {
              changeHeight(height + delta);
            }}
            changeWidth={(delta) => {
              changeWidth(width + delta);
            }}
            changeCenter={(deltaX, deltaY) => {
              move(deltaX ? x + deltaX : x, deltaY ? y + deltaY : y);
            }}
          />
        )}
      </g>
    </Draggable>
  );
};

export default observer(
  ({ element, planIsEditable = true, selectedElementId, ...props }) => (
    <CircleArea
      id={element.id}
      x={element.x}
      y={element.y}
      path={element.path}
      width={element.width}
      height={element.height}
      startAngle={element.startAngle}
      color={element.color}
      quantity={element.quantity}
      crossability={element.crossability}
      changeWidth={element.changeWidth}
      changeHeight={element.changeHeight}
      changeStartAngle={element.changeStartAngle}
      watermarkCanBeShow={element.isWatermarkCanBeShow}
      move={element.move}
      disabled={element.disabled || !planIsEditable}
      hasSelfIntersection={element.hasSelfIntersection}
      hasIntersection={element.hasIntersection}
      isSelected={element.id === selectedElementId}
      {...props}
    />
  )
);
