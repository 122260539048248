import React from "react";
import { observer } from "mobx-react";
import { action } from "mobx";

import { useDraggable } from "react-draggable-hoc";
import useDelayedAction from "../hooks/useDelayedAction";
import Moveable from "./Moveable";
import useStores from "../hooks/useStores";

/**
 * A draggable g component.
 *
 *
 *
 * @param {*} props
 * @property {drop | drag} movePhase
 */
function Draggable({
  selectedTool,
  id,
  x,
  y,
  zoomDelta,
  children,
  move,
  cloneable = false,
  movePhase = "drop",
  draggable = true,
  planDraggable,
  onClick,
  onChange,
  onDoubleClick,
  onAfterMove,
  onBeforeMove,
  actionDelay = onDoubleClick != null ? undefined : 0,
  setElementIsChanged,
  cloneElement,
  dragProps,
  className,
  fill,
  stroke,
  strokeWidth,
  onAfterClone = () => {},
}) {
  const [showDragEffect, setShowDragEffect] = React.useState(false);
  const [functionalKeyPressed, setFunctionalKeyPressed] = React.useState(false);

  const disabled = !(selectedTool === "select" && draggable && !planDraggable);

  const [changeDelayedAction] = useDelayedAction(actionDelay);

  const ref = React.useRef();
  const startPosition = React.useRef({ x, y });
  const deltaSum = React.useRef({ sdx: 0, sdy: 0 });
  const moveListener = React.useCallback(
    (state) => {
      const { x, y } = startPosition.current;
      const { deltaX, deltaY } = state;
      if (deltaX !== 0 || deltaY !== 0) {
        const dx = zoomDelta * deltaX;
        const dy = zoomDelta * deltaY;
        deltaSum.current = {
          sdx: (deltaSum.current.sdx ?? 0) + dx,
          sdy: (deltaSum.current.sdy ?? 0) + dy,
        };
        move((x ?? 0) + dx, (y ?? 0) + dy, {
          state,
          zoomDelta,
        });
      }
    },
    [move, zoomDelta]
  );

  const cleanRefs = () => {
    startPosition.current = null;
    deltaSum.current = {
      sdx: 0,
      sdy: 0,
    };
    setShowDragEffect(false);
  };

  const draggableProps = useDraggable(ref, {
    onDragStart: () => {
      startPosition.current = { x, y };
      deltaSum.current = {
        sdx: 0,
        sdy: 0,
      };
      setShowDragEffect(true);
      if (typeof onBeforeMove === "function") onBeforeMove(ref.current);
    },
    onDrag: (state) => {
      if (state.wasDetached) {
        const event = state.current.event;
        setFunctionalKeyPressed(event.shiftKey || event.altKey);

        if (movePhase === "drag") {
          moveListener(state);
        }
        if (typeof setElementIsChanged === "function") {
          setElementIsChanged(true);
        }
      }
    },
    onDrop: action((state) => {
      if (state.wasDetached) {
        moveListener(state);

        if (cloneable && functionalKeyPressed) {
          cloneElement();
          if (startPosition.current.x != null) {
            //for element with center
            move(startPosition.current.x, startPosition.current.y);
          } else {
            //for element with multiple points (polygonArea)
            move(-deltaSum.current.sdx, -deltaSum.current.sdy);
          }
          onAfterClone();
        }
      }

      cleanRefs();

      changeDelayedAction(() => {
        if (typeof setElementIsChanged === "function") {
          setElementIsChanged(false);
        }
        if (state.wasDetached) {
          if (typeof onAfterMove === "function") onAfterMove(ref.current);
        }
      });

      setFunctionalKeyPressed(false);
    }),
    onDragCancel: () => {
      cleanRefs();
      setFunctionalKeyPressed(false);
    },
    disabled,
    dragProps,
  });

  React.useEffect(() => {
    const node = ref && ref.current;
    if (node == null) return;

    const dblClickListener = () => {
      if (typeof onDoubleClick === "function") {
        changeDelayedAction(undefined);
        onDoubleClick();
      }
    };

    const clickListener = (e) => {
      if (!draggableProps.state.wasDetached && typeof onClick === "function") {
        changeDelayedAction(() => onClick(e));
      }
    };

    node.addEventListener("dblclick", dblClickListener);
    node.addEventListener("click", clickListener);

    return () => {
      //changeDelayedAction(undefined);
      node.removeEventListener("dblclick", dblClickListener);
      node.removeEventListener("click", clickListener);
    };
  });

  const deltas = React.useRef({ deltaX: 0, deltaY: 0, isDragged: false });
  const { deltaX, deltaY, isDragged } = draggableProps;
  React.useEffect(() => {
    deltas.current = { deltaX, deltaY, isDragged };
  }, [deltaX, deltaY, isDragged]);

  const getDelta = (key) =>
    movePhase === "drop"
      ? zoomDelta * ((dragProps && dragProps[key]) || deltas.current[key])
      : 0;

  const currentX = (x ?? 0) + getDelta("deltaX");
  const currentY = (y ?? 0) + getDelta("deltaY");

  const calculatedClassName = React.useMemo(() => {
    const classNames = [];
    if (className != null) classNames.push(className);
    if (!disabled) classNames.push("draggable");
    if (isDragged) classNames.push("active");
    return classNames.join(" ");
  }, [className, disabled, isDragged]);

  return (
    <Moveable
      id={id}
      x={x}
      y={y}
      move={move}
      onChange={typeof onChange === "function" ? onChange : onAfterMove}
      draggable={!disabled}
    >
      {
        //show clone
        showDragEffect &&
        functionalKeyPressed &&
        cloneable &&
        startPosition.current ? (
          <g
            className="active hovered cloned"
            transform={`translate(
                ${startPosition.current.x ?? 0}, ${
              startPosition.current.y ?? 0
            })`}
          >
            {children}
          </g>
        ) : null
      }
      <g
        id={id}
        className={calculatedClassName}
        ref={ref}
        transform={`translate(${!disabled ? currentX : x ?? 0}, ${
          !disabled ? currentY : y ?? 0
        })`}
        fill={fill}
        stroke={stroke}
        strokeWidth={strokeWidth}
      >
        {children}
      </g>
    </Moveable>
  );
}

let DraggableWithState = ({ id, ...props }) => {
  const { uiState } = useStores();
  return (
    <Draggable
      id={id}
      selectedTool={uiState.selectedTool}
      planDraggable={uiState.selectedOtherTool === "draggable"}
      zoomDelta={
        uiState.zoomState && uiState.zoomState.zoomDelta
          ? uiState.zoomState.zoomDelta
          : 1
      }
      setElementIsChanged={uiState.setElementIsChanged}
      cloneElement={() => {
        if (!id) return null;

        uiState.cloneElement(id);
      }}
      onAfterClone={() => {
        // if (uiState.reactions) {
        //   uiState.reactions.onSprinklerChange();
        // }
      }}
      {...props}
    />
  );
};
DraggableWithState = observer(DraggableWithState);

/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default DraggableWithState;
