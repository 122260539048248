import { sizeInMetersByPixel } from "@dvsproj/ipat-core/planUtils";
import { round } from "@dvsproj/ipat-core/formatter";
import {
  calculateCircuitsCountByValveBox,
  findFittingByTubeType,
  maxOfArray,
} from "./common-utils";
import { BomItem, Plan } from "../../config/plan-types";
import { Settings } from "../../config/settings-types";
import { IRRIGATION_TUBE_TYPE } from "../../config/dictionary";

type ValveBoxSettings = {
  circuitsCount: number;
  cableLength: number;
  id: string;
  type: string;
};

function _valveBoxCable(
  settings: any,
  length: number,
  circuitsCount: number
): BomItem {
  length = Math.max(round(length, 1, "ceil"), 0.1);

  const { bomList, elements } = settings;
  const { cableIrrigationValve } = elements;

  const cable = cableIrrigationValve.find(
    ({ minValves, maxValves, minLength, maxLength }) =>
      circuitsCount >= minValves &&
      circuitsCount <= maxValves &&
      length >= minLength &&
      length <= maxLength
  );

  if (cable != null) {
    return {
      bomId: cable.bomId,
      qualityList: bomList[cable.bomId],
      quantity: 1,
    };
  }

  let cablesByCircuitsCount = cableIrrigationValve.filter(
    ({ minValves, maxValves }) =>
      circuitsCount >= minValves && circuitsCount <= maxValves
  );
  if (cablesByCircuitsCount.length === 0) {
    const maxCircutsCable = maxOfArray<(typeof cableIrrigationValve)[0]>(
      cableIrrigationValve,
      "maxValves"
    );

    if (maxCircutsCable == null) {
      throw new Error("maxCircutsCable is not found");
    }

    cablesByCircuitsCount = cableIrrigationValve.filter(
      ({ minValves, maxValves }) =>
        maxCircutsCable.minValves === minValves &&
        maxCircutsCable.maxValves === maxValves
    );
  }

  let foundCable = cablesByCircuitsCount.find(
    ({ minLength, maxLength }) => length >= minLength && length <= maxLength
  );
  if (foundCable == null) {
    foundCable = maxOfArray(cablesByCircuitsCount, "maxLength");
  }

  if (foundCable == null) {
    throw new Error("ValveBoxCable not found");
  }

  return {
    bomId: foundCable.bomId,
    qualityList: bomList[foundCable.bomId],
    quantity:
      Math.ceil(circuitsCount / foundCable.maxValves) *
      Math.ceil(length / foundCable.maxLength),
  };
}

function _underfloorValveBox(
  settings: Settings,
  tubeType: IRRIGATION_TUBE_TYPE,
  circuitsCount: number
) {
  let bomItems: BomItem[] = [];

  const { bomList, elements } = settings;

  const { valveBoxConnector, valveBox: valveBoxList } = elements;
  const valveBoxes = valveBoxList
    .find((v) => v.value === "box-type")
    ?.types.filter((e) => e.type === tubeType);

  if (valveBoxes == null) {
    throw new Error(`Valveboxes types not found`);
  }

  const maxCircuits = maxOfArray(valveBoxes, "maxCircuits")?.maxCircuits;
  if (maxCircuits == null) {
    throw new Error("Max underfloor valvebox not found");
  }

  // if the number of circuits is greater than the maxZone,
  // then we take the same valveBox
  const boxQuantity = Math.ceil(circuitsCount / maxCircuits);

  let boxZone = Math.ceil(circuitsCount / boxQuantity);

  const bomByZone = valveBoxes.find(({ minCircuits, maxCircuits }) => {
    return boxZone >= minCircuits && boxZone <= maxCircuits;
  });

  if (bomByZone == null) {
    throw new Error("UnderfloorValvebox not found");
  }

  bomItems.push({
    bomId: bomByZone.bomId,
    qualityList: bomList[bomByZone.bomId],
    quantity: boxQuantity,
  });

  if (boxQuantity > 1) {
    bomItems.push({
      bomId: valveBoxConnector.bomId,
      qualityList: bomList[valveBoxConnector.bomId],
      quantity: boxQuantity - 1,
    });
  }

  return bomItems;
}

function _wallmountValveBox(
  settings: Settings,
  tubeType: IRRIGATION_TUBE_TYPE,
  circuitsCount: number
) {
  let bomItems: BomItem[] = [];

  const { bomList, elements } = settings;

  const { wallmountOneZone, valveBox: valveBoxList } = elements;
  const valveBoxes = valveBoxList
    .find((v) => v.value === "wallmount")
    ?.types.filter((e) => e.type === tubeType);

  if (valveBoxes == null) {
    throw new Error(`Wallmount types not found`);
  }

  let wallmountOneZoneQuantity: number = 0;
  let wallmount: any;

  const maxWallmount = maxOfArray(valveBoxes, "maxCircuits")?.maxCircuits;
  if (maxWallmount == null) {
    throw new Error("Max Wallmount not found");
  }

  // if zones > 10
  if (circuitsCount > maxWallmount) {
    wallmount = valveBoxes.find(({ minCircuits, maxCircuits }) => {
      return maxWallmount >= minCircuits && maxWallmount <= maxCircuits;
    });
    if (wallmountOneZone) {
      wallmountOneZoneQuantity = Math.max(circuitsCount - maxWallmount, 0);
    }
  } else {
    wallmount = valveBoxes.find(({ minCircuits, maxCircuits }) => {
      return circuitsCount >= minCircuits && circuitsCount <= maxCircuits;
    });
  }

  if (wallmount == null) {
    throw new Error("WallmountValveBox not found");
  }

  bomItems.push({
    bomId: wallmount.bomId,
    qualityList: bomList[wallmount.bomId],
    quantity: 1,
  });

  if (wallmountOneZoneQuantity > 0) {
    const wallmountOneZoneByTubeType = findFittingByTubeType(
      tubeType,
      wallmountOneZone
    );
    bomItems.push({
      bomId: wallmountOneZoneByTubeType.bomId,
      qualityList: bomList[wallmountOneZoneByTubeType.bomId],
      quantity: wallmountOneZoneQuantity,
    });
  }

  return bomItems;
}

function _valveBox(
  settings: Settings,
  tubeType: IRRIGATION_TUBE_TYPE,
  valueBoxSettings: ValveBoxSettings[]
) {
  let bomItems: BomItem[] = [];

  for (const valveBox of valueBoxSettings) {
    switch (valveBox.type) {
      case "box-type":
        bomItems.push(
          ..._underfloorValveBox(settings, tubeType, valveBox.circuitsCount)
        );
        break;
      case "wallmount":
        bomItems.push(
          ..._wallmountValveBox(settings, tubeType, valveBox.circuitsCount)
        );
        break;
      default:
        console.error(`ValveBox ${valveBox.type} not found`);
        break;
    }

    const product = _valveBoxCable(
      settings,
      valveBox.cableLength,
      valveBox.circuitsCount
    );
    bomItems.push(product);
  }

  return bomItems;
}

function valveBoxAndCables(settings: Settings, plan: Plan) {
  const circuitsCountByValveBox = calculateCircuitsCountByValveBox(plan);
  const valveBoxes = plan.systemElements.filter(
    (se) => se.systemType === "valve-box"
  );

  let valueBoxSettings: ValveBoxSettings[] = [];

  for (const valveBox of valveBoxes) {
    const cable = plan.irrigationValveCable?.find((cable) => {
      return [cable.startId, cable.stopId].includes(valveBox.id);
    });

    valueBoxSettings.push({
      id: valveBox.id,
      type: valveBox.valveBoxType,
      cableLength: sizeInMetersByPixel(cable?.distance ?? 0, plan.scale),
      circuitsCount: circuitsCountByValveBox[valveBox.id] ?? 1,
    });
  }

  return _valveBox(settings, plan.irrigationTubeType, valueBoxSettings);
}

export { valveBoxAndCables };
