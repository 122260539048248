import { action, extendObservable, observable } from "mobx";
import { v4 as uuid } from "uuid";

/**
 * Represents a sensor object
 * @param {*} param0 - sensor json
 * @param {*} settingsState - state that contain a labels for UI
 * @param {*} param2 - additional UI params
 * @returns sensor object
 */
const sensorFactory = (
  { id, name, x, y, disabled = false } = {},
  settingsState,
  _plan
) => {
  const state = observable({
    x,
    y,
    disabled,
  });

  //inject variables
  const sensor = observable({
    get type() {
      return "sensor";
    },
    id: id || "sensor-" + uuid(),
    name,
    get x() {
      return state.x;
    },
    set x(x) {
      state.x = x;
    },
    get y() {
      return state.y;
    },
    set y(y) {
      state.y = y;
    },
    disabled,
  });

  // getters
  // color and title by name and sensorType
  extendObservable(sensor, {
    get title() {
      return this.name;
    },
    get deleteConfirmText() {
      const lables = settingsState.texts.tools;
      return lables.sensor.delete;
    },
  });

  // actions
  extendObservable(sensor, {
    drag: action((x, y) => {
      state.x = x;
      state.y = y;
    }),
    onDisable: action(() => {
      sensor.disabled = !sensor.disabled;
    }),
    get toJSON() {
      return {
        id: this.id,
        name: this.name,
        type: this.type,
        x: this.x,
        y: this.y,
      };
    },
  });

  return sensor;
};

export default sensorFactory;
