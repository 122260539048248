import { useIntl } from "react-intl";
import ProductLink from "./ProductLink";
import QualityList from "./QualityList";
import QuantityInput from "./QuantityInput";

import { priceFormat } from "@dvsproj/ipat-core/formatter";
import React from "react";

import { ReactComponent as SensorSetSVG } from "./../../assets/sensor-set.svg";
import { observer } from "mobx-react-lite";

import MissingProductSVG from "../../assets/missing-product-image.svg";

const resolveLink = (linkId, formatMessage) => {
  if (!linkId) return;

  const link = formatMessage({ id: linkId });
  if (!link.startsWith("http")) return;

  return link;
};

let SmartGardenBomGroup = ({
  groupIndex,
  label,
  childs,
  planIsEditable,
  onBomItemChange,
  buyOnlySomeProducts,
}) => {
  const intl = useIntl();
  const { formatMessage } = intl;

  return (
    <div className="smart-garden">
      <div className="recom-group">{formatMessage({ id: label })}</div>

      <div>
        <div className="row">
          {childs.map(
            (
              {
                imageLink,
                productLink,
                quantity,
                quality,
                qualityList,
                name,
                description,
                price,
                pricePerUnit,
                disabled,
                articleNO,
                defaultQuantity,
                changeQuantity,
                changeQuality,
              },
              index
            ) => {
              const bom_disabled = !planIsEditable || disabled;
              const link = productLink
                ? formatMessage({ id: productLink })
                : undefined;

              return (
                <React.Fragment key={index}>
                  {index > 0 && <div className="separate" />}
                  <ProductLink
                    productLink={link}
                    className="column position-column"
                  >
                    {groupIndex + index + 1}
                  </ProductLink>

                  <img
                    className="column image-column"
                    alt=""
                    src={
                      resolveLink(imageLink, formatMessage) ?? MissingProductSVG
                    }
                    loading="lazy"
                  />

                  <ProductLink
                    productLink={link}
                    className="column title-column"
                  >
                    <div className="name">
                      {name ? formatMessage({ id: name }) : articleNO}
                    </div>
                    <div className="description">
                      {description
                        ? formatMessage({ id: description })
                        : undefined}
                    </div>
                  </ProductLink>

                  <div className="column column-with-dropdown">
                    <QualityList
                      value={quality}
                      options={qualityList}
                      onChange={(val) => {
                        if (!bom_disabled) {
                          changeQuality(val);
                          onBomItemChange();
                        }
                      }}
                      disabled={bom_disabled}
                    />
                  </div>

                  <ProductLink
                    productLink={link}
                    className="column article-column"
                  >
                    {articleNO}
                  </ProductLink>

                  <QuantityInput
                    quantity={quantity}
                    defaultQuantity={defaultQuantity}
                    changeQuantity={(val) => {
                      if (!bom_disabled) {
                        changeQuantity(val);
                        onBomItemChange();
                      }
                    }}
                    disabled={bom_disabled}
                    className="column quantity-column"
                  />

                  <ProductLink className="column price-per-unit-column">
                    {priceFormat(intl, pricePerUnit)}
                  </ProductLink>
                  <ProductLink className="column price-column">
                    {priceFormat(intl, price)}
                  </ProductLink>
                </React.Fragment>
              );
            }
          )}
        </div>
      </div>

      <button
        type="button"
        className="buy"
        disabled={!planIsEditable}
        onClick={async (e) => {
          if (planIsEditable) {
            await buyOnlySomeProducts(childs);
          }
        }}
      >
        <SensorSetSVG />
        {formatMessage({ id: "smart-garden.button" })}
      </button>
    </div>
  );
};
SmartGardenBomGroup = observer(SmartGardenBomGroup);

export default SmartGardenBomGroup;
