import { observer } from "mobx-react";

import Scroll from "../elements/Scroll";
import Popup from "./Popup";

import useStores from "../../hooks/useStores";
import { useIntl } from "react-intl";
import { ReactComponent as NotConnectedSVG } from "../../assets/not-connected-pipe.svg";
import { ReactComponent as PermanentPressureSVG } from "../../assets/permanent-pressure-pipe.svg";

function ViewIconSVG({
  innerColor,
  lineColor = "#f5f5f5",
  disabledColor = "#052334",
  disabled = false,
  onClick,
}) {
  lineColor = disabled ? disabledColor : lineColor;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      onClick={onClick}
    >
      <circle
        stroke="none"
        fill={innerColor}
        cx="16"
        cy="16"
        r="15"
        transform="translate(0 0)"
      />
      {/* border circle */}
      <path
        fill={lineColor}
        d="M642.18,498.143a14,14,0,1,1-14,14,14.016,14.016,0,0,1,14-14m0-2a16,16,0,1,0,16,16,16,16,0,0,0-16-16Z"
        transform="translate(-626.18 -496.143)"
      />
      {/* eye */}
      <path
        fill={lineColor}
        stroke="rgba(0,0,0,0)"
        strokeMiterlimit="10"
        d="M.212,6.642,0,6.3.186,5.95C.315,5.7,3.406,0,11.379,0h.19A15.282,15.282,0,0,1,23.7,5.879l.3.428-.327.406a17,17,0,0,1-12.1,5.837l-.3,0A13.478,13.478,0,0,1,.212,6.642ZM6.95,6.277A4.651,4.651,0,1,0,11.6,1.624,4.656,4.656,0,0,0,6.95,6.277ZM1.632,6.249a12.323,12.323,0,0,0,6.7,4.54,5.565,5.565,0,0,1,.05-9.066A10.938,10.938,0,0,0,1.632,6.249Zm15.551.027a5.571,5.571,0,0,1-2.046,4.313,16.617,16.617,0,0,0,7.078-4.343,14.264,14.264,0,0,0-7.243-4.409A5.576,5.576,0,0,1,17.183,6.277Zm-8.373,0A2.791,2.791,0,1,1,11.6,9.066,2.791,2.791,0,0,1,8.81,6.275Z"
        transform="translate(4.001 10)"
      />
      {disabled && (
        <line
          fill="none"
          stroke={lineColor}
          strokeWidth="2px"
          x1="21.333"
          y2="21.333"
          transform="translate(6 6)"
        />
      )}
    </svg>
  );
}

const PipeViewPopup = observer(
  ({
    pipes,
    hiddenPipeColors,
    togglePipeColor,
    hideAllPipes,
    showAllPipes,
    hasActive = false,
    onClose = () => {},
  }) => {
    const { formatMessage } = useIntl();

    const fixedPipesColors = ["#9EA1A2", "#006CFF"];

    pipes = pipes?.filter((p) => !fixedPipesColors.includes(p));

    return pipes ? (
      <Popup
        className="pipe-view-popup"
        hasActive={hasActive}
        position="top"
        onClose={onClose}
      >
        <div className="fixed-pipes">
          <div className="label">
            <NotConnectedSVG />
            <div>
              {formatMessage({
                id: "texts.tools.pipeView.not_connected.text",
              })}
            </div>
          </div>

          <div className="label">
            <PermanentPressureSVG />
            <div>
              {formatMessage({
                id: "texts.tools.pipeView.permanent_pressure_line.text",
              })}
            </div>
          </div>
        </div>

        <div className="divider" />

        <div
          className="show-hide-button"
          onClick={
            hiddenPipeColors.length === 0
              ? () => hideAllPipes(pipes)
              : showAllPipes
          }
        >
          {hiddenPipeColors.length === 0 ? (
            <>
              <ViewIconSVG disabledColor={"#f5f5f5"} disabled={true} />
              <div>
                {formatMessage({
                  id: "texts.tools.pipeView.hide_all_pipes.label",
                })}
              </div>
            </>
          ) : (
            <>
              <ViewIconSVG lineColor={"#c5d75d"} />
              <div style={{ color: "#c5d75d" }}>
                {formatMessage({
                  id: "texts.tools.pipeView.show_all_pipes.label",
                })}
              </div>
            </>
          )}
        </div>

        <Scroll className={`scroll pipe-item-wrap`}>
          {pipes.map((color, key) => {
            const hidden = hiddenPipeColors.indexOf(color) >= 0;

            return (
              <div
                key={key}
                className="pipe-item"
                onClick={() => togglePipeColor(color)}
              >
                <ViewIconSVG innerColor={color} disabled={hidden} />
              </div>
            );
          })}
        </Scroll>
      </Popup>
    ) : null;
  }
);

let PipeViewPopupWithState = ({ ...props }) => {
  const { uiState } = useStores();
  return (
    <PipeViewPopup
      pipes={uiState.plan ? uiState.plan.pipelineColors : []}
      hiddenPipeColors={
        uiState.hiddenPipeColors ? uiState.hiddenPipeColors : []
      }
      togglePipeColor={uiState.togglePipeColor}
      hideAllPipes={uiState.hideAllPipes}
      showAllPipes={uiState.showAllPipes}
      {...props}
    />
  );
};

PipeViewPopupWithState = observer(PipeViewPopupWithState);
export default PipeViewPopupWithState;
