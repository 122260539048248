import { extendObservable, action } from "mobx";

/**
 * Injects showAlert and showConfirm methods
 */
/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default (state) => {
  // inject variables
  extendObservable(state, {
    messageTitle: undefined,
    messageDescription: undefined,
    messageIcon: undefined,
    cancelLabel: undefined,
    cancelCallback: undefined,
    confirmLabel: undefined,
    confirmCallback: undefined,
    popupId: undefined,
  });

  // helper functions
  const clearDialog = action(() => {
    state.messageTitle = undefined;
    state.messageDescription = undefined;
    state.messageIcon = undefined;
    state.cancelLabel = undefined;
    state.cancelCallback = undefined;
    state.confirmLabel = undefined;
    state.confirmCallback = undefined;
    state.popupId = undefined;
  });

  const withDialogClose = (fn) => {
    return typeof fn === "function"
      ? () => {
          fn();
          clearDialog();
        }
      : undefined;
  };

  const setDialogProps = action(
    ({
      title,
      description,
      icon,
      cancel,
      cancelLabel,
      confirm,
      confirmLabel,
    } = {}) => {
      const confirmLabels = state.settingsState
        ? state.settingsState.dialog
        : null;

      state.messageTitle = title;
      state.messageDescription = description;
      state.messageIcon = icon;
      state.cancelCallback = withDialogClose(cancel);

      state.cancelLabel =
        cancelLabel ||
        (cancel != null
          ? confirmLabels
            ? state.intl.formatMessage({ id: confirmLabels.cancel })
            : "Cancel"
          : undefined);

      state.confirmCallback = withDialogClose(confirm);
      state.confirmLabel =
        confirmLabel ||
        (confirm != null
          ? confirmLabels
            ? state.intl.formatMessage({ id: confirmLabels.ok })
            : "Ok"
          : undefined);
    }
  );

  // inject API
  extendObservable(state, {
    /**
     * Setups configuration to show confirmation and clears after.
     * If cancel or confirm is not provided a promise,
     * that is rejected if cancel and resolved if confirmed,
     * is returned
     */
    showConfirm: ({ cancel, confirm, ...props } = {}) => {
      if (confirm || cancel) {
        const wrap = (fn = () => {}) => withDialogClose(fn);

        setDialogProps({
          cancel: wrap(cancel),
          confirm: wrap(confirm),
          ...props,
        });
      } else {
        return new Promise((resolve, reject) => {
          setDialogProps({ confirm: resolve, cancel: reject, ...props });
        }).finally(() => {
          clearDialog();
        });
      }
    },
    /**
     * Setups configuration to show alert and clears after.
     * If cancel or confirm is not provided a promise,
     * that is resolved on confirm, is returned
     */
    showAlert: ({ confirm, cancel, ...props }) => {
      if (confirm || cancel) {
        setDialogProps({
          confirm: withDialogClose(confirm || cancel || (() => {})),
          ...props,
        });
      } else {
        return new Promise((resolve) => {
          setDialogProps({ confirm: resolve, ...props });
        }).finally(() => {
          clearDialog();
        });
      }
    },
    showPopup: ({ id }) => {
      return new Promise(
        action((resolve) => {
          state.confirmCallback = resolve;
          state.popupId = id;
        })
      ).finally(() => {
        clearDialog();
      });
    },
  });
};
