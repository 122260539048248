import React from "react";
import AreaControlItem from "./AreaControlItem";
import AreaControlRotate from "./AreaControlRotate";
import {
  calculateTextsPositions,
  remToPx,
  toFixedPrecision,
} from "../../utils/uiUtils";
import {
  pixelSizeByZoom,
  svgTextCondensed,
} from "@dvsproj/ipat-core/planUtils";
import { useIntl } from "react-intl";

const deltaByStartAngle = (deltaX, deltaY, startAngle) => {
  const dY =
    deltaX * Math.sin(startAngle * (Math.PI / 180)) -
    deltaY * Math.cos(startAngle * (Math.PI / 180));

  const dX =
    deltaX * Math.cos(startAngle * (Math.PI / 180)) +
    deltaY * Math.sin(startAngle * (Math.PI / 180));

  return {
    deltaX: dX,
    deltaY: -dY,
  };
};

export const RectAndCircleAreaControl = ({
  width,
  height,
  disableMove,
  changeWidth,
  changeHeight,
  changeCenter,
  startAngle,
  changeAngle,
  zoomDelta,
  scale,
  onAfter = () => {},
}) => {
  return (
    <g className="area-control">
      <AreaControlRotate
        x={0}
        y={-height / 2}
        zoomDelta={zoomDelta}
        height={height}
        disableMove={disableMove}
        onAfter={onAfter}
        changeAngle={changeAngle}
        startAngle={startAngle}
      />
      <AreaControlItem
        id={`control-center-top`}
        x={0}
        y={-height / 2}
        zoomDelta={zoomDelta}
        scale={scale}
        disableMove={disableMove}
        onAfter={onAfter}
        move={({ deltaX, deltaY }) => {
          var newDelta = deltaByStartAngle(deltaX, deltaY, startAngle);
          changeHeight(-newDelta.deltaY);

          var centerDelta = deltaByStartAngle(
            0,
            0.5 * newDelta.deltaY,
            180 - startAngle
          );
          changeCenter(-centerDelta.deltaX, -centerDelta.deltaY);
        }}
      />
      <AreaControlItem
        id={`control-right-top`}
        x={width / 2}
        y={-height / 2}
        zoomDelta={zoomDelta}
        scale={scale}
        disableMove={disableMove}
        onAfter={onAfter}
        move={({ deltaX, deltaY }) => {
          var newDelta = deltaByStartAngle(deltaX, deltaY, startAngle);
          changeHeight(-newDelta.deltaY);
          changeWidth(newDelta.deltaX);

          var centerDelta = deltaByStartAngle(
            0.5 * newDelta.deltaX,
            0.5 * newDelta.deltaY,
            180 - startAngle
          );
          changeCenter(-centerDelta.deltaX, -centerDelta.deltaY);
        }}
      />
      <AreaControlItem
        id={`control-right-middle`}
        x={width / 2}
        y={0}
        zoomDelta={zoomDelta}
        scale={scale}
        disableMove={disableMove}
        onAfter={onAfter}
        move={({ deltaX, deltaY }) => {
          var newDelta = deltaByStartAngle(deltaX, deltaY, startAngle);
          changeWidth(newDelta.deltaX);

          var centerDelta = deltaByStartAngle(
            0.5 * newDelta.deltaX,
            0,
            180 - startAngle
          );
          changeCenter(-centerDelta.deltaX, -centerDelta.deltaY);
        }}
      />
      <AreaControlItem
        id={`control-right-bottom`}
        x={width / 2}
        y={height / 2}
        zoomDelta={zoomDelta}
        scale={scale}
        hasActive={true}
        disableMove={disableMove}
        onAfter={onAfter}
        move={({ deltaX, deltaY }) => {
          var newDelta = deltaByStartAngle(deltaX, deltaY, startAngle);
          changeWidth(newDelta.deltaX);
          changeHeight(newDelta.deltaY);

          var centerDelta = deltaByStartAngle(
            0.5 * newDelta.deltaX,
            0.5 * newDelta.deltaY,
            180 - startAngle
          );
          changeCenter(-centerDelta.deltaX, -centerDelta.deltaY);
        }}
      />
      <AreaControlItem
        id={`control-center-bottom`}
        x={0}
        y={height / 2}
        zoomDelta={zoomDelta}
        scale={scale}
        disableMove={disableMove}
        onAfter={onAfter}
        move={({ deltaX, deltaY }) => {
          var newDelta = deltaByStartAngle(deltaX, deltaY, startAngle);
          changeHeight(newDelta.deltaY);

          var centerDelta = deltaByStartAngle(
            0,
            0.5 * newDelta.deltaY,
            180 - startAngle
          );
          changeCenter(-centerDelta.deltaX, -centerDelta.deltaY);
        }}
      />
      <AreaControlItem
        id={`control-left-bottom`}
        x={-width / 2}
        y={height / 2}
        zoomDelta={zoomDelta}
        scale={scale}
        disableMove={disableMove}
        onAfter={onAfter}
        move={({ deltaX, deltaY }) => {
          var newDelta = deltaByStartAngle(deltaX, deltaY, startAngle);
          changeHeight(newDelta.deltaY);
          changeWidth(-newDelta.deltaX);

          var centerDelta = deltaByStartAngle(
            0.5 * newDelta.deltaX,
            0.5 * newDelta.deltaY,
            180 - startAngle
          );
          changeCenter(-centerDelta.deltaX, -centerDelta.deltaY);
        }}
      />
      <AreaControlItem
        id={`control-left-middle`}
        x={-width / 2}
        y={0}
        zoomDelta={zoomDelta}
        scale={scale}
        disableMove={disableMove}
        onAfter={onAfter}
        move={({ deltaX, deltaY }) => {
          var newDelta = deltaByStartAngle(deltaX, deltaY, startAngle);
          changeWidth(-newDelta.deltaX);

          var centerDelta = deltaByStartAngle(
            0.5 * newDelta.deltaX,
            0,
            180 - startAngle
          );
          changeCenter(-centerDelta.deltaX, -centerDelta.deltaY);
        }}
      />
      <AreaControlItem
        id={`control-left-top`}
        x={-width / 2}
        y={-height / 2}
        zoomDelta={zoomDelta}
        scale={scale}
        disableMove={disableMove}
        onAfter={onAfter}
        move={({ deltaX, deltaY }) => {
          var newDelta = deltaByStartAngle(deltaX, deltaY, startAngle);
          changeWidth(-newDelta.deltaX);
          changeHeight(-newDelta.deltaY);

          var centerDelta = deltaByStartAngle(
            0.5 * newDelta.deltaX,
            0.5 * newDelta.deltaY,
            180 - startAngle
          );
          changeCenter(-centerDelta.deltaX, -centerDelta.deltaY);
        }}
      />
    </g>
  );
};

export const FreeDrawingAreaControl = ({
  zoomDelta,
  scale,
  points,
  disableMove,
  lines,
  color,
  clearBezierPoint,
  removePoint,
  addPoint,
  move,
  shift,
  onAfter,
  onPointChange,
  onInteractionEnd,
  enclosed,
}) => {
  const { formatNumber } = useIntl();

  const controls = [];
  const textPositions = calculateTextsPositions(points, 1);

  const [isMovablePointIndex, setIsMovablePointIndex] = React.useState(0);
  const onHover = (index) => setIsMovablePointIndex(index);

  if (lines?.length) {
    lines.forEach((line, i) => {
      controls.push(
        <AreaControlItem
          key={`control-line-${i}`}
          id={`control-line-${i}`}
          d={`M ${line.start.x} ${line.start.y} 
          Q ${
            line.control
              ? line.control.x + " " + line.control.y
              : line.end.x + " " + line.end.y
          } 
          ${line.end.x} ${line.end.y}`}
          color={color}
          disableMove={disableMove}
          addPoint={(e) => addPoint(e, 2 * i)}
          move={({ x, y, deltaX, deltaY }) => {
            shift(2 * i + 2, deltaX, deltaY);
          }}
          onAfter={onAfter}
          type="line"
          x={0}
          y={0}
        />
      );
    });
  }

  points.forEach((point, i) => {
    controls.push(
      <AreaControlItem
        key={`control-${i}`}
        id={`control-${i}`}
        type={point.type}
        x={point.x}
        y={point.y}
        zoomDelta={zoomDelta}
        scale={scale}
        disableMove={disableMove}
        move={({ x, y, deltaX, deltaY, arrowKeyMove }) => {
          move(i, x + deltaX, y + deltaY, arrowKeyMove);
        }}
        onHover={() => point.type === "point" && onHover(i)}
        onAfter={onAfter}
        onInteractionEnd={i === 0 && !enclosed ? onInteractionEnd : null}
        onPointChange={!enclosed ? (p) => onPointChange(i, p) : null}
        dblClick={() => {
          setIsMovablePointIndex(points.length - 3);

          const removeFn = point.isControlPoint
            ? () => clearBezierPoint(i)
            : () => removePoint(i);
          removeFn();
        }}
        isMovablePoint={
          !enclosed && point.type === "point" && i === isMovablePointIndex
        }
      />
    );
  });

  if (!enclosed && textPositions != null) {
    points.forEach((point, i) => {
      if (point.type !== "point") return;

      const textPosition = textPositions[i];
      if (textPosition == null) return;

      controls.push(
        <g
          key={`line-angle-text-${i}`}
          style={{
            fontSize: pixelSizeByZoom(remToPx(0.75), zoomDelta),
            letterSpacing: pixelSizeByZoom(0.6, zoomDelta),
            fontWeight: "normal",
          }}
        >
          <g
            transform={`translate(${
              textPosition.x + pixelSizeByZoom(textPosition.dx, zoomDelta)
            }, ${
              textPosition.y + pixelSizeByZoom(textPosition.dy, zoomDelta)
            })`}
          >
            <text
              x="0"
              y="0"
              dominantBaseline={"middle"}
              textAnchor={"middle"}
              {...svgTextCondensed}
            >
              {formatNumber(toFixedPrecision(point.angle, 0))}°
            </text>
          </g>
        </g>
      );
    });
  }

  return <g className="area-control">{controls}</g>;
};
