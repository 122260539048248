import { action, extendObservable, observable } from "mobx";
import { v4 as uuid } from "uuid";

import { round } from "@dvsproj/ipat-core/formatter";
import {
  rotateVector,
  radiansFromDegree,
  rotatePoint,
} from "@dvsproj/ipat-core/geometryUtils";
import {
  findMpRotator,
  findMpStrip,
} from "@dvsproj/ipat-core/settings/settingsUtils";

import {
  sizeInMetersByPixel,
  sizeInPixelByMeters,
} from "@dvsproj/ipat-core/planUtils";
import { numberFormat } from "@dvsproj/ipat-core/formatter";

/**
 * Represents a sprinkler object
 * @param {*} param0 - sprinkler json
 * @param {*} settingsState - state that contain a labels for UI
 * @param {*} param2 - additional UI params
 * @returns sprinkler object
 */
const sprinklerFactory = (
  {
    id,
    name,
    color = "#3e4346",
    sprinklerType, // "circle" or "rect"
    x,
    y,
    startAngle,
    circleRadius,
    circleSectorAngle,
    rectHeight,
    rectWidth,
    rectType, // rectangle sprinkler type (left, center or right)
    kWH, // coef = width/height
    disabled = false,
    invalid = {
      startAngle: false,
      circleRadius: false,
      circleSectorAngle: false,
      rectHeight: false,
      rectWidth: false,
    },
    nozzleType,
    waterdrop,
  } = {},
  settingsState,
  { scale, sprinklerSetType }
) => {
  const sprinklerLabels = settingsState
    ? settingsState.texts.properties.sprinklers
    : null;

  const state = observable({
    x,
    y,
    startAngle: Math.round(startAngle),
    circleSectorAngle: Math.round(circleSectorAngle),
    nozzleType,
    waterdrop,
  });

  //inject variables
  const sprinkler = observable({
    get type() {
      return "sprinkler";
    },
    id: id || "sprinkler-" + uuid(),
    name,
    color,
    sprinklerType,
    kWH,
    set startAngle(val) {
      state.startAngle = val;
    },
    get startAngle() {
      const value = (Math.round(state.startAngle) + 360) % 360;
      return isNaN(value) || value === 0 ? 0 : value;
    },
    get realStartAngle() {
      return state.startAngle;
    },
    circleRadius,
    set circleSectorAngle(val) {
      state.circleSectorAngle = val;
    },
    get circleSectorAngle() {
      const value = (Math.round(state.circleSectorAngle) + 360) % 360;
      return isNaN(value) || value === 0 ? 360 : value;
    },
    get realCircleSectorAngle() {
      return Math.round(state.circleSectorAngle);
    },
    rectHeight: rectHeight != null ? rectHeight * 1 : undefined,
    rectWidth: rectWidth != null ? rectWidth * 1 : undefined,
    rectType,
    disabled,
    invalid: { ...invalid },
    get waterdropInBar() {
      return state.waterdrop
        ? numberFormat(state.waterdrop / 100000, 1) * 1
        : null;
    },
    get waterpressureInBar() {
      if (this.waterdropInBar == null) return null;

      if (this.waterdropInBar < 0) return null;

      return Math.ceil(Math.max(3.5 - this.waterdropInBar, 0) * 10) / 10;
    },
    get nozzleType() {
      return state.nozzleType ?? sprinkler.findConfig()?.nozzleType;
    },
    set nozzleType(v) {
      state.nozzleType = v;
    },
    get x() {
      return state.x;
    },
    set x(x) {
      state.x = x;
    },
    get y() {
      return state.y;
    },
    set y(y) {
      state.y = y;
    },
  });

  // getters
  // color and title by name and sprinklerType
  extendObservable(sprinkler, {
    get title() {
      return this.name;
    },
    get hasWaterpressureWarn() {
      return (
        sprinkler.waterpressureInBar != null &&
        sprinkler.waterpressureInBar * 10 < 28
      );
    },
    //is invalid when not available sprinkler by name, type, angle and radius
    get hasInvalid() {
      var invalidT = false;
      for (var invalidKey in sprinkler.invalid) {
        invalidT = invalidT || sprinkler.invalid[invalidKey];
      }

      return invalidT;
    },
    get isCircle() {
      return sprinkler.sprinklerType === "circle";
    },
    get isRectangle() {
      return sprinkler.sprinklerType === "rect";
    },
    get deleteConfirmText() {
      const lables = settingsState.texts.tools;
      if (sprinkler.isCircle) return lables.sprinklerCircle.delete;
      if (sprinkler.isRectangle) return lables.sprinklerRect.delete;
      return null;
    },
    rectTypes: [
      {
        label: sprinklerLabels
          ? sprinklerLabels["sprinklerRect"].type.right
          : "Right",
        value: "right",
      },
      {
        label: sprinklerLabels
          ? sprinklerLabels["sprinklerRect"].type.left
          : "Left",
        value: "left",
      },
      {
        label: sprinklerLabels
          ? sprinklerLabels["sprinklerRect"].type.center
          : "Center",
        value: "center",
      },
    ],
  });

  // actions
  extendObservable(sprinkler, {
    drag: action((x, y) => {
      state.x = x;
      state.y = y;
    }),
    onDisable: action(() => {
      sprinkler.disabled = !sprinkler.disabled;
    }),
    changeWaterDrop: action((waterdrop) => {
      state.waterdrop = waterdrop;
    }),
    changeCircleRadius: action((value) => {
      const radius = isNaN(value) ? 0 : value;

      if (sprinkler.circleRadius !== radius) {
        sprinkler.circleRadius = radius;
        sprinkler.updateConfigAndValidate("circleRadius");
      }
    }),
    changeStartAngle: action((value) => {
      sprinkler.startAngle = Math.round(value);
    }),
    changeCircleSectorAngle: action((value) => {
      const circleSectorAngle = Math.round(value);

      if (sprinkler.circleSectorAngle !== circleSectorAngle) {
        sprinkler.circleSectorAngle = circleSectorAngle;
        sprinkler.updateConfigAndValidate("circleSectorAngle");
      }
    }),
    changeRectWidth: action((value) => {
      const valueFormat = round(value, 1);
      const rectWidth = round(valueFormat, 1);

      if (rectWidth >= 0 && sprinkler.rectWidth !== rectWidth) {
        sprinkler.rectWidth = rectWidth;
        sprinkler.rectHeight = round(sprinkler.rectWidth / sprinkler.kWH, 1);
        sprinkler.updateConfigAndValidate("rectWidth");
      }
    }),
    changeRectHeight: action((value) => {
      const valueFormat = round(value, 1);
      const rectHeight = round(valueFormat, 1);

      if (rectHeight >= 0 && sprinkler.rectHeight !== rectHeight) {
        sprinkler.rectHeight = rectHeight;
        sprinkler.rectWidth = round(sprinkler.rectHeight * sprinkler.kWH, 1);
        sprinkler.updateConfigAndValidate("rectHeight");
      }
    }),
    changeRectType: action((type) => {
      const hasType = sprinkler.rectTypes.find(({ value }) => value === type);
      if (hasType && sprinkler.rectType !== type) {
        let [x, y] = rotateVector(
          sprinkler.x,
          sprinkler.y,
          -radiansFromDegree(startAngle)
        );

        switch (type) {
          case "left":
            switch (sprinkler.rectType) {
              case "center":
                x = x - +sprinkler.rectWidth / 2;
                break;
              case "right":
                x = x - +sprinkler.rectWidth;
                break;
              default:
                break;
            }

            break;
          case "right":
            switch (sprinkler.rectType) {
              case "center":
                x = x + +sprinkler.rectWidth / 2;
                break;
              case "left":
                x = x + +sprinkler.rectWidth;
                break;
              default:
                break;
            }
            break;
          case "center":
            switch (sprinkler.rectType) {
              case "right":
                x = x - +sprinkler.rectWidth / 2;
                break;
              case "left":
                x = x + sprinkler.rectWidth / 2;
                break;
              default:
                break;
            }
            break;
          default:
            break;
        }
        let [x1, y1] = rotateVector(x, y, radiansFromDegree(startAngle));
        sprinkler.x = x1;
        sprinkler.y = y1;
        sprinkler.rectType = type;

        if (!sprinkler.updateConfigAndValidate()) {
          const curSprinkler = findMpStrip(
            settingsState,
            sprinkler.rectType,
            null,
            null,
            sprinklerSetType
          );

          sprinkler.nozzleType = curSprinkler.nozzleType;

          const nozzle = curSprinkler.nozzleRadiuses.find(
            (e) => e.nozzleType === curSprinkler.nozzleType
          );
          sprinkler.rectWidth = sizeInPixelByMeters(nozzle.maxWidth, scale);
          sprinkler.rectHeight = sizeInPixelByMeters(nozzle.maxHeight, scale);
          sprinkler.kWH = curSprinkler.kWH;
          sprinkler.name = curSprinkler.name;
          sprinkler.color = curSprinkler.color;
        }

        Object.keys(sprinkler.invalid).forEach(
          (key) => (sprinkler.invalid[key] = false)
        );
      }
    }),

    //find sprinkler by type, angle and radius
    findConfig() {
      let rotator;
      switch (sprinkler.sprinklerType) {
        case "circle":
          rotator = findMpRotator(
            settingsState,
            round(sizeInMetersByPixel(sprinkler.circleRadius, scale), 1),
            sprinkler.circleSectorAngle,
            sprinklerSetType
          );
          break;
        case "rect":
          rotator = findMpStrip(
            settingsState,
            sprinkler.rectType,
            round(sizeInMetersByPixel(sprinkler.rectWidth, scale), 1),
            round(sizeInMetersByPixel(sprinkler.rectHeight, scale), 1),
            sprinklerSetType
          );
          break;
        default:
          break;
      }
      return rotator != null ? rotator : undefined;
    },
    //calculate by water consumption and angle
    get waterflow() {
      const curSprinkler = sprinkler.findConfig();
      let result = 0;
      if (curSprinkler != null && curSprinkler.waterConsumption) {
        result = sprinkler.isCircle
          ? curSprinkler.waterConsumption * sprinkler.circleSectorAngle
          : curSprinkler.waterConsumption;
      }
      return !result ? 0 : result;
    },
    get extremePoints() {
      const {
        isCircle,
        isRectangle,
        rectType,
        circleRadius,
        rectWidth,
        rectHeight,
        startAngle,
        x,
        y,
      } = sprinkler;

      let acc = [];
      const rotate = (point) => rotatePoint(point, { x, y }, -startAngle);

      if (isCircle) {
        for (let i = 0; i <= 360; i = i + 90) {
          acc.push({
            x: x + circleRadius * Math.cos((i * Math.PI) / 180),
            y: y + circleRadius * Math.sin((i * Math.PI) / 180),
          });
        }
      }
      if (isRectangle) {
        switch (rectType) {
          case "right":
            acc.push(
              ...[
                rotate({
                  x: x - rectWidth,
                  y: y - rectHeight,
                }),
                rotate({
                  x: x,
                  y: y - rectHeight,
                }),
                rotate({
                  x: x,
                  y: y,
                }),
                rotate({
                  x: x - rectWidth,
                  y: y,
                }),
              ]
            );
            break;
          case "left":
            acc.push(
              ...[
                rotate({
                  x: x,
                  y: y - rectHeight,
                }),
                rotate({
                  x: x + rectWidth,
                  y: y - rectHeight,
                }),
                rotate({
                  x: x + rectWidth,
                  y: y,
                }),
                rotate({
                  x: x,
                  y: y,
                }),
              ]
            );
            break;
          case "center":
            acc.push(
              ...[
                rotate({
                  x: x - rectWidth / 2,
                  y: y - rectHeight,
                }),
                rotate({
                  x: x + rectWidth / 2,
                  y: y - rectHeight,
                }),
                rotate({
                  x: x + rectWidth / 2,
                  y: y,
                }),
                rotate({
                  x: x - rectWidth / 2,
                  y: y,
                }),
              ]
            );
            break;
          default:
            break;
        }
      }

      return acc;
    },
    updateConfigAndValidate(modifiedAttribute = null) {
      const curSprinkler = sprinkler.findConfig();
      Object.keys(sprinkler.invalid).forEach(
        (key) => (sprinkler.invalid[key] = false)
      );

      if (curSprinkler != null) {
        sprinkler.name = curSprinkler.name;
        sprinkler.nozzleType = curSprinkler.nozzleType;
        sprinkler.color = curSprinkler.color;

        return true;
      }

      sprinkler.name = null;
      sprinkler.nozzleType = null;

      if (modifiedAttribute) {
        sprinkler.invalid[modifiedAttribute] = true;
      } else {
        Object.keys(sprinkler.invalid).forEach(
          (key) => (sprinkler.invalid[key] = true)
        );
      }
      return false;
    },
    get toJSON() {
      const {
        id,
        name,
        color,
        type,
        sprinklerType,
        x,
        y,
        startAngle,
        circleRadius,
        circleSectorAngle,
        rectHeight,
        rectWidth,
        rectType,
        disabled,
        invalid,
        nozzleType,
        kWH,
        waterflow,
        waterdrop,
      } = this;

      return {
        id,
        name,
        color,
        type,
        sprinklerType,
        x,
        y,
        startAngle,
        circleRadius,
        circleSectorAngle,
        rectHeight,
        rectWidth,
        rectType,
        disabled,
        invalid: invalid ? { ...invalid } : undefined,
        nozzleType,
        kWH,
        waterflow,
        waterdrop,
      };
    },
  });

  return sprinkler;
};

export default sprinklerFactory;
