import { Plan } from "../../config/plan-types";

/**
 * Finds an element with max value among array element's attr.
 *
 * @param {*} array
 * @param {*} attr
 */
export function maxOfArray<T>(array: T[], attr: keyof T): T | undefined {
  const { length } = array;
  const valueFactory = attr != null ? (v) => v[attr] : (v) => v;
  if (length > 0) {
    let index = 0;
    let max = valueFactory(array[index]);
    for (let i = 1, length = array.length; i < length; i++) {
      const value = valueFactory(array[i]);
      if (value > max) {
        index = i;
        max = value;
      }
    }

    return array[index];
  }

  return undefined;
}

export function calculateCircuitsCountByValveBox(plan: Plan) {
  let valveBoxes = {} as Record<string, number>;

  plan.pipelines.forEach((pipeline) => {
    if (!pipeline.isTubingPipe && pipeline.valveboxIds?.length > 0) {
      pipeline.valveboxIds.forEach((pointId) => {
        if (valveBoxes[pointId] == null) valveBoxes[pointId] = 0;
        valveBoxes[pointId] += pipeline.driplinePoint?.driplineValvesCount ?? 1;
      });
    }
  });

  return valveBoxes;
}

export function calculateTotalCircuitsCount(plan: Plan) {
  const valveBoxes = calculateCircuitsCountByValveBox(plan);
  return Object.values(valveBoxes).reduce((acc, value) => acc + value, 0);
}

export function findFittingByTubeType<T extends { type: string }>(
  type: string,
  fittings: T[]
) {
  const result = fittings.find((e) => e.type === type);
  if (result == null) {
    throw new Error(`Fitting for ${type} not found`);
  }

  return result;
}
