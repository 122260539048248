import useStores from "../../hooks/useStores";
import { observer } from "mobx-react";
import React from "react";
import { useIntl } from "react-intl";

import { ReactComponent as PopupArrowRightSVG } from "../../assets/popup-arrow-d.svg";
import { useOuterClickEffect } from "../../hooks/useOuterClickEffect";
import Dropdown from "../elements/Dropdown";

const defaultAssessment = (labelFormatter = (t) => t.id) => [
  {
    label: "",
    value: null,
  },
  {
    label: labelFormatter({
      id: "texts.steps.sprinklers.assessment.dropdown.values.perfect.label",
    }),
    value: 1,
  },
  {
    label: labelFormatter({
      id: "texts.steps.sprinklers.assessment.dropdown.values.good.label",
    }),
    value: 2,
  },
  {
    label: labelFormatter({
      id: "texts.steps.sprinklers.assessment.dropdown.values.average.label",
    }),
    value: 3,
  },
  {
    label: labelFormatter({
      id: "texts.steps.sprinklers.assessment.dropdown.values.bad.label",
    }),
    value: 4,
  },
  {
    label: labelFormatter({
      id: "texts.steps.sprinklers.assessment.dropdown.values.unacceptable.label",
    }),
    value: 5,
  },
];
const defaultAnnotations = (titleFormatter = (t) => t.id) => ({
  bad_irr_areas: {
    title: titleFormatter({
      id: "texts.steps.sprinklers.annotation.badIrrigation.button",
    }),
    used: false,
  },
  mp800_used: {
    title: titleFormatter({
      id: "texts.steps.sprinklers.annotation.mp800HF.button",
    }),
    used: false,
  },
  too_many_flowerbeds: {
    title: titleFormatter({
      id: "texts.steps.sprinklers.annotation.tooManyFlowerBeds.button",
    }),
    used: false,
  },
  strip_sprays_dry_area: {
    title: titleFormatter({
      id: "texts.steps.sprinklers.annotation.strip-sprays-dry-area.button",
    }),
    used: false,
  },
  increase_radius_for_distribution: {
    title: titleFormatter({
      id: "texts.steps.sprinklers.annotation.increaseRadiusForBetterDistrib.button",
    }),
    used: false,
  },
  increase_radius_to_reduce_sprinklers: {
    title: titleFormatter({
      id: "texts.steps.sprinklers.annotation.increaseRadiusToReduceSprinklerNumber.button",
    }),
    used: false,
  },
  sprinkler_spraying_out_of_lawn: {
    title: titleFormatter({
      id: "texts.steps.sprinklers.annotation.sprinklerSprayingSignificantlyOverLawn.button",
    }),
    used: false,
  },
  sprinklers_overlap: {
    title: titleFormatter({
      id: "texts.steps.sprinklers.annotation.sprinklersOverlap.button",
    }),
    used: false,
  },
});

function Divider({ narrow }) {
  return <div className={`divider ${narrow ? "narrow" : ""}`} />;
}

function PlanAssessment() {
  const { uiState } = useStores();
  const { formatMessage } = useIntl();

  const ref = React.useRef();
  useOuterClickEffect(ref, () => toggleAssessmentPopup());

  const assessmentsData = defaultAssessment(formatMessage);
  const [assessment, setAssessment] = React.useState(null);

  const [showPlanAssessment, setShowPlanAssessment] = React.useState(false);

  const toggleAssessmentPopup = () => setShowPlanAssessment((p) => !p);

  const [annotations, setAnnotations] = React.useState(
    structuredClone(defaultAnnotations(formatMessage))
  );
  const [usedAnnotations, setUsedAnnotations] = React.useState([]);

  const annotationsState = Object.entries(annotations);
  const removeAnnotation = (annotationId) => {
    setUsedAnnotations((p) => {
      return p.filter((t) => t !== annotationId);
    });

    setAnnotations((p) => {
      p[annotationId].used = false;
      return { ...p };
    });
  };

  const addAnnotation = (annotationId) => {
    setAssessmentDeleted(false);

    setUsedAnnotations((p) => {
      p.push(annotationId);
      return [...p];
    });

    setAnnotations((p) => {
      p[annotationId].used = true;
      return { ...p };
    });
  };

  const deleteAnnotations = () => {
    if (usedAnnotations?.length === 0) return;

    setUsedAnnotations([]);
    setAnnotations(structuredClone(defaultAnnotations(formatMessage)));
  };

  const [assessmentDeleted, setAssessmentDeleted] = React.useState(false);

  const deleteAssignment = () => {
    uiState.calcApi
      .removeAssessments(uiState.planId)
      .then((v) => {
        setAssessmentDeleted(true);
        setAssessment(null);
        deleteAnnotations();
      })
      .catch(console.error);
  };

  React.useEffect(() => {
    if (uiState.planId == null || !uiState?.userIsAdmin) return;

    uiState.calcApi
      .getAssessments(uiState.planId)
      .then((v) => {
        if (v == null) return;

        setAssessment(v.assessment);

        if (v.annotations == null) return;

        setUsedAnnotations([]);
        for (const annotation of v.annotations) {
          addAnnotation(annotation);
        }
      })
      .catch((e) => {
        console.error(e);
      });
  }, [uiState.calcApi, uiState.planId, uiState?.userIsAdmin]);

  React.useEffect(() => {
    if (ref.current == null) return;

    if (assessmentDeleted) return;

    const timeout = setTimeout(async () => {
      const planId = uiState.planId;
      uiState.calcApi
        .saveAssessments(planId, {
          userId: uiState.user.email,
          assessment,
          annotations: usedAnnotations,
        })
        .catch((e) => {
          console.error(e);
        });
    }, 1000);

    return () => clearTimeout(timeout);
  }, [
    assessment,
    assessmentDeleted,
    uiState.calcApi,
    uiState.planId,
    uiState.user.email,
    usedAnnotations,
  ]);

  if (
    uiState.planId == null ||
    !uiState?.userIsAdmin ||
    uiState.calculatingTitle != null ||
    uiState.stepIdx !== uiState.stepIdxByName("sprinklers")
  )
    return null;

  return (
    <>
      <div className="plan-assessment-control">
        <button
          className={showPlanAssessment ? "active" : ""}
          onClick={toggleAssessmentPopup}
        >
          {formatMessage({
            id: "texts.steps.sprinklers.assessment.title",
          }).toUpperCase()}
        </button>
      </div>

      {showPlanAssessment ? (
        <>
          <div
            ref={ref}
            className={`plan-assessment ${assessment > 1 ? "extend" : ""}`}
          >
            <div className="assessment">
              <div>
                {formatMessage({
                  id: "texts.steps.sprinklers.assessment.title",
                })}
                :
              </div>
              <Dropdown
                data={assessmentsData}
                current={assessment}
                onChange={(v) => {
                  setAssessment(v);
                  setAssessmentDeleted(false);
                }}
              />
            </div>
            <Divider />
            {assessment > 1 ? (
              <>
                <div className="annotations-wrapper">
                  <div className="title">
                    {formatMessage({
                      id: "texts.steps.sprinklers.annotation.title",
                    })}
                  </div>
                  <div className="annotations-container">
                    <div className="annotations used">
                      {usedAnnotations.map((key) => (
                        <button key={key} onClick={() => removeAnnotation(key)}>
                          {defaultAnnotations(formatMessage)[key].title}
                        </button>
                      ))}
                    </div>

                    {usedAnnotations.length > 0 &&
                    usedAnnotations.length < 8 ? (
                      <Divider narrow={true} />
                    ) : null}

                    <div className="annotations">
                      {annotationsState.flatMap(([key, props]) =>
                        !props.used
                          ? [
                              <button
                                key={key}
                                onClick={() => addAnnotation(key)}
                              >
                                {props.title}
                              </button>,
                            ]
                          : null
                      )}
                    </div>
                  </div>
                </div>
                <Divider />
              </>
            ) : null}

            <div className="footer">
              <button onClick={() => deleteAnnotations()}>
                {formatMessage({
                  id: "texts.steps.sprinklers.annotation.clearAnnotations.button",
                })}
              </button>
              <button onClick={deleteAssignment}>
                {formatMessage({
                  id: "texts.steps.sprinklers.annotation.deleteAssignment.button",
                })}
              </button>
            </div>
          </div>
          <PopupArrowRightSVG className="visual-arrow" />
        </>
      ) : null}
    </>
  );
}

const PlanAssessmentWithState = observer(PlanAssessment);
export default PlanAssessmentWithState;
