import React from "react";

import { ReactComponent as InfoSVG } from "./../../assets/inform.svg";
import Link from "./Link";

function Info({ text = null, url = null }) {
  return (
    <React.Fragment>
      {text ? (
        <span className="inform" title={text}>
          <i>{<InfoSVG />}</i>
        </span>
      ) : (
        <Link
          href={url}
          target="_blank"
          rel="noopener noreferrer"
          className="inform"
          title={text}
        >
          <i>{<InfoSVG />}</i>
        </Link>
      )}
    </React.Fragment>
  );
}

export default Info;
