import { useIntl } from "react-intl";

import HeaderButton from "./HeaderButton";
import { ReactComponent as LockSVG } from "../../assets/lock.svg";
import { ReactComponent as UnlockSVG } from "../../assets/unlock.svg";

function LockButton({ isLocked, lock, unlock, labels }) {
  const { formatMessage } = useIntl();

  return isLocked ? (
    <HeaderButton
      onClick={unlock}
      style={{ width: 70 }}
      Icon={LockSVG}
      label={
        labels
          ? formatMessage({
              id: labels.lockedLabel,
            })
          : "locked"
      }
    />
  ) : (
    <HeaderButton
      onClick={lock}
      style={{ width: 70 }}
      Icon={UnlockSVG}
      label={
        labels
          ? formatMessage({
              id: labels.unlockedLabel,
            })
          : "unlocked"
      }
    />
  );
}

export default LockButton;
