import React from "react";

import { ReactComponent as PopupArrowTRSVG } from "./../../assets/popup-arrow-tr.svg";
import { ReactComponent as PopupArrowRdSVG } from "./../../assets/popup-arrow-rd.svg";
import { ReactComponent as PopupArrowLSVG } from "./../../assets/popup-arrow-l.svg";
import { ReactComponent as PopupArrowDSVG } from "./../../assets/popup-arrow-d.svg";
import { ReactComponent as PopupArrowTSVG } from "./../../assets/popup-arrow-t.svg";
import { useOuterClickEffect } from "../../hooks/useOuterClickEffect";

function Popup({
  hasActive = false,
  hasCloseButton = true,
  hasOutherClickClose = true,
  onClose = () => {},
  position,
  arrow,
  children,
  className = "",
}) {
  const popupRef = React.useRef();
  const popupContainerRef = React.useRef();

  useOuterClickEffect(hasOutherClickClose ? popupContainerRef : null, onClose);

  React.useEffect(() => {
    setTimeout(() => {
      const node = popupRef.current;
      if (node) node.style.opacity = 1;
    }, 0);
  });

  let ArrowSVG = arrow;
  if (ArrowSVG == null) {
    switch (position) {
      case "top-left":
        ArrowSVG = PopupArrowTRSVG;
        break;
      case "top-right":
        ArrowSVG = PopupArrowLSVG;
        break;
      case "left":
        ArrowSVG = PopupArrowRdSVG;
        break;
      case "right":
        ArrowSVG = PopupArrowLSVG;
        break;
      case "top":
        ArrowSVG = PopupArrowDSVG;
        break;
      case "bottom":
        ArrowSVG = PopupArrowTSVG;
        break;
      default:
        break;
    }
  }

  if (!hasActive) return null;

  return (
    <div
      className={`popup ${position || ""} ${className || ""}`}
      ref={popupRef}
    >
      <div className={`popup-container`} ref={popupContainerRef}>
        {hasCloseButton ? (
          <div className="popup-close" onClick={onClose}>
            <i>&#215;</i>
          </div>
        ) : null}
        {children}
        {ArrowSVG != null && (
          <div className="popup-arrow">
            <ArrowSVG />
          </div>
        )}
      </div>
    </div>
  );
}

export default Popup;
