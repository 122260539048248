import React from "react";
import { observer } from "mobx-react";

import ToolsItem from "./ToolsItem";
import { ReactComponent as SensorAddSVG } from "./../../assets/sensor-tool.svg";
import { useIntl } from "react-intl";
import useStores from "../../hooks/useStores";

function SensorTools({ selectedTool, setSelectedTool, labels }) {
  const { formatMessage } = useIntl();

  return (
    <React.Fragment>
      <ToolsItem
        icon={<SensorAddSVG />}
        type="sensor-add"
        isActive={selectedTool === "sensor"}
        onClick={() => {
          setSelectedTool("sensor");
        }}
        hasLine={true}
        tooltip={labels ? formatMessage({ id: labels.sensor.tooltip }) : null}
      />
    </React.Fragment>
  );
}

let SensorToolsWithState = () => {
  const { uiState } = useStores();
  return (
    <SensorTools
      selectedTool={uiState.selectedTool}
      setSelectedTool={uiState.setSelectedTool}
      labels={uiState.settingsState ? uiState.settingsState.texts.tools : null}
    />
  );
};

SensorToolsWithState = observer(SensorToolsWithState);
export default SensorToolsWithState;
