import React from "react";
import ToolButton from "../../elements/ToolButton";
import Tooltip from "../../tooltip/Tooltip";

import { ReactComponent as ArrowRightSVG } from "../../../assets/arrow-right.svg";
import { ReactComponent as NoGoSVG } from "../../../assets/nogo.svg";

function StepItem({
  icon,
  hasActive = false,
  hasLine = true,
  onClick,
  disabled = false,
  nextDisabled = false,
  hasError = false,
  onLineClick = () => {},
  tooltip,
}) {
  return (
    <ToolButton
      className={`steps-item ${hasActive ? "active" : ""} ${
        hasError ? "error" : ""
      }`}
      disabled={disabled}
    >
      <Tooltip position="bottom" tooltip={tooltip}>
        <i
          className={`step-icon ${disabled ? "disabled" : ""}`}
          onClick={!disabled ? () => (onClick ? onClick() : null) : undefined}
        >
          {icon}
        </i>
      </Tooltip>
      {hasLine && (
        <React.Fragment>
          {hasError || nextDisabled ? (
            <i onClick={onLineClick} className="arrow-no">
              <NoGoSVG />
            </i>
          ) : (
            <i onClick={onLineClick} className="arrow-right">
              <ArrowRightSVG />
            </i>
          )}
        </React.Fragment>
      )}
    </ToolButton>
  );
}

export default StepItem;
