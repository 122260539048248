import * as React from "react";
import { useIntl } from "react-intl";

import { ReactComponent as FeedbackSmallIconSVG } from "../assets/feedback.svg";
import { ReactComponent as FeedbackLargeIconSVG } from "../assets/feedback_new.svg";
import { ReactComponent as FlowerSVG } from "../assets/flower.svg";

import { observer } from "mobx-react-lite";
import usePending from "../hooks/usePending";
import Button from "./elements/Button";
import ToolButton from "./elements/ToolButton";
import Popup from "./popups/Popup";
import useStores from "../hooks/useStores";
import useWindowSize from "../hooks/useWindowSize";

const CATEGORY_LIST = ["compliment", "idea", "suggestion", "problem"];

const FeedbackSuccess = ({ close }) => {
  const { formatMessage } = useIntl();

  return (
    <>
      <div className="popup-title">
        {formatMessage({ id: "texts.tools.feedback.success.title" })}
      </div>
      <div className="feedback-container">
        <div className="row success">
          <div className="success-title">
            {formatMessage({ id: "texts.tools.feedback.success.messagePre" })}{" "}
            <FlowerSVG />{" "}
            {formatMessage({ id: "texts.tools.feedback.success.messagePost" })}
          </div>
          <div className="success-description">
            {formatMessage({ id: "texts.tools.feedback.success.description" })}
          </div>
        </div>
        <div className="row controls">
          <Button
            type="button"
            title={formatMessage({ id: "texts.tools.feedback.success.button" })}
            className="ok"
            onClick={() => close()}
          />
        </div>
      </div>
    </>
  );
};

const FeedbackForm = ({ pending, save }) => {
  const { formatMessage } = useIntl();

  const [rate, setRate] = React.useState(undefined);
  const [hoverRate, setHoverRate] = React.useState(undefined);
  const [description, setDescription] = React.useState();
  const [category, changeCategory] = React.useState();

  const disabled = !(description?.trim()?.length > 0 || rate != null);

  return (
    <>
      <div className="popup-title">
        {formatMessage({ id: "texts.tools.feedback.title" })}
      </div>
      <form
        onSubmit={async (e) => {
          e.preventDefault();
          if (!disabled && !pending) {
            await save({ rate, category, description });
          }
        }}
        className="feedback-container"
      >
        <div className="row text">
          {formatMessage({ id: "texts.tools.feedback.text" })}
        </div>
        <div className="row rate">
          {[1, 2, 3, 4, 5].map((val) => (
            <ToolButton
              key={val}
              className={
                (hoverRate == null && val <= rate) || val <= hoverRate
                  ? "selected"
                  : undefined
              }
              onClick={() => {
                if (!pending) setRate(val);
              }}
              onMouseEnter={() => {
                if (!pending) setHoverRate(val);
              }}
              onMouseLeave={() => {
                if (!pending) setHoverRate(undefined);
              }}
            >
              <FlowerSVG />
            </ToolButton>
          ))}
        </div>
        <div className="row">
          {formatMessage({ id: "texts.tools.feedback.category.title" })}
        </div>
        <div className="row category">
          {CATEGORY_LIST.map((c) => {
            const selected = category === c;

            return (
              <ToolButton
                key={c}
                type="buttton"
                className={selected ? "selected" : undefined}
                onClick={() => changeCategory(selected ? undefined : c)}
              >
                {formatMessage({
                  id: `texts.tools.feedback.category.${c}`,
                })}
              </ToolButton>
            );
          })}
        </div>
        {category != null && (
          <div className="row description">
            <textarea
              disabled={pending}
              placeholder={formatMessage({
                id: "texts.tools.feedback.placeholder",
              })}
              defaultValue={description ?? ""}
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>
        )}
        <div className="row controls">
          <Button
            disabled={disabled}
            type="submit"
            title={formatMessage({ id: "texts.tools.feedback.button" })}
            className="ok"
          />
        </div>
      </form>
    </>
  );
};

const FeedBackButton = ({ popup, changePopup }) => {
  const { width } = useWindowSize();
  const isDesktopScreen = width >= 960;

  return (
    <div
      className={`feedback ${isDesktopScreen ? "large-icon" : "small-icon"}`}
    >
      <ToolButton
        className={popup ? "active" : undefined}
        onClick={() => changePopup(!popup)}
      >
        {isDesktopScreen ? <FeedbackLargeIconSVG /> : <FeedbackSmallIconSVG />}
      </ToolButton>
    </div>
  );
};

const FeedbackPopup = ({ save: saveAction, close, success }) => {
  const [pending, save] = usePending(saveAction);

  return (
    <Popup
      className="feedback-popup"
      hasActive={true}
      hasCloseButton={false}
      onClose={close}
    >
      {success ? (
        <FeedbackSuccess close={close} />
      ) : (
        <FeedbackForm pending={pending} save={save} />
      )}
    </Popup>
  );
};

let Feedback = () => {
  const { uiState } = useStores();
  const user = uiState.user;
  const [popup, changePopup] = React.useState(false);
  const [success, setSuccess] = React.useState(false);

  if (uiState.planId == null) return null;

  return (
    <>
      {popup && (
        <FeedbackPopup
          success={success}
          close={() => {
            changePopup(false);
            setSuccess(false);
          }}
          save={async (data) => {
            try {
              await uiState.saveFeedback({ email: user?.email, ...data });
              setSuccess(true);
            } catch (e) {
              console.error("Error in saving feedback", e);
              uiState.showRestError("saveFeedback", e);
            }
          }}
        />
      )}

      <FeedBackButton popup={popup} changePopup={changePopup} />
    </>
  );
};
Feedback = observer(Feedback);

export default Feedback;
