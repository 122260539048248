import Link from "../elements/Link";

function LinkComponent({ children, noredirect, ...props }) {
  return noredirect ? (
    <a {...props}>{children}</a>
  ) : (
    <Link {...props}>{children}</Link>
  );
}

function ButtonComponent({ children, onClick, ...props }) {
  return (
    <button
      {...props}
      onClick={async (e) => {
        e.preventDefault();
        await onClick();
        e.target.blur();
      }}
    >
      {children}
    </button>
  );
}

function HeaderButton({ Icon, label, className, ...props }) {
  const Component = props.href == null ? ButtonComponent : LinkComponent;
  return (
    <Component {...props} className={`right-item ${className ?? ""}`}>
      <i>
        <Icon width={32} height={32} />
      </i>
      {label && <span>{label}</span>}
    </Component>
  );
}

export default HeaderButton;
