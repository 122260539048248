import { extendObservable, observable, action, runInAction } from "mobx";
import { debounce } from "lodash";
import { sleep } from "@dvsproj/ipat-core/helpers";
import { v4 as uuid } from "uuid";

import asyncExecutionFacade from "../utils/asyncExecutionFacade";
import { availableStatuses } from "../planFactory/injectPlanSerialization";
import { urlDecorator } from "../../utils/api";
import { getImageSize } from "../../utils/uiUtils";
import { replaceState } from "../../components/elements/Link";
import { planVersion } from "../planFactory/planVersion";
import { getPlanCheckItem } from "../../utils/bomUtils";

const planIdURLParameter = "planId";
const localStorageKeyPrefix = "plan";

/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default (state, restApi, calcApi) => {
  const localStorageKey = (planId) => {
    return `${localStorageKeyPrefix}-${planId}`;
  };

  const privateState = observable({
    createPlan: async () => {
      if (state.planId == null) {
        try {
          const response = await restApi.createPlan(state.planName, state.plan);
          if (!response) return;

          const { ident, created_at } = response;

          action(() => {
            state.planId = ident;
            state.plan.setCreatedAt(created_at);
          })();

          const imageFile = state.plan?.background?.file;

          await calcApi.savePlan(
            state.planId,
            state.planName,
            state.plan.toSaveJSON,
            state.plan.status,
            imageFile
          );

          replaceState(`?${planIdURLParameter}=${ident}`);

          state.changeHasSavePopup();
        } catch (e) {
          console.error(e);
          state.showRestError("createPlan", e);
          throw e;
        }
      }
    },
    _updatePlanRequests: async (planId, planName, data, status) => {
      if (planId != null) {
        const promises = [];
        promises.push(calcApi.savePlan(planId, planName, data, status));

        const restApiRequest = restApi.savePlan(planId, planName, data, status);
        promises.push(restApiRequest);

        await Promise.all(promises);

        return restApiRequest;
      }
    },
    updatePlan: async () => {
      if (state.planId != null) {
        try {
          const response = await privateState._updatePlanRequests(
            state.planId,
            state.planName,
            state.plan.toSaveJSON,
            state.plan.status
          );
          if (!response) return;
        } catch (e) {
          console.error(e);
          state.showRestError("updatePlan", e);
          throw e;
        }
      }
    },
  });

  extendObservable(privateState, {
    updatePlanRequest: asyncExecutionFacade(async () => {
      try {
        state.saveStatus = "saving";
        if (state.planId == null) {
          await privateState.createPlan();
        } else {
          await privateState.updatePlan();
          action(() => {
            state.lastSaveDate = new Date();
            state.saveStatus = "done";
          })();
        }
      } catch (e) {
        state.saveStatus = "disconnect";
      }
    }, 1),
  });

  // inject save SETTINGS
  extendObservable(state, {
    /**
     * saveStatus: null, done, saving, disconnect
     */
    saveStatus: null,
    lastSaveDate: null,
    hasSavePopup: false,
    changeHasSavePopup: action(() => {
      state.hasSavePopup = !state.hasSavePopup;
    }),
  });

  extendObservable(state, {
    saveFeedback: async ({ email, rate, category, description }) => {
      try {
        action(() => {
          state.calculatingTitle = "texts.tools.feedback.calculationTitle";
        })();

        const response = await calcApi.saveFeedback(
          state.planId,
          email,
          rate,
          category,
          description
        );
        return response;
      } finally {
        action(() => {
          state.calculatingTitle = null;
        })();
      }
    },
  });

  extendObservable(state, {
    _requestAndCheckPlanVersion: async (planId) => {
      if (planId != null) {
        let response = await restApi.getPlanById(planId);
        if (!response) return;

        if (response?.data) {
          const { data, name } = response;
          const { elements, version } = data;
          if (!version) {
            elements
              .filter(
                (elem) =>
                  elem.type === "pipeline-point" &&
                  elem.pointType === "water-tap-point" &&
                  elem.parentId == null
              )
              .forEach((waterTapWithoutParent) => {
                waterTapWithoutParent.parentId = "system-element-" + uuid();
                elements.push({
                  id: waterTapWithoutParent.parentId,
                  x: waterTapWithoutParent.x,
                  y: waterTapWithoutParent.y,
                  type: "system-element",
                  systemType: waterTapWithoutParent.pointType,
                });
              });

            await privateState._updatePlanRequests(
              planId,
              name,
              { ...data, elements, version: planVersion },
              data.status
            );
            response = await restApi.getPlanById(planId);
          }
        }
        return response;
      }
    },
    getPlanInfo: async () => {
      try {
        const planId = new window.URLSearchParams(
          window.location.search.slice(1)
        ).get(planIdURLParameter);

        if (planId != null) {
          const response = await state._requestAndCheckPlanVersion(planId);
          if (!response) return;

          const { name, ident, image, created_at, data } = response;

          if (data) {
            action(() => {
              state.planName = name;
              state.planId = ident;
            })();

            if (image) {
              const background = data?.background;

              let imageSize = {
                width: background?.width,
                height: background?.height,
              };
              try {
                imageSize = await getImageSize(image);
              } catch (error) {
                console.error(error);
              } finally {
                data.background = {
                  ...data.background,
                  src: image,
                  ...imageSize,
                };
              }
            }

            return {
              ...data,
              createdAt: created_at,
            };
          }
        }
      } catch (e) {
        console.error(e);
        state.showRestError("getPlanInfo", e);
      }
    },
    updatePlan: async () => {
      if (state.planId == null) {
        await privateState.updatePlanRequest.call();
      }

      await privateState.updatePlanRequest.call();
    },

    planChecked: async () => {
      action(() => {
        state.calculatingTitle =
          state.settingsState.texts.steps.bom.planCheckedCalculationTitle;
      })();
      await sleep(0);

      try {
        const response = await restApi.planChecked(state.planId);
        if (response) {
          action(() => {
            state.calculatingTitle = null;
            state.plan.status = availableStatuses.Checked;
            state.savePlan();
          })();
        }
      } catch (e) {
        console.error(e);
        action(() => {
          state.calculatingTitle = null;
          state.showRestError("planChecked", e);
        })();
      }
    },
    planToCheck: async () => {
      action(() => {
        state.calculatingTitle =
          state.settingsState.texts.steps.bom.planToCheckCalculationTitle;
      })();
      await sleep(0);

      const { bomItems } = state.plan;
      if (!bomItems.find(({ bomId }) => bomId === "checkMyPlan")) {
        const planCheckBomItem = getPlanCheckItem(state.settingsState);
        state.plan.addBomItemsToStorage([planCheckBomItem]);
      }

      try {
        const response = await restApi.planToCheck(state.planId);
        if (response) {
          const checkMyPlan = bomItems.find(
            ({ bomId }) => bomId === "checkMyPlan"
          );

          const cartURL = await restApi.getCartUrl(state.planId, [
            {
              article_id: checkMyPlan.articleId,
              article_no: checkMyPlan.articleNO,
              quantity: 1,
            },
          ]);

          if (cartURL) {
            runInAction(() => {
              state.calculatingTitle = null;
              state.plan.status = availableStatuses.ToCheck;
            });
            await state.savePlan();

            if (!state.hasDev) {
              return urlDecorator(cartURL);
            }
          } else {
            throw new Error("getCartUrl returned null");
          }
        } else {
          throw new Error("planToCheck returned null");
        }
      } catch (e) {
        console.error(e);
        action(() => {
          state.calculatingTitle = null;
          state.showRestError("planToCheck", e);
        })();
      }
    },
    getPlanPosition: () => {
      return JSON.parse(
        window.localStorage.getItem(localStorageKey(state.planId))
      );
    },
    savePlanPosition: debounce(() => {
      if (state.planId == null) return false;

      try {
        window.localStorage.setItem(
          localStorageKey(state.planId),
          JSON.stringify({
            date: Date.now(),
            zoom: state.zoomState.zoom,
            offsetX: state.offsetX,
            offsetY: state.offsetY,
            step: state.stepIdx,
          })
        );
      } catch (e) {
        console.error(e);

        // if the limit is exceeded
        let storagesKey = {};
        for (let i = 0; i < localStorage.length; i++) {
          let key = localStorage.key(i);
          if (key.match(`${localStorageKeyPrefix}`)) {
            const data = JSON.parse(window.localStorage.getItem(key));
            storagesKey[data.date] = key;
          }
        }

        const filteredKey = Object.keys(storagesKey).sort((a, b) => a > b);
        if (filteredKey.length > 0) {
          window.localStorage.removeItem(storagesKey[filteredKey[0]]);
        }
      }
    }, 400),
  });

  extendObservable(state, {
    saveStats: debounce(async () => {
      try {
        if (state.planId == null || (state.plan?.maxStep ?? 0) < 4) {
          return;
        }

        const response = await calcApi.saveStats(
          state.planId,
          state.plan.toStatsJSON
        );
        return response;
      } catch (e) {
        console.warn("send pipelines info failed", e);
      }
    }, 1000),
  });
};
